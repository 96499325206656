import React from 'react';
import { Button } from '@mui/material';
import EditableField from './EditableField';
import EditableDateField from './EditableDateField';
import EditableSelectField from './EditableSelectField';
import DummyField from './DummyField';
import EditableFileField from './EditableFileField';

import {RowProps} from './DataType';
import moment from 'moment-timezone';

export default function EditableRow (props: RowProps) {
    return (
        <tr
            style={Object.assign({margin: 2, backgroundColor:props.isHeader?"#333333":"#FFFFFF", color:!props.isHeader?"#333333":"#FFFFFF" },props.style || {})}
        >
            {props.headerButtons.map(({name, color, onClick, props}, index) =>
                <td key={"headerButton:"+index}><Button {...(props || {})} color={color} onClick={onClick} >
                    {name}
                </Button></td>
            )}
            {props.fields.map((option)=> {

                switch (option.type) {
                    case 'select':
                        return <td
                            key={(props.rowId || '') + option.field}
                        ><EditableSelectField
                            {...option}
                            update={props.update}
                        /></td>;
                    case 'date':
                        return <td
                            key={(props.rowId || '') + option.field}
                        >{props.forDownload?
                        (
                            option.monthOnly?moment(option.defaultValue * 1000).format('YYYY-MM')
                            :option.timeOnly?moment(option.defaultValue * 1000).format('hh:mm:ss')
                            :moment(option.defaultValue * 1000).format('YYYY-MM-DD hh:mm:ss')
                        ):<EditableDateField
                            {...option}
                            update={props.update}
                        />}</td>;
                    case 'file':
                        return <td
                            key={(props.rowId || '') + option.field}
                        >{props.forDownload?
                        option.defaultValue
                        :<EditableFileField
                            {...option}
                            update={props.update}
                        />}</td>;
                    case null:
                        return <td
                            key={(props.rowId || '') + option.field}
                        ><DummyField
                            {...option}
                            update={props.update}
                        /></td>;
                    default:
                        return <td
                            key={(props.rowId || '') + option.field}
                        ><EditableField
                            {...option}
                            update={props.update}
                        /></td>;
                }
            })}
        </tr>
    );
}