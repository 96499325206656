import React from 'react';
import EditableTable from 'Component/Table/EditableTable';
import { deleteExpense, Expense as ExpenseType, readExpense, updateExpense } from 'Data/Expense';

import '../Expense.scss';
import EditableDateField from 'Component/Table/EditableDateField';
import { fn, unique } from 'Functions';
import { readProfileList, profileType } from 'Data/Profile';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Input, MenuItem, Paper, Select, TextField } from '@mui/material';
import moment from 'moment-timezone';
import EditableFileField from 'Component/Table/EditableFileField';
import { useSession } from 'Recoil/Session/Session';
import { CellData } from 'Component/Table/DataType';

type DoubleNestedObject = {
    [x: string]: {
        [x: string]: number;
    };
};

const Expense = () => {
    const {session} = useSession();
    
    if (session.userid === null || session.memberState !== 'regular'){
        fn.goto('/setting?redirectTo=/expense');
    }

    const [data, setData] = React.useState([] as (ExpenseType & {expenseid: number})[]);
    
    const [refreshTimer, setRefreshTimer] = React.useState(null as null | NodeJS.Timeout)
    const [needRefresh, setNeedRefresh] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [editId, setEditId] = React.useState(-1);

    const [profiles, setProfiles] = React.useState([] as profileType[]);
    React.useEffect(()=>{
        (async ()=>{
            let userids = data.map(row => row.userid || "").filter(userid => !!userid);
    
            if (userids.length === 0){
                return;
            }
            try{
                let profiles = await readProfileList(userids);
    
                setProfiles(profiles);
            }catch(e){
                //
            }
        })();
    },[data])

    const [month, setMonth] = React.useState(new Date().getTime()/1000 - 10 * 24 * 60 * 60);

    const [deletingExpense, setDeletingExpense] = React.useState(null as (ExpenseType & {expenseid: number}) | null);

    React.useEffect(()=>{
        let isFinished = false;

        if (refreshTimer){
            clearTimeout(refreshTimer);
        }

        let timer = setTimeout(()=>{
            (async()=>{
                setIsLoading(true);
                let startDayOfThisMonth = moment(month * 1000).startOf('month').unix();
                let endDayOfThisMonth = moment(month * 1000).endOf('month').unix();
                let expenses = await readExpense(undefined, startDayOfThisMonth, endDayOfThisMonth);
    
                if (!isFinished){
                    setData(expenses);
                    setIsLoading(false);
                }
            })();
        }, 2000);

        setRefreshTimer(timer);

        return ()=>{
            isFinished = true;
            if (refreshTimer){
                clearTimeout(refreshTimer);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[needRefresh, month]);

    const persons = data.map(row => row.related).flat().filter(unique).filter(person => person !== '기타').sort();

    return (<div className={"Expense"} style={{wordBreak: 'keep-all', whiteSpace: 'nowrap'}}>
        <h1><img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>{"비용 작성"}</h1>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{marginRight: 10}}>{"조회기간"}</h3>
            <EditableDateField
                type={'date'}
                isEditing={true}
                defaultValue={month}
                monthOnly={true}
                field={"month"}
                update={(newValue)=>{
                    setMonth(newValue.month)
                }}
            />
        </div>
        <EditableTable
            style={{margin: 'auto'}}
            headerButtonHeaders={[]}
            headerButtonsFunction={(row, index)=> []}
            isDownloadable={false}
            columnHeaders={['날짜', ...persons, '기타']}
            columnsFunction={(row: [string, {[person: string]: number}], index) => [
                {isEditing: false, type: 'default', defaultValue: row[0], field: 'date', },
                ...(persons?.map(person =>
                    ({isEditing: false, type: 'default', defaultValue: <span style={((new Date(row[0]).getDay() + 1) % 7) < 2 || ((row[1] || {})[person] || 0) > 10000 ?{color:'red'}:{}}>{Number((row[1] || {})[person] || 0).toLocaleString()}</span>, field: person, })
                )),
                {isEditing: false, type: 'default', defaultValue: `${Number((row[1] || {})['기타'] || 0).toLocaleString()}`, field: '기타', }
            ] as CellData[]}
            data={Object.entries(
                data
                .filter(({type}) => type === '식대-점심 식사')
                .map(({occured_at = 0, amount, request_amount, related}) => (related.map(person => ({
                    [new Date(occured_at * 1000 + 9 * 60 * 60 * 1000).toISOString().substring(0,10)]: {
                        [person]: (
                            request_amount <= 0
                                ?(amount+request_amount)
                                :request_amount
                        ) / related.length
                    } } as DoubleNestedObject) ))).flat()
                .reduce((a:DoubleNestedObject,b:DoubleNestedObject) => {
                    let ret = JSON.parse(JSON.stringify(a));

                    for (let date of Object.keys(b)){
                        if (! (date in ret)){
                            ret[date] = {};
                        }

                        for(let person of Object.keys(b[date])) {
                            ret[date][person] = (ret[date][person] || 0) + Math.floor(b[date][person]);
                        }
                    }

                    console.log(ret)
                    return ret;
                }, {} as DoubleNestedObject)
            )}
            updateGenerator={(row, index)=> async (newValue: any)=> {}}
        />
        <br/><br/>
        <EditableTable
            headerButtonHeaders={[
                '', '', 
            ]}
            headerButtonsFunction={(row: ExpenseType & {expenseid: number}, index)=> [
                {name: '삭제', color: 'secondary', onClick: ()=>{
                    setDeletingExpense(row)
                }, props: {}},
                {name: '수정', color: 'primary', onClick: ()=>{
                    setEditId(editId === -1?row.expenseid:-1)
                }, props: {}},
            ]}
            isDownloadable={true}
            columnHeaders={['번호', '상태', '작성자', '비목', '카드', '결제액', '청구액', '인원', '영수증', '사용일', '청구월', '코멘트', '참석자']}
            columnsFunction={(row: ExpenseType & {expenseid: number}, index)=> [
                {isEditing: false, type: 'default', defaultValue: row.expenseid, field: 'expenseid', },
                {isEditing: true, type: 'select', defaultValue: row.state, field: 'state', options: [{value: 'confirmed', key: '승인됨'}, {value: 'rejected', key: '반려됨'}, {value:'edit', key: '대기중'}]},
                {isEditing: row.expenseid === editId, type: 'select', defaultValue: row.userid || "", field: 'userid', style:{wordBreak: 'keep-all'}, options: profiles.map( (row:any) => ({key: row.name || "", value: row.userid || ""})) },
                {isEditing: false, type: 'default', defaultValue: row.type, field: 'type', style:{wordBreak: 'keep-all'} },
                {isEditing: row.expenseid === editId, type: 'default', defaultValue: row.cardid, field: 'cardid', style:{wordBreak: 'keep-all'} },
                {isEditing: row.expenseid === editId, type: 'default', defaultValue: row.amount, field: 'amount', },
                {isEditing: row.expenseid === editId, type: 'default', defaultValue: row.request_amount, field: 'request_amount', },
                {isEditing: false, type: 'default', defaultValue: row.related?.length || 0, field: 'related_numb', style:{wordBreak: 'keep-all'} },
                {isEditing: row.expenseid === editId, type: 'file', defaultValue: row.receipt || "", field: 'receipt', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} },
                {isEditing: row.expenseid === editId, type: 'date', defaultValue: row.occured_at || 0, field:'occured_at' },
                {isEditing: row.expenseid === editId, type: 'date', monthOnly: true, defaultValue: row.requested_at || 0, field:'requested_at' },
                {isEditing: row.expenseid === editId, type: 'default', defaultValue: row.comment, field: 'comment', },
                {isEditing: false, type: 'default', defaultValue: row.related.join(', '), field: 'related', style:{wordBreak: 'keep-all'} },
            ]}
            data={data}
            updateGenerator={(row, index)=> async (newValue: any) => {
                await updateExpense({...row, ...newValue})
                let targetIndex = data.findIndex(record => record.expenseid === row.expenseid)
                setData([
                    ...data.slice(0, targetIndex),
                    {...row, ...newValue},
                    ...data.slice(targetIndex+1),
                ])
                setEditId(-1)
                setNeedRefresh(needRefresh+1)
            }}
        />
        <Dialog open={!!deletingExpense}>
            <DialogTitle>{"정말 삭제하시겠습니까?"}</DialogTitle>
            <DialogContent>
                {"삭제하시면 복구가 어렵습니다."}
                {deletingExpense
                ?<Paper elevation={2} className={"NewExpense"}>
                    <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                        <label style={{paddingRight: 10, lineHeight:'40px'}}>{"비목"}</label>
                        <Select
                            className='InputCell' fullWidth size={"small"} disabled
                            value={deletingExpense.type} onChange={(event)=> {}}
                        >
                            <MenuItem value={deletingExpense.type}>{deletingExpense.type}</MenuItem>
                        </Select>
                    </div>
                    <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                        <label style={{paddingRight: 10, lineHeight:'40px'}}>{"카드"}</label>
                        <Select
                            className='InputCell' fullWidth size={"small"} disabled
                            value={deletingExpense.cardid} onChange={(event)=> {}}
                        >
                            <MenuItem value={"개인"}>{"개인"}</MenuItem>
                            <MenuItem value={"법인"}>{"법인"}</MenuItem>
                        </Select>
                    </div>
                    <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                        <TextField
                            className='Child' fullWidth label={"결제액"} inputProps={{style:{padding: 10}}} disabled
                            value={deletingExpense.amount} onChange={(event)=> {}}
                        />
                        <TextField
                            className='Child' fullWidth label={"청구액"} inputProps={{style:{padding: 10}}} disabled
                            value={deletingExpense.request_amount} onChange={(event)=> {}} 
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', paddingBottom: 10,}}>
                        <label style={{paddingRight: 10, lineHeight:'30px', whiteSpace:'nowrap'}}>{"사용일"}</label>
                        <Input
                            fullWidth type={"date"} disabled
                            value={moment((deletingExpense.occured_at || new Date().getTime()/1000)*1000).format('YYYY-MM-DD')}
                            onChange={(event) => {}} 
                        />
                    </div>
                    <div style={{display:'flex', flexDirection:'row', paddingBottom: 10,}}>
                        <label style={{paddingRight: 10, lineHeight:'30px', whiteSpace:'nowrap'}}>{"청구월"}</label>
                        <Input
                            fullWidth type={"month"} disabled
                            value={moment((deletingExpense.requested_at || new Date().getTime()/1000)*1000).format('YYYY-MM')}
                            onChange={(event) => {}} 
                        />
                    </div>
                    <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                        <label style={{paddingRight: 10, lineHeight:'40px'}}>{"참석자"}</label>
                    </div>
                    <div className='InputCell' style={{display:'flex', flexDirection:'row', flexWrap: 'wrap', whiteSpace:'nowrap'}}>
                        {deletingExpense.related.map(name => <Chip label={name}/>)}
                    </div>
                    <TextField
                        className='InputCell' fullWidth label={"코멘트"} inputProps={{style:{padding: 10}}}
                        value={deletingExpense.comment} onChange={(event)=> {}}
                    />
                    <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                        <label style={{paddingRight: 10, lineHeight:'30px'}}>{"영수증"}</label>
                        <EditableFileField
                            type='file'
                            accept='jpg,png,pdf'
                            isEditing={false}
                            defaultValue={deletingExpense.receipt || ""}
                            field={"receipt"}
                            update={(value) =>{}}
                        />
                    </div>
                </Paper>
                :<></>}
            </DialogContent>
            <DialogActions>
                <Button color={"primary"} onClick={()=>{setDeletingExpense(null)}}>{"취소"}</Button>
                <Button variant={"contained"} color={"secondary"} onClick={()=>{
                    (async()=>{
                        if (deletingExpense){
                            await deleteExpense(deletingExpense)
                        }
                        setDeletingExpense(null);
                        setNeedRefresh(needRefresh+1)
                    })();
                }}>{"삭제하기"}</Button>
            </DialogActions>
        </Dialog>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Expense;