import React from 'react';
import { CellData } from './DataType';

const DummyField = (props: CellData & {update: (newValue:any) => void}) => {

    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue);
    },[props.defaultValue, setValue]);

    return (<div>{value}</div>);
}

export default DummyField;