import React from 'react';
import { TextField } from '@mui/material';
import { CellData } from './DataType';
import moment from 'moment-timezone';

const EditableDateField = (props: CellData & {type: 'date', update: (newValue:any) => void}) => {

    const mapper = ({defaultValue, fullDate, timeOnly, monthOnly}: {defaultValue: number, fullDate?: boolean, timeOnly?: boolean, monthOnly?: boolean}) =>
        !defaultValue?{ type: 'date',             value: undefined }
        :fullDate    ?{ type: 'datetime-local',   value: moment(defaultValue * 1000).format('YYYY-MM-DD HH:mm:ss'), }:
        timeOnly     ?{ type: 'time',             value: moment(defaultValue * 1000).format('hh:mm:ss'), }:
        monthOnly    ?{ type: 'month',            value: moment(defaultValue * 1000).format('YYYY-MM'), }:
        /*deafult*/   { type: 'date',             value: moment(defaultValue * 1000).format('YYYY-MM-DD'), };

    const [data, setData] = React.useState(mapper(props));

    React.useEffect(()=>{
        setData(mapper(props));
    },[props])
    
    return (<TextField
        disabled={!props.isEditing}
        type={data.type}
        value={data.value || ""}
        onChange={(event) => setData(mapper({...props, defaultValue: new Date(event.target.value).getTime()/1000}))}
        onBlur={(event)=>{
            let newData = {} as any; newData[props.field] = new Date(event.target.value).getTime()/1000;
            props.update(newData);
        }}
        style={{...props.style}}
        fullWidth={props.fullWidth || false}
        inputProps={{...(props.timeOnly?{step:3600}:{}), style:{padding: 5}}}
    />);
}

export default EditableDateField;