import React from 'react';
import { Expense as ExpenseType, readExpense } from 'Data/Expense';
import moment from 'moment-timezone';

import 'Page/Report.scss';
import 'Page/Expense.scss';

import EditableDateField from 'Component/Table/EditableDateField';
import { fn } from 'Functions';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { Button } from '@mui/material';
import { useSession } from 'Recoil/Session/Session';

const CommaSeperate = (num: number) => String(num).split('').reverse().map((char,idx,arr) => (idx % 3 === 2 && idx<arr.length - 1)?[char, ',']:[char]).flat().reverse().join('')

const ExpenseReport = () => {
    const {session} = useSession();
    
    if (session.userid === null || session.memberState !== 'regular'){
        fn.goto('/setting?redirectTo=/expense/report');
    }

    const [data, setData] = React.useState([] as (ExpenseType & {expenseid: number})[]);
    const [isLoading, setIsLoading] = React.useState(false);

    const [month, setMonth] = React.useState(new Date().getTime()/1000 - 10 * 24 * 60 * 60);

    React.useEffect(()=>{
        let isFinished = false;
        setIsLoading(true);
        (async()=>{
            let startDayOfThisMonth = moment(month * 1000).startOf('month').unix();
            let endDayOfThisMonth = moment(month * 1000).endOf('month').unix();
            let expenses = await readExpense(session.userid, startDayOfThisMonth, endDayOfThisMonth);

            if (!isFinished){
                setData(expenses.filter(row => row.state === 'confirmed'));
                setIsLoading(false);
            }
        })();
        return ()=>{
            isFinished = true;
        }
    },[month, session.userid]);

    return (<div className={"Expense"}>
        <h1>
            <img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>
            {session.name} {"비용 보고서"}
        </h1>
        
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{marginRight: 10}}>{"조회기간"}</h3>
            <EditableDateField
                type={'date'}
                isEditing={true}
                defaultValue={month}
                monthOnly={true}
                field={"month"}
                update={(newValue)=>{
                    setMonth(newValue.month)
                }}
            />
            <Button>{"확인"}</Button>
        </div>
        <div style={{width: "100%", textAlign:'right', marginBottom: 20, fontSize: '0.8rem'}}>
            {`이하 보고서는 조회기간에 대해 승인된 비용이며 정산 시스템에서 대조가 가능합니다.`}<br/>{`발행일시 ${moment().format('YYYY-MM-DD HH:mm:ss')}`}
        </div>

        <div className='Section'>
            <div style={{textAlign:'right', width: '100%', marginBottom: 20}}>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 건수 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(data.map(row => 1).reduce( (a,b) => a+b,0 ))}</div>
                </div>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 결제액 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(data.map(row => row.amount).reduce( (a,b) => a+b,0 ))}</div>
                </div>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 지급(예정)액 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(data.map(row => row.request_amount).reduce( (a,b) => a+b,0 ))}</div>
                </div>
        
            </div>
            <h2>{"요약"}</h2>
            <table className='Table' style={{textAlign: 'left', width: '100%'}}><tbody>
                <tr className='TableRow'>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'비목'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'건수'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'결제액'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'청구액'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className='Label'>{'청구월'}</label>
                    </td>
                </tr>
                {Object.entries(data
                    .map(({type, cardid, amount, request_amount}) => ({[`${cardid}/${type}`]:{ amount, request_amount, count:1}}))
                    .reduce( (a,b) => {
                        for(let type of Object.keys(b)){
                            a[type] = {
                                amount:         ((a[type] || {}).amount         || 0) + b[type].amount,
                                request_amount: ((a[type] || {}).request_amount || 0) + b[type].request_amount,
                                count:          ((a[type] || {}).count          || 0) + b[type].count
                            };
                        }

                        return a;
                    }, {} as {[type: string]: {request_amount: number, amount: number, count: number}})
                )
                .map( ([type, row]) =>
                    <tr key={type} className='TableRow'>
                        <td className={"TableCell DataCell"} style={{textAlign: 'left'}}>
                            {type}
                        </td>
                        <td className={"TableCell DataCell"}>
                            {row.count}
                        </td>
                        <td className={"TableCell DataCell"}>
                            <span style={{display: 'inline-block', width: 80}}> {CommaSeperate(row.amount)}</span>
                        </td>
                        <td className={"TableCell DataCell"}>
                            <span style={{display: 'inline-block', width: 80}}> {CommaSeperate(row.request_amount)}</span>
                        </td>
                        <td className={"TableCell DataCell"}>
                            {moment((data[0].requested_at || 0)*1000).format('YYYY-MM')}
                        </td>
                        
                    </tr>
                )
            }</tbody></table>
        </div>
        <div className='Section'>
            <h2>{"청구내역"}</h2>
            <table className={"Table"}><tbody>
                <tr className='TableRow'>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{"연번"}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{"비목"}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{"인원"}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{"결제액"}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{"청구액"}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{"사용일 / 상세"}</label>
                    </td>

                </tr>
                {data.map(row =>
                <tr key={row.expenseid} className='TableRow'>
                    <td className={"TableCell DataCell"}>
                        {row.expenseid}
                    </td>
                    <td className={"TableCell DataCell"} style={{textAlign: 'left', whiteSpace: 'nowrap'}}>
                        {row.cardid}{"/"}{row.type}
                    </td>
                    <td className={"TableCell DataCell"}>
                        <span style={{display: 'inline-block'}}> {CommaSeperate(row.related?.length)}</span>
                    </td>
                    <td className={"TableCell DataCell"}>
                        <span style={{display: 'inline-block'}}> {CommaSeperate(row.amount)}</span>
                    </td>
                    <td className={"TableCell DataCell"}>
                        <span style={{display: 'inline-block'}}> {CommaSeperate(row.request_amount)}</span>
                    </td>
                    <td className={"TableCell DataCell"}>
                        <span style={{display: 'inline-block'}}> {moment((row.occured_at || 0)*1000).format('YYYY-MM-DD')}</span>
                        <br/>
                        <span style={{display: 'inline-block', whiteSpace: "nowrap"}}> {row.comment}</span>
                    </td>
            
                </tr>
                )
            }</tbody></table>
        </div>
        <div className='Section'>
        <h2>{"영수증"}</h2>
            <div className={"Receipts"}>
                <table>
                    <tbody>
                        {Array(Math.ceil(data.length / 2)).fill(0).map( (v,i) =>
                            <tr key={data[i*2].expenseid}>
                                <td>
                                    <div>
                                        <div className={"Detail"} style={{backgroundColor: 'rgb(244,244,244)'}}>
                                            <label className={"Label"}>{"연번"}</label>{data[i*2]?.expenseid}<label className={"Label"}>{"비목"}</label>{data[i*2]?.type}
                                            <label className={"Label"}>{"결제일"}</label>{moment((data[i*2]?.occured_at || 0) * 1000).format('YYYY-MM-DD')} <br/>
                                            <label className={"Label"}>{"상세"}</label>{data[i*2]?.comment} <br/>
                                            <label className={"Label"}>{"참석"}</label>{data[i*2]?.related?.join(', ')}
                                        </div>
                                        <img
                                            style={{width: '100%', objectFit: 'cover'}}
                                            className={"ReceiptCell"}
                                            src={data[i*2].receipt?`https://cached-api.webtoon.today/thumb?mw=700&u=${encodeURIComponent(data[i*2].receipt || "")}`:""}
                                            onError={(event)=>{
                                                if (event.target && (event.target as HTMLElement).getAttribute('src') === `https://cached-api.webtoon.today/thumb?mw=700&u=${encodeURIComponent(data[i*2].receipt || "")}`){
                                                    (event.target as HTMLElement).setAttribute('src', data[i*2].receipt || "");
                                                }
                                            }}
                                            alt={'receipt'}
                                        />
                                    </div>
                                </td>
                                {data.length > i*2+1
                                ?<td>
                                    <div>
                                        <div className={"Detail"} style={{backgroundColor: 'rgb(244,244,244)'}}>
                                            <label className={"Label"}>{"연번"}</label>{data[i*2+1]?.expenseid}<label className={"Label"}>{"비목"}</label>{data[i*2+1]?.type} 
                                            <label className={"Label"}>{"결제일"}</label>{moment((data[i*2+1]?.occured_at || 0) * 1000).format('YYYY-MM-DD')} <br/>
                                            <label className={"Label"}>{"상세"}</label>{data[i*2+1]?.comment} <br/>
                                            <label className={"Label"}>{"참석"}</label>{data[i*2+1]?.related?.join(', ')}
                                        </div>
                                        <img
                                            style={{width: '100%', objectFit: 'cover'}}
                                            className={"ReceiptCell"}
                                            src={data[i*2+1].receipt?`https://cached-api.webtoon.today/thumb?mw=700&u=${encodeURIComponent(data[i*2+1]?.receipt || "")}`:""}
                                            onError={(event)=>{
                                                if (event.target && (event.target as HTMLElement).getAttribute('src') === `https://cached-api.webtoon.today/thumb?mw=700&u=${encodeURIComponent(data[i*2+1].receipt || "")}`){
                                                    (event.target as HTMLElement).setAttribute('src', data[i*2+1].receipt || "");
                                                }
                                            }}
                                            alt={'receipt'}
                                        />
                                    </div>
                                </td>
                                :<></>}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default ExpenseReport;