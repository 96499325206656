import axios from 'axios';
import ss from './Session';

export type SalesType = {

    requested_at: number,
    teamid: string,
    platform: string,
    note: string,

    gross: number,
    payment_commission: number,
    platform_commission: number,

    net: number,

};

export const deleteSales = async (sales: SalesType,) => {
    let res = await axios.delete(`https://admin-api.webtoon.today/sales?`
            +`requested_at=${encodeURIComponent(sales.requested_at)}`
            +`&teamid=${encodeURIComponent(sales.teamid)}`
            +`&platform=${encodeURIComponent(sales.platform)}`
            +`&note=${encodeURIComponent(sales.note)}`,
        {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};
export const createOrUpdateSales = async (sales: SalesType[],) => {

    let res = await axios.put('https://admin-api.webtoon.today/sales', sales, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return true;
    } else {
        return new Error( res.data.message );
    };
};

export const readSales = async (teamid: string) => {
    let res = await axios.post(`https://admin-api.webtoon.today/sales`, {teamid}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.data && res.data.code === 200) {

        return res.data.data as SalesType[];

    } else {
        return []
    };
};
