import { Button, Checkbox, MenuItem, Paper, Select, TextField } from "@mui/material";
import EditableFileField from "Component/Table/EditableFileField";
import { CounterpartType, createCounterpart, updateCounterpart } from "Data/Counterpart";
import { MembershipType } from "Data/Membership";
import React from "react";



const CounterPartCell = ({newCounterpart: givenCounterpart, teamData, refreshCallback}: {newCounterpart: CounterpartType, teamData: MembershipType[], refreshCallback: ()=>void}) => {

    const [newCounterpart, setNewCounterpart] = React.useState(givenCounterpart);

    React.useEffect(()=>{
        setNewCounterpart(givenCounterpart);
    },[givenCounterpart]);

    console.log(newCounterpart)

    return <Paper elevation={2} className={"NewExpense"}>
        <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row', flexShrink: 0}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"거래처명"}</label>
                <TextField inputProps={{style:{padding: 10}}} fullWidth
                    label={"가급적 실명 사용"}
                    value={newCounterpart.name || ''}
                    onChange={event => setNewCounterpart({...newCounterpart, name: event.target.value})}
                />
                <label style={{paddingLeft: 10, lineHeight:'40px'}}>{"종료"}</label>
                <Checkbox checked={newCounterpart.finished || false} onChange={(event) => {setNewCounterpart({...newCounterpart, finished:event.target.checked})}}/>
            </div>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row', flexShrink: 0}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"관련작품"}</label>
                <Select fullWidth size={"small"}
                    style={{overflow: 'hidden'}}
                    value={newCounterpart.teamid || []}
                    multiple
                    onChange={(event) => setNewCounterpart({...newCounterpart, teamid: typeof event.target.value === 'string'?[event.target.value]:event.target.value})}>
                    {teamData.map(team => 
                        <MenuItem key={team.teamid} value={team.teamid}>{team.name}</MenuItem>
                    )}
                </Select>
            </div>
        </div>  
        <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"계좌번호"}</label>
                <TextField inputProps={{style:{padding: 10, width: 100, flexShrink:0}}}
                    value={newCounterpart.bank_name || ''} label={"은행"}
                    onChange={event => setNewCounterpart({...newCounterpart, bank_name: event.target.value})}
                />
                <TextField inputProps={{style:{padding: 10}}} fullWidth
                    value={newCounterpart.account_num || ''} label={"계좌번호"}
                    onChange={event => setNewCounterpart({...newCounterpart, account_num: event.target.value})}
                />
            </div>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"주민번호"}</label>
                <TextField inputProps={{style:{padding: 10}}} fullWidth
                    value={newCounterpart.id_num || ''}
                    onChange={event => setNewCounterpart({...newCounterpart, id_num: event.target.value})}
                />
            </div>
        </div>
        <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"통장사본"}</label>
                <EditableFileField
                    type={"file"} isEditing={true}
                    defaultValue={newCounterpart.account_img} onLoad={()=>{}} field={'account_img'}
                    update={(newValue) => setNewCounterpart({...newCounterpart, ...newValue})}
                    accept=''
                />
            </div>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"신분증　"}</label>
                <EditableFileField
                    type={"file"} isEditing={true}
                    defaultValue={newCounterpart.id_img} onLoad={()=>{}} field={'id_img'}
                    update={(newValue) => setNewCounterpart({...newCounterpart, ...newValue})}
                    accept=''
                />
            </div>
        </div>
        <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"업무개요"}</label>
                <TextField inputProps={{style:{padding: 10}}} fullWidth
                    value={newCounterpart.comment || ''}
                    onChange={event => setNewCounterpart({...newCounterpart, comment: event.target.value})}
                />
            </div>
            <div className='Child' style={{width: 'calc(50% - 10px)', display: 'flex', flexDirection: 'row'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"기준 단가"}</label>
                <TextField inputProps={{style:{padding: 10}}} fullWidth type={"number"}
                    value={newCounterpart.unit_amount || ''}
                    onChange={event => setNewCounterpart({...newCounterpart, unit_amount: Number(event.target.value)})}
                />
            </div>
        </div>
        <Button fullWidth variant={"outlined"} color={"primary"} onClick={async ()=>{
            if (newCounterpart.counterpartid){
                await updateCounterpart(newCounterpart);
            }else {
                await createCounterpart(newCounterpart);
            }
            setNewCounterpart({
                counterpartid: '',
                teamid: [],
                name: '',
                bank_name: '',
                account_num: '',
                account_img: '',
                id_num: '',
                id_img: '',
                comment: '',
                unit_amount: 0,
                finished: false,
            });
            refreshCallback();
        }}>{"저장"}</Button>
    </Paper>;
}

export default CounterPartCell;