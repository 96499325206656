import React, { CSSProperties } from 'react';
import EditableRow from './EditableRow';
import {utils, writeFile} from 'xlsx';
import { IconButton } from '@mui/material';
import { SwapVert, CloudDownload, KeyboardArrowDown, KeyboardArrowUp, } from '@mui/icons-material';

import './EditableTable.scss';
import { CellData } from './DataType';


const jsxToString = (jsx: React.ReactElement): string => {
    if (jsx.props && jsx.props.children){
        if (jsx.props.children instanceof Array){
            return jsx.props.children.map((child: React.ReactElement) => jsxToString(child)).join("/")
        }else {
            return jsxToString(jsx.props.children)
        }
    }else {
        return String(jsx)
    }
}

const extractValue = (columnData: CellData): string|number|React.ReactElement => {
    if (columnData.type === 'select') {
        return columnData.options.filter(option => option.value === columnData.defaultValue).concat([{key: "", value: ''}])[0].key
    }else if (columnData.defaultValue && typeof columnData.defaultValue !== 'string' && typeof columnData.defaultValue !== 'number'){
        return jsxToString(columnData.defaultValue)
    }else{
        return columnData.defaultValue || ''
    }
}

export type EditableTablePropType<T> = {
    headerButtonHeaders: (React.ReactElement | string)[],
    columnHeaders: (React.ReactElement | string)[],
    defaultSortCol?: [number, 'asc' | 'desc'],
    headerButtonsFunction: (row: T, index: number) => any[],
    columnsFunction: (row: T, index: number) => CellData[],
    updateGenerator: (row: T, index: number) => (newValue: Partial<T>) => Promise<void>,
    style?: CSSProperties,
    data: T[],
    name?: string,
    isDownloadable?: boolean,
    className?: string,
};

const EditableTable = ({
    headerButtonHeaders, columnHeaders,
    headerButtonsFunction, columnsFunction,
    updateGenerator, data,
    name="noname", defaultSortCol = [0, 'asc'], isDownloadable=false,
    style = {}, className =""
}: EditableTablePropType<any>) => {
    
    const [ownSortCol, setOwnSortCol] = React.useState(defaultSortCol);

    return (
        <div className={`EditableTableRoot ${className}`} style={style}>
            <table>
                <thead>
                    <tr>
                        {
                            headerButtonHeaders
                            .map( (headerButtonHeader,index) => 
                                <th key={index}>
                                    {headerButtonHeader}
                                </th>)
                        }
                        {
                            columnHeaders
                            .map( (columnHeader, index) => 
                                <th key={index + headerButtonHeaders.length}>
                                    <div className={"InnerContainer"}>
                                        {columnHeader}
                                        <IconButton
                                            className={"IconBtn"}
                                            onClick={(event)=> {
                                                ownSortCol[0] === index
                                                    ?setOwnSortCol([index, ownSortCol[1] === 'asc'?'desc':'asc'])
                                                    :setOwnSortCol([index, 'asc'])
                                            }}
                                        >
                                            {ownSortCol[0] !== index
                                                ?<SwapVert style={{fontSize:'0.9rem'}}/>
                                                :ownSortCol[1] === 'asc'
                                                    ?<KeyboardArrowUp style={{fontSize:'0.9rem', color: 'white'}}/>
                                                    :<KeyboardArrowDown style={{fontSize:'0.9rem', color: 'white'}}/>}
                                        </IconButton>
                                    </div>
                                </th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    {data
                    .map( (a,i) => [a,i] )
                    .sort( ([a,ai], [b,bi]) =>
                        !ownSortCol
                            ?(ai - bi)
                            :ownSortCol[1] === 'desc'
                                ? extractValue(columnsFunction(a,0)[ownSortCol[0]]) < extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1
                                : extractValue(columnsFunction(a,0)[ownSortCol[0]]) > extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1 )
                    .map( ([a,i]) => a )
                    .map( (row,index) => 
                        <EditableRow
                            isHeader={false}
                            rowId={row.rowId || ""}
                            key={index}
                            style={{backgroundColor:index%2===0?"#EEEEEE":"#FFFFFF"}}
                            headerButtons={headerButtonsFunction(row, index)}
                            fields={columnsFunction(row, index)}
                            update={updateGenerator(row, index)}
                        />
                    )}
                </tbody>
            </table>
            {isDownloadable?<>
            <table style={{display:"none"}} className={"downloadableTable"}>
                <tbody>
                    <tr style={{color: "#FFFFFF", backgroundColor: '#333333'}}>
                        {
                            columnHeaders
                            .map((columnHeader,index) => 
                                <td key={index}>
                                    {columnHeader}
                                </td>)
                        }
                    </tr>
                    {data
                    .map( (a,i) => [a,i] )
                    .sort( ([a,ai], [b,bi]) =>
                        !ownSortCol
                            ?(ai - bi)
                            :ownSortCol[1] === 'desc'
                                ? extractValue(columnsFunction(a,0)[ownSortCol[0]]) < extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1
                                : extractValue(columnsFunction(a,0)[ownSortCol[0]]) > extractValue(columnsFunction(b,0)[ownSortCol[0]]) ? 1:-1 )
                    .map( ([a,i]) => a )
                    .map( (row,index) => 
                        <EditableRow
                            isHeader={false}
                            key={index}
                            rowId={row.rowId}
                            style={{backgroundColor:index%2===0?"#EEEEEE":"#FFFFFF"}}
                            headerButtons={[]}
                            fields={columnsFunction(row, index).map( (row: any) => Object.assign(row, {isEditing: false}))}
                            update={updateGenerator(row, index)}
                            forDownload={true}
                        />
                    )}
                </tbody>
            </table>
        
            <IconButton
                style={{
                    position: 'absolute',
                    top: 40, right: 5,
                    display:data.length===0?'none':'block',
                    backgroundColor:'rgba(155,155,155,0.2)',
                    padding: 5,
                }}
                onClick={async (event)=>{
                    try{
                        let currentTarget = event.target as HTMLElement;
                        /* convert table 'table1' to worksheet named "Sheet1" */
                        var ws = utils.table_to_sheet(
                            currentTarget.parentElement?.querySelector('.downloadableTable')
                            || currentTarget.parentElement?.parentElement?.querySelector('.downloadableTable')
                            || currentTarget.parentElement?.parentElement?.parentElement?.querySelector('.downloadableTable')
                            || currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.querySelector('.downloadableTable')
                        );

                        /* create new workbook */
                        let workbook = utils.book_new();
                        
                        console.log(workbook)
                        for (const col of 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.substr(0,columnHeaders.length)){
                            ws[col+1].s = {
                                fill: {
                                    patternType: 'none',
                                    fgColor: { rgb :"FFFFFFFF"},
                                    bgColor: { rgb :"FF000000"},
                                }
                            }
                        }
                        
                        utils.book_append_sheet(workbook, ws, name.substr(0,31));
                        
                        writeFile(workbook, `${name}-${new Date().toISOString()}.xlsx`)
                    }catch(e){
                        console.error(e)
                    }
                }}
            ><CloudDownload/></IconButton>
            </>:<></>}
        </div>
    );
}

export default EditableTable;