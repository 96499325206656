import axios from 'axios';
import ss from './Session';

export type Settlement = {
    settlementid?: number,
    state: 'edit'|'confirmed'|'rejected',
    userid?: string,
    type: string,
    teamid: string,
    artname: string,
    episodename: string,
    membername: string,
    
    unit_amount: number,
    request_count: number,
    request_amount: number,
    tax_rate: number,
    
    receipt: string | null,
    comment: string | null,
    
    occured_at?: number,
    requested_at?: number,
}

export const deleteSettlement = async (settlement: (Settlement & {settlementid: number}),) => {
    let res = await axios.delete(`https://admin-api.webtoon.today/settlement?settlementid=${settlement.settlementid}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

export const createSettlement = async (settlement: Settlement,) => {
    let res = await axios.put('https://admin-api.webtoon.today/settlement', {
            ...settlement,
            unit_amount: Number(settlement.unit_amount),
            request_count: Number(settlement.request_count),
            request_amount: Number(settlement.request_amount),
            
            occured_at: Number(settlement.occured_at),
            requested_at: Number(settlement.requested_at),
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

export const updateSettlement = async (settlement: Settlement,) => {

    let res = await axios.patch('https://admin-api.webtoon.today/settlement', {
        ...settlement,
        unit_amount: Number(settlement?.unit_amount || 0),
        request_count: Number(settlement?.request_count || 0),
        request_amount: Number(settlement?.request_amount || 0),
        
        occured_at: Number(settlement.occured_at),
        requested_at: Number(settlement.requested_at),
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

export const readSettlement = async (start_from?: number, end_to?:number, teamid?: string) => {
    let res = await axios.post(`https://admin-api.webtoon.today/settlement`, {start_from, end_to, teamid}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.data && res.data.code === 200) {

        return res.data.data as Settlement[];

    } else {
        return []
    };
};
