import React from 'react';

import {Collapse, IconButton, Alert} from '@mui/material';
import { Close } from '@mui/icons-material';

const ToastAutoClose = ({className="", style={}, message="", timeout=1000, severity="info"}) => {

    const [infoMessage, setInfoMessage] = React.useState(message);

    React.useEffect(()=>{
        setInfoMessage(message);

        let timer = setTimeout(()=>{
            setInfoMessage("")
        }, timeout);

        return ()=> {
            clearTimeout(timer);
        }
    },[message, timeout])

    return (
    <Collapse style={style} in={((infoMessage || '').length) > 0}>
        <Alert className={className} severity={severity} action={
            <IconButton size={"small"} onClick={()=>setInfoMessage("")}>
                <Close/>
            </IconButton>}>
            {message}
        </Alert> 
    </Collapse>
    )

}

export default ToastAutoClose;