import React, { useEffect } from 'react';
import EditableTable from 'Component/Table/EditableTable';
import { createSettlement, Settlement as SettlementType, readSettlement, updateSettlement } from 'Data/Settlement';
import { Button, Input, MenuItem, Paper, Select, Switch, TextField } from '@mui/material';
import moment from 'moment-timezone';

import '../Expense.scss';
import EditableFileField from 'Component/Table/EditableFileField';
import EditableDateField from 'Component/Table/EditableDateField';
import { fn } from 'Functions';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { MembershipType, readMemberships } from 'Data/Membership';
import { CounterpartType, readCounterparts } from 'Data/Counterpart';
import CounterPartCell from './Component/CounterpartCell';
import { useSession } from 'Recoil/Session/Session';
import { useToastAlert } from '@webtoontoday/toast';

type T = any;
const unique = (value:T, index:number, array:T[]) => index === array.indexOf(value);
const NumberSafe = (num: any) => typeof num === 'number'?num:(Number.isNaN(Number(num))?0:Number(num));

const Settlement = () => {
    const {session} = useSession();

    if (session.userid === null || session.memberState !== 'regular'){
        fn.goto('/setting?redirectTo=/settlement');
    }

    const {toastAlert} = useToastAlert();

    useEffect(()=>{
       toastAlert("외주 작업이 아닌 실비 정산인 경우 세율 0%로 입력해주세요.", 2000);
    },[toastAlert])

    const [data, setData] = React.useState([] as SettlementType[]);
    const [needRefresh, setNeedRefresh] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [showNewCounterpart, setShowNewCounterpart] = React.useState(false);
    
    const [month, setMonth] = React.useState(new Date().getTime()/1000 - 10 * 24 * 60 * 60);

    const [arts, setArts] = React.useState([] as string[]);
    const [teamData, setTeamData] = React.useState([] as MembershipType[]);
    const [teamDict, setTeamDict] = React.useState({} as {[key: string]: MembershipType});
    useEffect(()=>{
        (async()=>{
            let memberships = await readMemberships();
            setTeamData(memberships.filter(membership => membership.userid === session.userid));

            setArts(memberships.filter(row => !row.closed).map(row => `${row.name}:${row.teamid}`).filter(unique));
            setTeamDict(
                memberships
                .filter(row => row.userid === session.userid)
                .map(row => ({[row.name]: row}))
                .reduce( (a,b) => ({...a, ...b}), {})
            )
        })();
    },[needRefresh, session.userid])

    useEffect(()=>{
        let isFinished = false;
        setIsLoading(true);
        (async()=>{
            let startDayOfThisMonth = moment(month * 1000).startOf('month').unix();
            let endDayOfThisMonth = moment(month * 1000).endOf('month').unix();
            let settlements = await readSettlement(startDayOfThisMonth, endDayOfThisMonth);

            if (!isFinished){
                setData(settlements);
                setIsLoading(false);
            }
        })();
        return ()=>{
            isFinished = true;
        }
    },[month, needRefresh]);

    const [newSettlement, setNewSettlement] = React.useState({
        state: 'edit',
        type: 'MG',
        teamid: '',
        artname: '',
        episodename: '',
        membername: '',

        occured_at: (new Date()).getTime()/1000,
        requested_at: (new Date()).getTime()/1000,
        
        unit_amount: 0,
        request_count: 0,
        request_amount: 0,
        tax_rate: 3.3,
        
        receipt: "",
        comment: "",
    } as (SettlementType | (SettlementType & {settlementid: number})) )

    const [counterparts, setCounterparts] = React.useState([] as CounterpartType[]);
    useEffect(()=>{
        (async()=>{
            let counterparts = await readCounterparts();
            setCounterparts(counterparts);
        })();
    },[needRefresh])

    return (<div className={"Expense"}>
        <h1>
            <img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>
            {"작품 정산 작성"}
        </h1>
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
            <Switch checked={showNewCounterpart} onChange={(event, checked)=>setShowNewCounterpart(checked)}/>
            <label >{'새로운 거래처 작성'}</label>
        </div>
        {showNewCounterpart?<CounterPartCell newCounterpart={{
            counterpartid: '', teamid: [], name: '', bank_name: '',
            account_num: '', account_img: '', id_num: '', id_img: '',
            comment: '', unit_amount: 0, finished: false
        }} teamData={teamData} refreshCallback={()=>{
            setNeedRefresh(needRefresh+1);
        }}/>:<></>}
        <Paper elevation={2} className={"NewExpense"}>
            <h2>{('settlementid' in newSettlement)?"수정":"새로 작성"}</h2>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <Select
                    className='Child' fullWidth label={"작품명"} inputProps={{style:{padding: 10}}} size={"small"}
                    value={`${newSettlement.artname}:${newSettlement.teamid}`} onChange={(event)=> setNewSettlement(newSettlement => ({...newSettlement, artname: event.target.value.split(':')[0], teamid: event.target.value.split(':')[1]}))}
                >
                    {arts.sort().map(art =>
                        <MenuItem key={art} value={art}>{art.split(':')[0]}</MenuItem>
                    )}
                </Select>
                <TextField
                    className='Child' fullWidth label={"에피소드정보"} inputProps={{style:{padding: 10}}}
                    value={newSettlement.episodename} onChange={(event)=> setNewSettlement(newSettlement => ({...newSettlement, episodename: event.target.value}))}
                />
                <Select size='small'
                    className='Child' fullWidth label={"대상자"}
                    value={newSettlement.membername} onChange={(event)=> setNewSettlement(newSettlement => ({
                        ...newSettlement,
                        membername: event.target.value,
                        unit_amount: (counterparts.filter(counterpart => counterpart.counterpartid === event.target.value)[0] || {}).unit_amount || 0,
                        request_count: 1,
                        request_amount: (counterparts.filter(counterpart => counterpart.counterpartid === event.target.value)[0] || {}).unit_amount || 0,
                    }))}
                >
                    {counterparts.filter(counterpart => teamDict[newSettlement.artname] && counterpart.teamid.find(team => team === teamDict[newSettlement.artname].teamid) && !counterpart.finished).map(counterpart => 
                        <MenuItem key={counterpart.counterpartid} value={counterpart.counterpartid}>{counterpart.name}</MenuItem>
                    )}
                </Select>
            </div>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"비목"}</label>
                <Select
                    className='InputCell' fullWidth size='small'
                    value={newSettlement.type} onChange={(event)=> setNewSettlement(newSettlement => ({
                        ...newSettlement,
                        type: event.target.value,
                        tax_rate: event.target.value === '상금'
                            ?newSettlement.request_amount>100000?4.4:0
                            :3.3
                    }))}
                >
                    <MenuItem value={"MG"}>{"MG"}</MenuItem>
                    <MenuItem value={"RS"}>{"RS"}</MenuItem>
                    <MenuItem value={"채색"}>{"채색"}</MenuItem>
                    <MenuItem value={"선화"}>{"선화"}</MenuItem>
                    <MenuItem value={"후보정"}>{"후보정"}</MenuItem>
                    <MenuItem value={"배경"}>{"배경"}</MenuItem>
                    <MenuItem value={"식자"}>{"식자"}</MenuItem>
                    <MenuItem value={"스케치"}>{"스케치"}</MenuItem>
                    <MenuItem value={"콘티"}>{"콘티"}</MenuItem>
                    <MenuItem value={"스토리"}>{"스토리"}</MenuItem>
                    <MenuItem value={"작업도구"}>{"작업도구"}</MenuItem>
                    <MenuItem value={"상금"}>{"상금"}</MenuItem>
                    <MenuItem value={"샘플"}>{"샘플"}</MenuItem>
                    <MenuItem value={"마케팅"}>{"마케팅"}</MenuItem>
                    <MenuItem value={"기타"}>{"기타"}</MenuItem>
                </Select>
            </div>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', paddingBottom: 10, textAlign:'right', alignItems: 'flex-end'}}>
                <TextField
                    className='Child' fullWidth label={"단위금액"} inputProps={{style:{padding: 10, textAlign:'right'}}} type={"number"}
                    value={newSettlement.unit_amount}
                    onChange={(event)=> setNewSettlement(newSettlement => ({
                        ...newSettlement,
                        unit_amount: NumberSafe(event.target.value),
                        request_count: Math.max(newSettlement.request_count || 0,1),
                        request_amount: Math.max(newSettlement.request_count || 0,1) * NumberSafe(event.target.value),
                        tax_rate: newSettlement.type === '상금'
                            ?((newSettlement.request_count || 0) * NumberSafe(event.target.value))>100000?4.4:0
                            :3.3
                    }))}
                />
                <TextField
                    className='Child' fullWidth label={"건수"} inputProps={{style:{padding: 10, textAlign:'right'}}} type={"number"}
                    value={newSettlement.request_count}
                    onChange={(event)=> setNewSettlement(newSettlement => ({
                        ...newSettlement,
                        request_count: NumberSafe(event.target.value),
                        request_amount: (newSettlement.unit_amount || 0) * NumberSafe(event.target.value),
                        tax_rate: newSettlement.type === '상금'
                            ?((newSettlement.unit_amount || 0) * NumberSafe(event.target.value))>100000?4.4:0
                            :3.3
                    }))}
                />
                <TextField
                    className='Child' fullWidth label={"청구액"} inputProps={{style:{padding: 10, textAlign:'right'}}} type={"number"}
                    value={newSettlement.request_amount}
                    onChange={(event)=> setNewSettlement(newSettlement => ({
                        ...newSettlement,
                        request_amount: NumberSafe(event.target.value),
                        tax_rate: newSettlement.type === '상금'
                            ?(NumberSafe(event.target.value))>100000?4.4:0
                            :3.3
                    }))}
                />
                <TextField
                    className='Child' fullWidth label={"세율"} inputProps={{style:{padding: 10, textAlign:'right'}}} type={"number"}
                    value={newSettlement.tax_rate} onChange={(event)=> setNewSettlement(newSettlement => ({...newSettlement, tax_rate: (Number.isNaN(Number(event.target.value))?0.0:Number(event.target.value)) }))}
                /><span style={{padding: 5}}>{"%"}</span>
            </div>
            <div style={{display:'flex', flexDirection:'row', paddingBottom: 10,}}>
                <label style={{paddingRight: 10, lineHeight:'30px', whiteSpace:'nowrap'}}>{"원고마감일"}</label>
                <Input
                    fullWidth type={"date"}
                    value={moment((newSettlement.occured_at || new Date().getTime()/1000)*1000).format('YYYY-MM-DD')}
                    onChange={(event) => {
                        setNewSettlement(newSettlement => ({
                            ...newSettlement,
                            occured_at: (new Date(event.target.value+' 00:00:00+09:00')).getTime()/1000
                        }))
                    }} 
                />
            </div>
            <div style={{display:'flex', flexDirection:'row', paddingBottom: 10,}}>
                <label style={{paddingRight: 10, lineHeight:'30px', whiteSpace:'nowrap'}}>{"청구대상월"}</label>
                <Input
                    fullWidth type={"month"}
                    value={moment((newSettlement.requested_at || new Date().getTime()/1000)*1000).format('YYYY-MM')}
                    onChange={(event) => {
                        setNewSettlement(newSettlement => ({
                            ...newSettlement,
                            requested_at: (new Date(event.target.value+' 00:00:00+09:00')).getTime()/1000
                        }))
                    }} 
                />
            </div>
            <TextField
                className='InputCell' fullWidth label={"코멘트"} inputProps={{style:{padding: 10}}}
                value={newSettlement.comment} onChange={(event)=> setNewSettlement(newSettlement => ({...newSettlement, comment: event.target.value}))}
            />
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <label style={{paddingRight: 10, lineHeight:'30px'}}>{"증빙"}</label>
                <EditableFileField
                    type='file'
                    accept='jpg,png,pdf'
                    isEditing={true}
                    defaultValue={newSettlement.receipt || ""}
                    field={"receipt"}
                    update={(value) =>{
                        setNewSettlement(newSettlement => ({...newSettlement, ...value}))
                    }}
                />
            </div>
            <Button fullWidth variant={"outlined"} disabled={isLoading} onClick={()=>{
                setIsLoading(true);
                if ('settlementid' in newSettlement){
                    (async()=>{
                        try{
                            await updateSettlement(newSettlement);
                        }catch(e){
                            fn.toastAlert((e as any).response.data.message, 2000);
                            setIsLoading(false);
                        }
                        setNeedRefresh(needRefresh+1);
                        setIsLoading(false);
                        
                    })();
                }else{
                    (async()=>{
    
                        try{
                            await createSettlement(newSettlement);
                        }catch(e){
                            fn.toastAlert((e as any).response.data.message, 2000);
                            setIsLoading(false);
                        }
                        setNeedRefresh(needRefresh+1);
    
                        setNewSettlement({
                            state: 'edit',
                            type: 'MG',
                            teamid: '',
                            artname: '',
                            episodename: '',
                            membername: '',
                    
                            occured_at: (new Date()).getTime()/1000,
                            requested_at: (new Date()).getTime()/1000,
                            tax_rate: 0.0,
                            
                            unit_amount: 0,
                            request_count: 0,
                            request_amount: 0,
                            
                            receipt: "",
                            comment: "",
                        });
                        setIsLoading(false);
                    })();
                }
            }}>{newSettlement.settlementid?"수정 완료":"새로 등록"}</Button>
        </Paper>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{marginRight: 10}}>{"조회기간"}</h3>
            <EditableDateField
                type={'date'}
                isEditing={true}
                defaultValue={month}
                monthOnly={true}
                field={"month"}
                update={(newValue)=>{
                    setMonth(newValue.month)
                }}
            />
            <Button>{"확인"}</Button>
        </div>
        <EditableTable
            headerButtonHeaders={[
                ''
            ]}
            headerButtonsFunction={(row: SettlementType & {settlementid: number}, index)=> [
                {name: '수정', color: 'primary', onClick: ()=>{
                    setNewSettlement(row)
                }, props: {}}
            ]}
            columnHeaders={['번호', '비목', '작품명', '에피소드명', '담당자명', '단위금액', '건수', '청구액', '세율', '영수증', '사용일', '청구월', '코멘트']}
            columnsFunction={(row: SettlementType & {settlementid: number}, index)=> [
                {isEditing: false, type: 'default', defaultValue: row.settlementid, field: 'settlementid', },
                {isEditing: false, type: 'default', defaultValue: row.type, field: 'type', style:{wordBreak: 'keep-all'} },
                {isEditing: false, type: 'select', defaultValue: row.teamid, field: 'artname', style:{wordBreak: 'keep-all'},
                    options: arts.map( (key) => ({key: key.split(':')[0], value: key.split(':')[1]}) )
                },
                {isEditing: false, type: 'default', defaultValue: row.episodename, field: 'episodename', style:{wordBreak: 'keep-all'} },
                {isEditing: false, type: 'select', defaultValue: row.membername, field: 'membername', style:{wordBreak: 'keep-all'}, options: counterparts.map(counterpart => ({key: counterpart.name, value: String(counterpart.counterpartid)})) },
                {isEditing: false, type: 'default', defaultValue: row.unit_amount, field: 'unit_amount', },
                {isEditing: false, type: 'default', defaultValue: row.request_count, field: 'request_count', },
                {isEditing: false, type: 'default', defaultValue: row.request_amount, field: 'request_amount', },
                {isEditing: false, type: 'default', defaultValue: row.tax_rate, field: 'tax_rate', },
                {isEditing: false, type: 'file', defaultValue: row.receipt || "", field: 'receipt', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} },
                {isEditing: false, type: 'date', defaultValue: row.occured_at || 0, field:'occured_at' },
                {isEditing: false, type: 'date', monthOnly: true, defaultValue: row.requested_at || 0, field:'requested_at' },
                {isEditing: false, type: 'default', defaultValue: row.comment, field: 'comment', },
            ]}
            data={data}
            updateGenerator={(row, index)=> async (newValue: any) => {}}
        />
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Settlement;