import axios from 'axios';
import ss from './Session';

export type CounterpartType = {
   counterpartid: string,
   teamid: string[],
   name: string,
   bank_name: string,
   account_num: string,
   account_img: string,
   id_num: string,
   id_img: string,
   comment: string,
   unit_amount: number,
   finished: boolean,
};

export async function readCounterparts (teamids: string[] = []) {
    try{
        let res = await axios.post('https://admin-api.webtoon.today/counterpart', {teamids}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data as (CounterpartType)[];

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

export async function createCounterpart (counterpart: CounterpartType) {
    try{
        let res = await axios.put('https://admin-api.webtoon.today/counterpart', counterpart, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function updateCounterpart (counterpart: CounterpartType) {
    try{
        let res = await axios.patch('https://admin-api.webtoon.today/counterpart', counterpart, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function deleteCounterpart (counterpart: CounterpartType) {
    try{
        let res = await axios.delete(`https://admin-api.webtoon.today/counterpart?counterpartid=${counterpart.counterpartid}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}