import React from 'react';
import {BranchFunction, fn} from './Functions';
import {Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button, } from '@mui/material';

import Setting from './Page/Setting';
import NotFound from './Page/NotFound';

import {logBehavior} from './Data/Behavior';
import './VirtualRouter.scss';
import Expense from './Page/Expense';
import Settlement from './Page/Settlement';
import ExpenseManager from './Page/Expense/Manager';
import SettlementManager from './Page/Settlement/Manager';
import Team from './Page/Team';
import ExpenseReport from './Page/Expense/Report';
import Counterpart from './Page/Settlement/Counterpart';
import SettlementReport from './Page/Settlement/Report';
import Sales from 'Page/Settlement/Sales';
import Art from 'Page/Settlement/Art';
import Email from 'Page/Settlement/Email';

const jsxBranch = (path: string, hash: string, newProps: any) => BranchFunction(
    path,
    [
        {
            validator: (path) => path.toLowerCase().startsWith("/settlement"),
            sublogic: [
                {
                    validator: (path) => path.toLowerCase().startsWith("/settlement/manager"),
                    defaultValue: () => <SettlementManager/>,
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/settlement/report"),
                    defaultValue: () => <SettlementReport/>,
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/settlement/counterpart"),
                    defaultValue: () => <Counterpart/>,
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/settlement/sales"),
                    defaultValue: () => <Sales/>,
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/settlement/email"),
                    defaultValue: () => <Email/>,
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/settlement/art/"),
                    defaultValue: () => {
                        let [ , teamid ] = /\/settlement\/art\/(.+)$/.exec(path) || [];
                        return <Art teamid={teamid}/>;
                    },
                },
            ],
            defaultValue: () => <Settlement/>,
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/team"),
            sublogic: [
            ],
            defaultValue: () => <Team/>,
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/expense"),
            sublogic: [
                {
                    validator: (path) => path.toLowerCase().startsWith("/expense/report"),
                    defaultValue: () => <ExpenseReport/>,
                },
                {
                    validator: (path) => path.toLowerCase().startsWith("/expense/manager"),
                    defaultValue: () => <ExpenseManager/>,
                },
            ],
            defaultValue: () => <Expense/>,
        },
        {
            validator: (path) => path.toLowerCase().startsWith("/setting") || path.toLowerCase() === "/",
            defaultValue: () => <Setting/>,
        },
        
    ],
    () => <NotFound />,
)

/**
 * 
 * @param {import('./App').globalDataType} props 
 */
const VirtualRouter = (props: {injectedLocation?: string, isClient: boolean, session: any}) => {

    /**
     * @type {[
     *      [string, set<string>],
     *      [size, set<size>],
     *      [number, set<number>],
     * ]}
     * */
    const [path, setPath] = React.useState((props.injectedLocation || window.location.pathname).replace(window.location.origin, ""));
    const [hash, setHash] = React.useState((props.injectedLocation || '').split('#')[1] || window.location.hash);

    React.useEffect(()=>{
        const historyListener = ()=>{
            setPath(window.location.pathname);
            setHash(window.location.hash);
        };
        window.addEventListener('popstate', historyListener);
        return ()=> {
            window.removeEventListener('popstate', historyListener);
        }
    },[])

    const [goto, ] = React.useState(() => (urlOrPath?: string) => {
        if (urlOrPath === window.location.pathname){
            return;
        }
        logBehavior('goto', {to: urlOrPath});

        if (urlOrPath === undefined || urlOrPath === null) {
            window.history.back();
        }else if (urlOrPath.startsWith("/")){
            
            const path = urlOrPath.split('#')[0];
            
            const hash = urlOrPath.split('#')[1];

            if (path.startsWith("/") || path === ""){
                setPath(path);
                setHash(hash);

                window.history.pushState({}, document.title, window.location.origin + path + (hash?`#${hash}`:``));
            }
        }else {
            window.open(urlOrPath);
        }
    });
    React.useEffect(()=>{
        fn.goto = goto;
    },[goto]);

    const [
        [warningShown, setWarningShown],
        [warningTitle, setWarningTitle],
        [warningMessage, setWarningMessage],
        [warningShownCallback, setWarningShownCallback],
        [warningOKText, setWarningOkText],
        [warningCancelText, setWarningCancelText],
    ] = [
        React.useState(false),
        React.useState(""),
        React.useState(""),
        React.useState(()=>(isOkay: boolean) => {}),
        React.useState(""),
        React.useState(""),
    ];
    

    /**
     * 
     * @param {string} warningTitle 
     * @param {string} warningMessage 
     * @param {(boolean) => void} warningShownCallback 
     * @param {string?} warningOKText 
     * @param {string?} warningCancelText 
     */
    const showWarning = React.useMemo(()=>(warningTitle: string, warningMessage: string | React.ReactDOM, warningShownCallback: (param: any)=> void, warningOKText: string, warningCancelText: string) => {
        setWarningTitle(warningTitle);
        setWarningMessage(warningMessage as string);
        setWarningShownCallback(warningShownCallback);
        setWarningOkText(warningOKText);
        setWarningCancelText(warningCancelText);
    }, [setWarningCancelText, setWarningMessage, setWarningOkText, setWarningShownCallback, setWarningTitle]);

    /** @type {routingDataType} */
    const newProps = {
        isClient: props.isClient,
        goto, path,
        showWarning
    };

    return (
    <>
        <div className={"Router"}>
            <div style={{width:'100%'}}>
                {jsxBranch(path, hash, newProps)()}
            </div>
        </div>
        <Dialog
            open={warningShown}
        >
            <DialogTitle>{warningTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{warningMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                    setWarningShown(false)
                    warningShownCallback(false)
                }} color="inherit">
                    {warningCancelText || "Close"}
                </Button>
                <Button onClick={()=>{
                    setWarningShown(true)
                    warningShownCallback(true)
                }} color="primary">
                {warningOKText || "OK"}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
}

export default VirtualRouter