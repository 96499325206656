import React from 'react';
import {Select, MenuItem} from '@mui/material';
import { CellData } from './DataType';

const EditableSelectField = (props: CellData & {type: 'select', update: (newValue:any) => void}) => {
    
    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue);
    },[props.defaultValue]);

    return (props.isEditing
    ?(<Select
        style={props.style||{}}
        size={"small"}
        fullWidth={props.fullWidth||false}
        value={value || ''}
        onChange={(event)=> {
            setValue(event.target.value);
            let newData = {} as any; newData[props.field] = event.target.value;
            props.update(newData);
        }}
    >
        {props.options.filter(row => row.value !== "header" && (!row.expose || row.expose === 1)).map(row => (
            <MenuItem key={row.key as string} value={row.value}>{row.key}</MenuItem>
        ))}
    </Select>)
    :<div style={props.style}>{((arr)=> (
        arr.length>0
            ?arr[0].key
            :"")
        )(props.options.filter(row => row.value === props.defaultValue))}</div>);
}

export default EditableSelectField;