import axios from 'axios';
import ss from './Session';

export type MembershipType = {
   teamid: string,
   userid?: string,
   name: string,
   email: string,
   teamemail: string,
   closed: boolean,
   authority: 'owner' | 'write' | 'read',
};

export async function readMemberships () {
    try{
        let res = await axios.post('https://admin-api.webtoon.today/membership', {}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data as (MembershipType)[];

        }else{
            return [];
        }
    }catch(e){
        return [];
    }
}

export async function createTeam (name: string, teamemail: string) {
    try{
        let res = await axios.put('https://admin-api.webtoon.today/membership', {name, teamemail}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function renameTeam ({ teamid, name, teamemail, closed }: { teamid: string; name: string; teamemail: string; closed: boolean }) {
    try{
        let res = await axios.patch('https://admin-api.webtoon.today/membership', {teamid, name, teamemail, closed}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}


export async function createMembership (membership: MembershipType) {
    try{
        let res = await axios.put('https://admin-api.webtoon.today/membership', membership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function updateMembership (membership: MembershipType) {
    try{
        let res = await axios.patch('https://admin-api.webtoon.today/membership', membership, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function deleteMembership (membership: MembershipType) {
    try{
        let res = await axios.delete(`https://admin-api.webtoon.today/membership?teamid=${membership.teamid}&userid=${membership.userid}`, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.key;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}