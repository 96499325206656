import axios, { AxiosError } from 'axios';
import ss from './Session';

export type profileType = {
    userid: string,
    addressid: string,
    name: string | null,
    email: string | null,
    image: string | null,
 };

 export type partialProfileType = {
    userid?: string,
    addressid?: string,
    name?: string | null,
    email?: string | null,
    image?: string | null,
 };

export const updateProfile = async (newProfileData: partialProfileType,) => {
    let res = await axios.patch('https://admin-api.webtoon.today/profile', newProfileData, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};


export const readProfile = async (targetid: string) => {
    
    try{
        
        let res = await readProfileList([targetid]);
        
        if (res.length > 0) {
            let profile = res[0];
            if (!profile.image){
                profile.image = "https://static.webtoon.today/noprofile.jpg";
            }
            return profile;
        } else {
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
    }catch(e){
        if ((e as AxiosError).response && (e as AxiosError).response?.status === 404) {
            console.log(e);
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
        console.error(e);
        return {
            userid: null,
            email: null,
            image: null,
            addressid: null,
            memberState: null,
            name: null,
            gernder: null,
        };
    }
};

 export const readProfileByEmail = async (email: string) => {
    
    try{
        
        let res = await readProfileList([email]);
        
        if (res.length > 0) {
            let profile = res[0];
            if (!profile.image){
                profile.image = "https://static.webtoon.today/noprofile.jpg";
            }
            return profile;
        } else {
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
    }catch(e){
        if ((e as AxiosError).response && (e as AxiosError).response?.status === 404) {
            console.log(e);
            return {
                userid: null,
                email: null,
                image: null,
                addressid: null,
                memberState: null,
                name: null,
                gernder: null,
            };
        };
        console.error(e);
        return {
            userid: null,
            email: null,
            image: null,
            addressid: null,
            memberState: null,
            name: null,
            gernder: null,
        };
    }
};


export const readProfileList = async (targetids: string[]) => {
    let res = await axios.post(`https://admin-api.webtoon.today/profile`, {targetid: targetids.join(',')}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.data && res.data.code === 200) {

        return res.data.data;

    } else {
        return []
    };
};

export const removeProfile = async (userid: string, ) => {

    let res = await axios.delete(`https://challenge-api.webtoon.today/profile?userid=${userid}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.code === 200) {

        return true;

    } else {
        return false;
    };
};