import axios from 'axios';
import ss from './Session';

export type SettlementInfo = {

    teamid: string,
    
    author: string,
    revenue_share: number,
    needWithholding: boolean,
    email: string,
};

export type TeamType = {
    teamid: string, 
    name: string, 
    teamemail: string, 
};

export const deleteSettlementInfo = async (settlement: SettlementInfo,) => {
    let res = await axios.delete(`https://admin-api.webtoon.today/settlement-info?teamid=${encodeURIComponent(settlement.teamid)}&author=${encodeURIComponent(settlement.author)}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};
export const createOrUpdateSettlementInfo = async (settlement: SettlementInfo,) => {
    if (typeof settlement.revenue_share !== 'number' 
        || settlement.revenue_share < 0 || settlement.revenue_share > 1) {
        return new Error('revenue share is invalid');
    }

    let res = await axios.put('https://admin-api.webtoon.today/settlement-info', {
        ...settlement,

    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

export const readSettlementInfo = async () => {
    let res = await axios.get(`https://admin-api.webtoon.today/settlement-info`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.data && res.data.code === 200) {

        return res.data.data as (SettlementInfo & TeamType)[];

    } else {
        return []
    };
};
