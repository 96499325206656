import React from 'react';

import { Button, } from '@mui/material';
import {GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
import { logBehavior } from 'Data/Behavior';

import ProfileComponent from 'Component/Profile';
import LoadingCircle from 'Component/LoadingCircleAnimated';

import './Setting.scss';
import { fn, } from '../Functions';
import { MoreVert, Warning } from '@mui/icons-material';
import ToastAutoClose from 'Component/ToastAutoClose';
import { useSession, useSignIn } from 'Recoil/Session/Session';
import { profileType } from 'Data/Profile';

const GOOGLE_CID=window.location.host.endsWith("webtoon.today")
    ?"1093253062710-dh2542i4vf7soiqjji199vcr0i02d7vh.apps.googleusercontent.com"
    :"824057779622-lf4u5l68msk11noirpnobfmlrljamicc.apps.googleusercontent.com";


const Setting = () => {

    let queryString = window.location.search.replace('?','');
    let queries: {[key:string]: string} = Object.fromEntries(queryString.split('&').map(pair => pair.split("=").map(uri => decodeURIComponent(uri))))
    
    const [isLoading, setIsLoading] = React.useState(false);

    const {session} = useSession();
    const {GoogleSignin, logout} = useSignIn();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [errorMessage, setErrorMessage] = React.useState("");

    
    if (session && session.memberState === 'regular' && 'redirectTo' in queries) {
        fn.goto(queries.redirectTo);
    }

    if (!session || session.memberState !== 'regular'){
        return (<div className={"SettingStyle"}>
            <div className={"GradientBackground"}>
            </div>
            <div className={"SettingContainer"}>
                <div className={"Section"}>
                    <div className={"SectionHeader"}>{"로그인"}</div>
                    <div className={"SectionBody"}>
                        <ToastAutoClose severity="error" message={errorMessage}/>
                        <div style={{paddingTop:100}}></div>
                        <img className={"BigWhiteLogo"} src='/logo-with-money-512.png' alt={"webtoon today logo"}/>
                        <div style={{paddingTop:100}}></div>
                        <GoogleLogin
                            clientId={GOOGLE_CID}
                            redirectUri="/"
                            buttonText="SIGN IN WITH GOOGLE"
                            theme={"light"}
                            onSuccess={async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
                                setIsLoading(true);
                                let res = await GoogleSignin(response);
                                if (res) {
                                    logBehavior('login(success)',)
                                }
                                setIsLoading(false);
                            }}
                            onFailure={async (response: Error) => {
                                setIsLoading(true);
                                await GoogleSignin(response);
                                logBehavior('login(fail)',)
                                setIsLoading(false);
                            }}
                            cookiePolicy={'single_host_origin'}
                        />
                    </div>
                </div>
                <div className={"KakaoNotice"}>
                    <Warning/>{"카카오톡 브라우저는 "}
                    <MoreVert className={"WithBG"}/>{" 버튼을 눌러 "}
                    <code>{"다른브라우저로 보기"}</code>{"를 이용해주세요."}
                </div>
            </div>
            <LoadingCircle show={isLoading}/>
        </div>);
    }else{
        return (<div className={"SettingStyle"}>
            <div className={"SettingContainer"}>
                <div className={"Section"}>
                    <div className={"SectionHeader"}>{"프로필"}</div>
                    <div className={"SectionBody"}>
                        <ProfileComponent
                            givenProfile={{
                                ...(session as profileType)
                            }}
                        />
                        <Button
                            variant={"outlined"} className={"LogoutButton"} color={"secondary"} fullWidth
                            onClick={ () => {
                                logout();
                            }} >
                            {"로그아웃"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>);
    }

};

export default Setting;