import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { logBehavior } from "../../Data/Behavior";
import ss from '../../Data/Session';
import { useCallback } from "react";

const localStorageEffect = (key: string) => ({setSelf, onSet}: {setSelf: Function, onSet: Function}) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue) {
        setSelf(JSON.parse(savedValue));
    }

    onSet((newSession: sessionType) => {
        localStorage.setItem(key, JSON.stringify(newSession));
    });
};

const logBehaviorEffect = (what: any, detail: any) => ({onSet}: {onSet: Function}) => {
    onSet((newSession: sessionType) => {
        if (newSession.userid){
            logBehavior(what, detail);
        }
    })
}

const sessionState = atom<sessionType>({
    key: 'sessionState',
    default: {
        userid: null,
        addressid: null,
        token: null,
        authority: {},
        email: null,
        jwt: '',
        memberState: null,
        image: null,
        gender: null,
        birthday: null,
        name: null
    },
    effects: [
        localStorageEffect('session'),
        logBehaviorEffect("login(success)", {})
    ]
});

export const useSession = () : {
    session: sessionType,
    sessionRefresh: Function
} => {
    const [session, setSession] = useRecoilState(sessionState);

    const sessionRefresh = useCallback(async () => {
        const newSession = await ss.sessionRefresh();
        setSession(newSession);
        if(newSession.userid){
            return true;
        } else{
            return false;
        }
    }, [setSession]);

    return {
        session,
        sessionRefresh
    }
}

export const useSignIn = () => {
    const setSessionState = useSetRecoilState(sessionState);

    const GoogleSignin = useCallback(async (response: any) => {
        const newSession = await ss.GoogleSignin(response);
        setSessionState(newSession);
        if(newSession.userid){
            return newSession;
        } else{
            return false;
        }
    }, [setSessionState]);

    const logout = useCallback(async () => {
        const newSession = await ss.logout();
        setSessionState(newSession);
    }, [setSessionState]);

    return {
        GoogleSignin,
        logout
    };
}