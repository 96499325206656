import React from 'react';
import EditableTable from 'Component/Table/EditableTable';
import { createExpense, Expense as ExpenseType, readExpense, updateExpense, expenseTypeMap } from 'Data/Expense';
import { Button, Chip, Input, MenuItem, Paper, Select, TextField } from '@mui/material';
import moment from 'moment-timezone';

import '../Expense.scss';
import EditableFileField from 'Component/Table/EditableFileField';
import EditableDateField from 'Component/Table/EditableDateField';
import { fn } from 'Functions';
import { Cancel, } from '@mui/icons-material';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { useSession } from 'Recoil/Session/Session';

type T = any;
const unique = (value:T, index:number, array:T[]) => index === array.indexOf(value)
const NumberSafe = (num: any) => typeof num === 'number'?num:(Number.isNaN(Number(num))?0:Number(num));


const Expense = () => {
    const {session} = useSession();
    if (session.userid === null || session.memberState !== 'regular'){
        fn.goto('/setting?redirectTo=/expense');
    }

    const [data, setData] = React.useState([] as ExpenseType[]);
    const [needRefresh, setNeedRefresh] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const [month, setMonth] = React.useState(new Date().getTime()/1000 - 10 * 24 * 60 * 60);

    React.useEffect(()=>{
        let isFinished = false;
        setIsLoading(true);
        (async()=>{
            let startDayOfThisMonth = moment(month * 1000).startOf('month').unix();
            let endDayOfThisMonth = moment(month * 1000).endOf('month').unix();
            let expenses = await readExpense(session.userid, startDayOfThisMonth, endDayOfThisMonth);

            if (!isFinished){
                setData(expenses);
                setIsLoading(false);
            }
        })();
        return ()=>{
            isFinished = true;
        }
    },[needRefresh, month, session.userid]);

    const [newExpense, setNewExpense] = React.useState({
        state: 'edit',
        type: '식대-점심 식사',
        cardid: '법인',
        related: [session.name],

        occured_at: (new Date()).getTime()/1000,
        requested_at: new Date(moment().format('YYYY-MM-01 00:00:00+09:00')).getTime()/1000,
        
        amount: 0,
        request_amount: 0,
        
        receipt: "",
        comment: "",
    } as (ExpenseType | (ExpenseType & {expenseid: number})) )

    return (<div className={"Expense"}>
        <h1><img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>{"비용 작성"}</h1>
        <Paper elevation={2} className={"NewExpense"}>
            <h2>{('expenseid' in newExpense)?"수정":"새로 작성"}</h2>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"비목"}</label>
                <Select
                    className='InputCell' fullWidth size={"small"}
                    value={newExpense.type.split('-')[0]}
                    onChange={(event)=> setNewExpense(newExpense => ({...newExpense, type: [
                        event.target.value, (expenseTypeMap as any)[event.target.value][0]
                    ].filter(term => term).join('-') }))}
                >
                    <MenuItem value={"식대"}>{"식대"}</MenuItem>
                    <MenuItem value={"교통비"}>{"교통비"}</MenuItem>
                    <MenuItem value={"소모품"}>{"소모품"}</MenuItem>
                    <MenuItem value={"업무기기"}>{"업무기기"}</MenuItem>
                    <MenuItem value={"교육"}>{"교육"}</MenuItem>
                    <MenuItem value={"업무관계자 접대"}>{"업무관계자 접대"}</MenuItem>
                    <MenuItem value={"업무관련"}>{"업무관련"}</MenuItem>
                    <MenuItem value={"회의비"}>{"회의비"}</MenuItem>
                    <MenuItem value={"기타"}>{"기타"}</MenuItem>
                </Select>
                <Select
                    className='InputCell' fullWidth size={"small"}
                    value={newExpense.type.split('-')[1] || ''} onChange={(event)=> setNewExpense(newExpense => ({...newExpense, type: [newExpense.type.split('-')[0], event.target.value].filter(term => term).join('-') }))}
                >
                    {(expenseTypeMap as any)[newExpense.type.split('-')[0]].map((value:string) => 
                        <MenuItem value={value}>{value}</MenuItem>
                    )}
                </Select>
            </div>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"카드"}</label>
                <Select
                    className='InputCell' fullWidth size={"small"}
                    value={newExpense.cardid} onChange={(event)=> setNewExpense({...newExpense, cardid: event.target.value})}
                >
                    <MenuItem value={"개인"}>{"개인"}</MenuItem>
                    <MenuItem value={"법인"}>{"법인"}</MenuItem>
                </Select>
            </div>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <TextField
                    className='Child' fullWidth label={"결제액"} inputProps={{style:{padding: 10}}} type={"number"}
                    value={newExpense.amount} onChange={(event)=> setNewExpense(newExpense => ({...newExpense, amount: NumberSafe(event.target.value), request_amount: NumberSafe(event.target.value)}))}
                />
                <TextField
                    className='Child' fullWidth label={"청구액"} inputProps={{style:{padding: 10}}} type={"number"}
                    value={newExpense.request_amount} onChange={(event)=> {
                        if (newExpense.amount === 0){
                            fn.toastAlert("결제액을 먼저 입력해주세요.")
                            return;
                        }
                        if (newExpense.amount < NumberSafe(event.target.value)){
                            fn.toastAlert("청구액은 결제액보다 클 수 없습니다.")
                            return;
                        }
                        setNewExpense(newExpense => ({...newExpense, request_amount: NumberSafe(event.target.value)}))}
                    } 
                />
            </div>
            <div style={{display:'flex', flexDirection:'row', paddingBottom: 10,}}>
                <label style={{paddingRight: 10, lineHeight:'30px', whiteSpace:'nowrap'}}>{"사용일"}</label>
                <Input
                    fullWidth type={"date"}
                    value={moment((newExpense.occured_at || new Date().getTime()/1000)*1000).format('YYYY-MM-DD')}
                    onChange={(event) => {
                        setNewExpense(newExpense => ({
                            ...newExpense,
                            occured_at: (new Date(event.target.value+' 00:00:00+09:00')).getTime()/1000
                        }))
                    }} 
                />
            </div>
            <div style={{display:'flex', flexDirection:'row', paddingBottom: 10,}}>
                <label style={{paddingRight: 10, lineHeight:'30px', whiteSpace:'nowrap'}}>{"청구월"}</label>
                <Input
                    fullWidth type={"month"}
                    value={moment((newExpense.requested_at || new Date().getTime()/1000)*1000).format('YYYY-MM')}
                    onChange={(event) => {
                        setNewExpense(newExpense => ({
                            ...newExpense,
                            requested_at: (new Date(event.target.value+' 00:00:00+09:00')).getTime()/1000
                        }))
                    }} 
                />
            </div>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <label style={{paddingRight: 10, lineHeight:'40px'}}>{"참석자"}</label>
                <Select
                    className='InputCell' fullWidth size={"small"}
                    value={""} onChange={(event)=> setNewExpense(newExpense => ({...newExpense, related: newExpense.related.concat(event.target.value).filter(unique)}))}
                >
                    <MenuItem value={"진수글"}>{"진수글"}</MenuItem>
                    <MenuItem value={"김동영"}>{"김동영"}</MenuItem>
                    <MenuItem value={"서영호"}>{"서영호"}</MenuItem>
                    <MenuItem value={"안수현"}>{"안수현"}</MenuItem>
                    <MenuItem value={"권기현"}>{"권기현"}</MenuItem>
                    <MenuItem value={"윤수선"}>{"윤수선"}</MenuItem>
                    <MenuItem value={"오경훈"}>{"오경훈"}</MenuItem>
                    <MenuItem value={"반세현"}>{"반세현"}</MenuItem>
                    <MenuItem value={"기타"}>{"기타"}</MenuItem>
                </Select>
            </div>
            <div className='InputCell' style={{display:'flex', flexDirection:'row', flexWrap: 'wrap', whiteSpace:'nowrap'}}>
                {newExpense.related.map(name => <Chip key={name} label={name} deleteIcon={<Cancel/>} onDelete={()=>{setNewExpense(newExpense => ({...newExpense, related: newExpense.related.filter(token => token !== name)}))}} style={{marginRight: 5}}/>)}
            </div>
            <TextField
                className='InputCell' fullWidth label={"코멘트"} inputProps={{style:{padding: 10}}}
                value={newExpense.comment || ""} onChange={(event)=> setNewExpense(newExpense => ({...newExpense, comment: event.target.value}))}
            />
            <div className='InputCell' style={{display:'flex', flexDirection:'row', whiteSpace:'nowrap'}}>
                <label style={{paddingRight: 10, lineHeight:'30px'}}>{"영수증"}</label>
                <EditableFileField
                    type='file'
                    accept='jpg,png,pdf'
                    isEditing={true}
                    defaultValue={newExpense.receipt || ""}
                    field={"receipt"}
                    update={(value) =>{
                        setNewExpense(newExpense => ({...newExpense, ...value}))
                    }}
                />
            </div>
            <Button fullWidth variant={"outlined"} disabled={isLoading} onClick={()=>{
                if (newExpense.amount === 0){
                    fn.toastAlert("비용이 입력되지 않았습니다.");
                    return;
                }

                setIsLoading(true);
                (async()=>{
                    try{
                        if ('expenseid' in newExpense){
                            await updateExpense({...newExpense, state: 'edit'});
                        }else{
                            await createExpense(newExpense);
                        }
                    }catch(e){
                        fn.toastAlert((e as any).response.data.message, 2000);
                        setIsLoading(false);
                    }

                    setNeedRefresh(needRefresh+1);

                    setNewExpense(newExpense => ({
                        state: 'edit',
                        type: '식대-점심 식사',
                        cardid: '법인',
                        related: session.name?[session.name]:[],

                        occured_at: newExpense.occured_at || new Date().getTime() / 1000,
                        requested_at: newExpense.requested_at || new Date(moment().format('YYYY-MM-01 00:00:00+09:00')).getTime()/1000,
                    
                        amount: 0,
                        request_amount: 0,
                        
                        receipt: null,
                        comment: null,
                    }));
                    setIsLoading(false);
                })();
            }}>{'expenseid' in newExpense?"저장":"새로 등록"}</Button>
        </Paper>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{marginRight: 10}}>{"조회기간"}</h3>
            <EditableDateField
                type={'date'}
                isEditing={true}
                defaultValue={month}
                monthOnly={true}
                field={"month"}
                update={(newValue)=>{
                    setMonth(newValue.month)
                }}
            />
            <Button>{"확인"}</Button>
        </div>
        {data.filter(row => row.state === 'rejected').length > 0
        ?<>
            <h3>{"반려됨"}</h3>
            <EditableTable
                headerButtonHeaders={[
                    ''
                ]}
                headerButtonsFunction={(row: ExpenseType & {expenseid: number}, index)=> [
                    {name: '수정', color: 'primary', onClick: ()=>{
                        setNewExpense(row)
                    }, props: {}}
                ]}
                columnHeaders={['번호', '비목', '카드', '결제액', '청구액', '영수증', '사용일', '청구월', '코멘트', '참석자']}
                columnsFunction={(row: ExpenseType & {expenseid: number}, index)=> [
                    {isEditing: false, type: 'default', defaultValue: row.expenseid, field: 'expenseid', },
                    {isEditing: false, type: 'default', defaultValue: row.type, field: 'type', style:{wordBreak: 'keep-all'} },
                    {isEditing: false, type: 'default', defaultValue: row.cardid, field: 'cardid', style:{wordBreak: 'keep-all'} },
                    {isEditing: false, type: 'default', defaultValue: row.amount, field: 'amount', },
                    {isEditing: false, type: 'default', defaultValue: row.request_amount, field: 'request_amount', },
                    {isEditing: false, type: 'file', defaultValue: row.receipt || "", field: 'receipt', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} },
                    {isEditing: false, type: 'date', defaultValue: row.occured_at || 0, field:'occured_at' },
                    {isEditing: false, type: 'date', monthOnly: true, defaultValue: row.requested_at || 0, field:'requested_at' },
                    {isEditing: false, type: 'default', defaultValue: row.comment, field: 'comment', },
                    {isEditing: false, type: 'default', defaultValue: row.related.join(', '), field: 'related', style:{wordBreak: 'keep-all'} },
                ]}
                data={data.filter(row => row.state === 'rejected')}
                updateGenerator={(row, index)=> async (newValue: any) => {}}
            />
        </>:<></>}

        <h3>{"입력내용"}</h3>
        <EditableTable
            headerButtonHeaders={[
                ''
            ]}
            headerButtonsFunction={(row: ExpenseType & {expenseid: number}, index)=> [
                {name: '수정', color: 'primary', onClick: ()=>{
                    setNewExpense(row)
                }, props: {}}
            ]}
            columnHeaders={['번호', '비목', '카드', '결제액', '청구액', '영수증', '사용일', '청구월', '코멘트', '참석자']}
            columnsFunction={(row: ExpenseType & {expenseid: number}, index)=> [
                {isEditing: false, type: 'default', defaultValue: row.expenseid, field: 'expenseid', },
                {isEditing: false, type: 'default', defaultValue: row.type, field: 'type', style:{wordBreak: 'keep-all'} },
                {isEditing: false, type: 'default', defaultValue: row.cardid, field: 'cardid', style:{wordBreak: 'keep-all'} },
                {isEditing: false, type: 'default', defaultValue: row.amount, field: 'amount', },
                {isEditing: false, type: 'default', defaultValue: row.request_amount, field: 'request_amount', },
                {isEditing: false, type: 'file', defaultValue: row.receipt || "", field: 'receipt', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} },
                {isEditing: false, type: 'date', defaultValue: row.occured_at || 0, field:'occured_at' },
                {isEditing: false, type: 'date', monthOnly: true, defaultValue: row.requested_at || 0, field:'requested_at' },
                {isEditing: false, type: 'default', defaultValue: row.comment, field: 'comment', },
                {isEditing: false, type: 'default', defaultValue: row.related.join(', '), field: 'related', style:{wordBreak: 'keep-all'} },
            ]}
            data={data.filter(row => row.state !== 'rejected')}
            updateGenerator={(row, index)=> async (newValue: any) => {}}
        />
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Expense;