import moment from 'moment-timezone';
import {MouseEventHandler} from 'react';

export const pixelize = (rem: number) => (
    rem * parseFloat(window.getComputedStyle(document.documentElement).fontSize || '16')
);


type BranchLogic = {
    validator: (object: any) => boolean,
    sublogic?: BranchLogic[],
    defaultValue: any,
};

export const BranchFunction = (key: any, logic: BranchLogic[], defaultValue: any): any => {

    if (logic instanceof Array){
        for(const {validator, sublogic, defaultValue} of logic){
            if (validator(key)){
                if (sublogic && sublogic instanceof Array && sublogic.length > 0){
                    return BranchFunction(key, sublogic, defaultValue);
                }else{
                    return defaultValue;
                }
            }else {
            }
        }
    }
    return defaultValue;

}

export const randomString = (length: number) =>
  new Array(length).fill(0)
    .map((x) => String.fromCharCode(65 + Math.floor(Math.random() * 2) * 32 + Math.floor(Math.random() * 26)))
    .join("");

export const fn: {
    goto: (arg0: string) => void,
    gotoByAnchor: MouseEventHandler,
    alert: (arg0: string, arg1?: number) => void,
    toastAlert: (arg0: string, arg2?: number) => void,
} = {
    goto: (destination?: string)=>{},
    gotoByAnchor: (event)=> {
        event.preventDefault();
        
        let target = event.target as HTMLElement
        
        while (target.tagName !== 'body'){
            if (target.getAttribute('href')){
                break;
            }
            if (!target.parentElement){
                console.error("no parent");
                return;
            }
            target = target.parentElement
        }
        if (target.tagName === 'body'){
            return;
        }else{
            fn.goto(target.getAttribute('href') || "");
        }
    },
    alert: window.alert,
    toastAlert: window.alert,
};

export const thumbnailize = (url: string,max_width=0,max_height=0) => (
    !url
        ?url
        :`https://cached-api.webtoon.today/thumb?u=${encodeURIComponent(url)}&agent${max_width>0?`&mw=${max_width*2}`:''}${max_height>0?`&mh=${max_height*2}`:''}`
);

/**
 * 
 * @param {Date} date 
 */
export const dateFormat = (date: Date) =>{
    let todayZeroAM = new Date();
    todayZeroAM.setHours(0,0,0,0);

    if (todayZeroAM.getTime() - date.getTime() < 2 * 24 * 60 * 60 * 1000){
        if (todayZeroAM.getTime() - date.getTime() < 0 ){
            return `오늘`;
        }else if (todayZeroAM.getTime() - date.getTime() < 1 * 24 * 60 * 60 * 1000){
            return `어제`;
        } else {
            return `이틀 전`;
        }
    }
    
    let thisMonthFirst = new Date();
    thisMonthFirst.setHours(0,0,0,0);
    thisMonthFirst.setDate(1);

    if (todayZeroAM.getTime() - date.getTime() < (365 - 31) * 24 * 60 * 60 * 1000){
        return `${('00'+(date.getMonth()+1)).substr(-2)}/${('00'+date.getDate()).substr(-2)}`
    }

    return `${date.getFullYear()}`;

}

export const decodeEntities = (str: string) => {
        // this prevents any overhead from creating the object each time
        let element = document.createElement('div');
    
        const decodeHTMLEntities = (str: string) => {
            if(str && typeof str === 'string') {
                // strip script/html tags
                str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
                str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
                element.innerHTML = str;
                str = element.textContent || "";
                element.textContent = '';
            }
        
            return str;
        }
    
        return decodeHTMLEntities(str);
};

type T = any;
export const unique = (obj: T, index: number, arr: T[]) => {
    if (index === arr.indexOf(obj)){
        return true;
    }else {
        return false;
    }
}

export const waitImageLoaded = (imageUrl: string) => {
    let smallImage = document.createElement('img');
    smallImage.setAttribute('style', 'width:1px; opacity:0; position:absolute;');
    const imagePromise = new Promise((resolve,reject) => {
        smallImage.onload = () => {
            resolve({width: smallImage.naturalWidth || 16, height: smallImage.naturalHeight || 9})
            document.body.removeChild(smallImage)
        }
        smallImage.onerror = (error) => {
            reject(error)
            document.body.removeChild(smallImage)
        }
    })
    document.body.appendChild(smallImage);
    smallImage.setAttribute('src', imageUrl);

    return imagePromise;
}

export const parseUrl = (url: string) => {
    const [fullUrl, scheme, domain, path, query, hash] = (/(https|http):\/\/([^/]+)(\/?[^?]+)?\??([^#]+)?(#?.+)?$/.exec(url) || [])
    return {
        fullUrl, scheme, domain, path, query: Object.fromEntries(query.split('&').map(pair => pair.split('=').map(term => decodeURIComponent(term)))), hash
    }
}

/**
 * @description {@link https://gist.github.com/flyskyne/b6b310187ec26581f7ee548656473e72}의 오픈소스를 참고하였습니다.
 */
export const koreanFirstSort = (a: string, b: string) => {

    const addOrderPrefix = (text: string) => {
            
        const code = text.toLowerCase().charCodeAt(0);
        let prefix = "";

        if (0xac00 <= code && code <= 0xd7af) {prefix = '1'} 
        else if (0x3130 <= code && code <= 0x318f) {prefix = '2'} 
        else if (0x61 <= code && code <= 0x7a) {prefix = '3'} 
        else {prefix = '9'}

        return prefix + text;
    }
    
    a = addOrderPrefix(a);
    b = addOrderPrefix(b);

    if (a < b) {return -1}
    if (a > b) {return 1}
    return 0;
}

/**
 * 
 * @description {@link https://taegon.kim/archives/9919}의 오픈소스를 참고하였습니다.
 */
const syllablePattern = (syllable: string) => {
    const offset = 44032;

    if(/[가-힣]/.test(syllable)){
        const syllableCode = syllable.charCodeAt(0) - offset;

        if ( syllableCode % 28 > 0 ) {
            return syllable;
        }

        const begin = Math.floor(syllableCode / 28) * 28 + offset;
        const end = begin + 27;
        return `[\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }

    if (/[ㄱ-ㅎ]/.test(syllable)) {

        const vowelToSyllable: {[key: string]: number} = {
            ㄱ: "가".charCodeAt(0),
            ㄲ: "까".charCodeAt(0),
            ㄴ: "나".charCodeAt(0),
            ㄷ: "다".charCodeAt(0),
            ㄸ: "따".charCodeAt(0),
            ㄹ: "라".charCodeAt(0),
            ㅁ: "마".charCodeAt(0),
            ㅂ: "바".charCodeAt(0),
            ㅃ: "빠".charCodeAt(0),
            ㅅ: "사".charCodeAt(0)
        };

        const begin =
            vowelToSyllable[syllable] ||
            (syllable.charCodeAt(0) - 12613) * 588 + vowelToSyllable["ㅅ"];
        const end = begin + 587;

        return `[${syllable}\\u${begin.toString(16)}-\\u${end.toString(16)}]`;
    }

    return syllable;
}

/**
 * 
 * @param {string} input 
 * @returns {RegExp}
 * @description {@link https://taegon.kim/archives/9919}의 오픈소스를 참고하였습니다.
 */
export const createFuzzyMatcher = (input: string) => {
    const pattern = input.split('').map(character => { return syllablePattern(character) }).join('.*?');
    
    return new RegExp(pattern);
}


export const validateEmailForm = (infomation: string) => {
        
    var regExp = /^([-_.+0-9a-zA-Z])*@([-_.0-9a-zA-Z])*\.[a-zA-Z]{2,10}$/i;

    if (regExp.test(infomation)) {

        return true;

    } else {

        return false;
    }
}

export const timeLapsConversion = (commentTime: number) => {
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const timeLag = currentTime - commentTime;

    if ( 24 * 60 * 60 <= timeLag) {
        return moment(commentTime * 1000).format('YY.MM.DD');
    };

    if ( timeLag < 60 ) {
        const timeLagSeconds = Math.floor(timeLag);
        return `${timeLagSeconds}초전`
    };

    if ( 60 <= timeLag && timeLag < 60 * 60) {
        const timeLagMinutes = Math.floor(timeLag / 60 );
        return `${timeLagMinutes}분전`;
    };

    if ( 60 * 60 <= timeLag && timeLag < 24 * 60 * 60 ){
        const timeLagHours = Math.floor(timeLag / (60 * 60) )
        return `${timeLagHours}시간전`
    };
};

export const fileReadHandler = async (file: File, encoding: 'dataurl'|'utf8' = 'utf8'): Promise<string|ArrayBuffer> => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        
        reader.onload = (event) => {
            if (!reader.result){
                return reject(reader.result);
            }
            return resolve(reader.result);
        };

        reader.onerror = (event) => {
            if (reader.error){
                return reject(reader.error);
            }
        };

        if (encoding === 'utf8'){
            reader.readAsText(file);
        }else if (encoding === 'dataurl'){
            reader.readAsDataURL(file);
        }else {
            throw new Error('encoding is invalid');
        }
    })
}

const specialCharacters = new Set(['\r']);
const CSVTokenizer = (csv: string) => {
    let res = [];

    let escaped = false;
    let currentRow = [];
    let currentToken = "";
    for (let i=0; i<csv.length; i++) {
        let char = csv[i];

        if (char === ',' && !escaped){
            try{
                currentRow.push(JSON.parse(`"${currentToken}"`));
            }catch(e){
                currentRow.push(currentToken);
            }
            currentToken = '';
            continue;
        }

        if (char === '\n' && !escaped){
            currentRow.push(JSON.parse(`"${currentToken}"`));
            res.push(currentRow);
            currentToken = '';
            currentRow = [];
            continue;
        }

        if (char === '"'
            && (i < 1 || csv[i-1] !== '\\' )
            && (i < 2 || csv[i-1] !== '\\' || csv[i-2] !== '\\' )
        ){
            escaped = !escaped;
            continue;
        }

        if (specialCharacters.has(char)){
            continue;
        }

        currentToken += char;
    }

    if (currentToken){
        currentRow.push(JSON.parse(`"${currentToken}"`));
    }

    if (currentRow.length > 0){
        res.push(currentRow);
    }

    return res;
}

export const readCSVAsNamedRecordArray = (csv: string, hasHeader: boolean = true) => {
    let data = CSVTokenizer(csv);

    let header = Array(data.map(row => row.length).reduce( (a,b) => a>b?a:b, 0 )).fill(null).map( (v,i) => `c_${i+1}`)
    if (hasHeader){
        header = header.map( (v,i) => (i<data[0].length?data[0][i]:v).trim() );
        data = data.slice(1);
    }

    return data.map(row => Object.fromEntries(
        row.map((col,index) => [header[index], col.trim()])
    ))
}

export const checkKorean = (password: string) => {
    const regx = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    return regx.test(password)
}

export const numberToAccountingNumber = (num: number | string): string => {

    if (Number.isNaN(Number(num))){
        return "NaN";
    }

    let [ integer, decimal = '' ] = num.toString().split('.');
    let negative = integer.startsWith('-');
    if (negative) {
        integer = integer.substring(1);
    }

    return `${
        negative?'-':''
    }${
        integer.split('').reverse().map((char, idx) => `${char}${idx % 3 === 0 && idx !== 0 ?',':''}`).reverse().join('')
    }${
        decimal?'.':''
    }${
        decimal
    }`
}

export const dateToYYYYMM = (date: Date) => {
    return `${
        date.getFullYear()
    }-${
        ((month) => month.substring(month.length - 2))('00'+(date.getMonth()+1))
    }`;
};

export const flags = {
    didTitleUpdated: false,
    didEpisodeUpdated: false,
    didPasswordReset: false,
    isClient: false,
};