import axios from 'axios';

import {GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';

const nullSession = {
    userid:null, addressid: null, token:null, authority: {},
    email: null, jwt: '', memberState: null, image: null, gender: null, birthday: null, name: null
};
const nullBucket = {};
const crnt = {
    session: global.localStorage?JSON.parse(localStorage.getItem('session') || '{}'):nullSession,
    bucket: global.localStorage?JSON.parse(localStorage.getItem('bucket') || '{}'):nullBucket,
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const bucketForABTest = (hash: number, numberOfBuckets: number) => {
    let buckets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return buckets[Math.floor(hash * numberOfBuckets)];
}

const sessionRefresh = async (): Promise<sessionType> => {
    const session = JSON.parse(localStorage.getItem('session') || '{}');
    
    try{

        if (!session.userid){
            crnt.session = nullSession;
            return crnt.session;
        }

        /** @type {sessionResponseType} res */
        let res = await axios.post(
            'https://admin-api.webtoon.today/session', 
            (session.userid && session.token)?{userid: session.userid, token: session.token}:{}
        );
        
        if (res.data.code === 200 && res.data.data && res.data.data.userid){
            if (!res.data.data.image){
                res.data.data.image = "https://static.webtoon.today/noimage.jpg";
            }
            crnt.session = res.data.data;
        } else {
            crnt.session = nullSession;
        }
    }catch(e){
        crnt.session = nullSession;
    }

    return crnt.session;
}       

const GoogleSignin = async (response: GoogleLoginResponse | GoogleLoginResponseOffline | Error, ) => {
    if ('gtag' in window && typeof window.gtag === 'function'){
        window.gtag('event', 'conversion', {'send_to': 'AW-604780621/UKKOCJ3C1t4BEM3wsKAC'});
    }
    if ('googleId' in response){
        try{
            /** @type {sessionResponseType} res */
            let res = await axios.put(
                'https://admin-api.webtoon.today/session',
                {gsso: response}
            );
            
            if (res.data.code === 200 && res.data.data && res.data.data.userid){
                if (!res.data.data.image){
                    res.data.data.image = "https://static.webtoon.today/noimage.jpg";
                }
                crnt.session = res.data.data;
                return crnt.session;
            }else{
                crnt.session = nullSession;
            }
        }catch(e){
            console.error(e)
            // do nothing
            return false;
        }
    }
    return false;
}

const logout = async () => {
    crnt.session = nullSession;
    localStorage.setItem('session', JSON.stringify(nullSession));

    return crnt.session;
}

const sessionFuncs = {
    GoogleSignin,
    sessionRefresh,
    logout,
    getCurrentSession: () => {return crnt.session},
    getCurrentBucket: () => {return crnt.bucket},
};

export default sessionFuncs
