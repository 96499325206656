import axios from 'axios';
import ss from './Session';

export const getSizeOf = async (key: string) => {
    let ret = null;

    const req = {key};

    let res = await axios.post(`https://api.webtoon.today/image`, req, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        }
    })
    
    if (res.data && res.data.code === 200){
        return res.data.data;
    }else{
        console.error(res.data.code, res.data);
    }

    return ret;
};

export const updateImage = async (imageComponent: HTMLInputElement,) => {
    let ret = null;

    var formData = new FormData();
    formData.append("image", (imageComponent.files || [])[0]);
    let res = await axios.put(`https://api.webtoon.today/image`, formData, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`,
            'Content-Type': 'multipart/form-data'
        }
    })

    if (res.data && res.data.code === 200){
        return res.data.data.id;
    }else{
        console.error(res.data.code, res.data);
    }

    return ret;
};

export const createImageWithBase64 = async (imageDataUrl: string, onProgress = ()=>{}) => {
    let ret = null;

    let res = await axios.put(`https://api.webtoon.today/image`, {
        file: imageDataUrl
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    if (res.data && res.data.code === 200){
        return 'https://static.webtoon.today/'+res.data.data.filename;
    }else{
        console.error(res.data.code, res.data);
    }

    return ret;
}

export const createImageWithForm = async (imageComponent: HTMLInputElement) => {
    let formData = new FormData();
    let ret = null;

    formData.append('file', (imageComponent.files || [])[0])
    
    let res = await axios.put(`https://api.webtoon.today/image`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    if (res.data && res.data.code === 200){
        return 'https://static.webtoon.today/'+res.data.data.filename;
    }else{
        console.error(res.data.code, res.data);
    }

    return ret;
}

export const createImageWithS3 = async (imageComponent: HTMLInputElement) => {
    let formData = new FormData();
    let ret = null;

    let S3Signed = await axios.put(`https://api.webtoon.today/image`, {
        filename: (imageComponent.files || [])[0].name
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    })

    formData.append('file', (imageComponent.files || [])[0])
    
    let res = await axios.put(S3Signed.data.data.uploadURL, (imageComponent.files || [])[0], {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        withCredentials: true
    })

    if (res.status === 200){
        ret = 'https://static.webtoon.today/'+S3Signed.data.data.Key;
    }

    return ret;
}

export const getThumbnail = async ( images: string[], width: number ) => {
    try{
        let res = await axios.post(`https://challenge-api.webtoon.today/thumbnail-generate`, {imageUrls: images, image_width: width}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.urls;

        }else{
            return null;
        }
    }catch(e){
        return null;
    }
}

export async function getContentsAsZip (images: `https://static.webtoon.today/${string}`[]) {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/zip-staticfiles', {staticFiles: images}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return res;
        }
    }catch(e){
        console.error(e)
        return null;
    }
}

export async function spreadZipOnS3 (zipName: `https://static.webtoon.today/${string}`) {
    try{
        let res = await axios.post('https://challenge-api.webtoon.today/spread-zip', {zipName}, {
            headers: {
                Authorization: `Bearer ${ss.getCurrentSession().jwt}`
            },
            withCredentials: true
        });

        if (res.data && res.data.code === 200){
            
            return res.data.data;

        }else{
            return res;
        }
    }catch(e){
        console.error(e)
        return null;
    }
}

export const fileToBase64: ((file: Blob) => Promise<string|ArrayBuffer|null>) = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const toBase64 = (file: Blob, onProgress: (this: FileReader, ev: ProgressEvent<FileReader>) => any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.onprogress = onProgress
});