import React from 'react';
import EditableTable from 'Component/Table/EditableTable';
import { Settlement as SettlementType, readSettlement, deleteSettlement, updateSettlement } from 'Data/Settlement';

import '../Expense.scss';
import EditableDateField from 'Component/Table/EditableDateField';
import { fn } from 'Functions';
import { profileType, readProfileList } from 'Data/Profile';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import moment from 'moment-timezone';
import { CounterpartType, readCounterparts } from 'Data/Counterpart';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useSession } from 'Recoil/Session/Session';

function unique <T>(val:T, idx:number, arr:T[]){return idx === arr.indexOf(val)};

const Settlement = () => {

    const {session} = useSession();

    if (session.userid === null || session.memberState !== 'regular'){
        fn.goto('/setting?redirectTo=/settlement');
    }

    const [data, setData] = React.useState([] as SettlementType[]);

    const [refreshTimer, setRefreshTimer] = React.useState(null as null | NodeJS.Timeout)
    const [needRefresh, setNeedRefresh] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);

    const [editId, setEditId] = React.useState(-1);
    
    const [profiles, setProfiles] = React.useState([] as profileType[]);
    React.useEffect(()=>{
        (async ()=>{
            let userids = data.map(row => row.userid || "").filter(userid => !!userid);
    
            if (userids.length === 0){
                return;
            }
            try{
                let profiles = await readProfileList(userids);
    
                setProfiles(profiles);
            }catch(e){
                //
            }
        })();
    },[data])

    const [counterparts, setCounterparts] = React.useState([] as CounterpartType[]);
    React.useEffect(()=>{
        (async()=>{
            let counterparts = await readCounterparts();
            setCounterparts(counterparts);
        })();
    },[])

    const [month, setMonth] = React.useState(new Date().getTime()/1000 - 10 * 24 * 60 * 60);

    const [groupByCounterpart, setGroupByCounterpart] = React.useState(false);

    React.useEffect(()=>{
        let isFinished = false;

        if (refreshTimer){
            clearTimeout(refreshTimer);
        }

        let timer = setTimeout(()=>{
            (async()=>{
                setIsLoading(true);
                let startDayOfThisMonth = moment(month * 1000).startOf('month').unix();
                let endDayOfThisMonth = moment(month * 1000).endOf('month').unix();
                let settlements = await readSettlement(startDayOfThisMonth, endDayOfThisMonth);
    
    
                if (!isFinished){
                    setData(settlements);
                    setIsLoading(false);
                }
            })();
        }, 2000);

        setRefreshTimer(timer);

        return ()=>{
            isFinished = true;
            if (refreshTimer){
                clearTimeout(refreshTimer);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[needRefresh, month]);

    let samePersonTypeTax = {} as {[key: string]: SettlementType};
    data.forEach( row => {
        let key = `${row.membername}:${row.type}:${row.tax_rate}`;
        if (!(key in samePersonTypeTax)){
            samePersonTypeTax[key] = row;
        }else {
            let episodeNames = [...(samePersonTypeTax[key].episodename || '').split(','), row.episodename].filter(row => row).filter(unique).join(',');
            let comments = [...(samePersonTypeTax[key].comment || '').split(','), row.comment].filter(row => row).filter(unique).join(',');
            samePersonTypeTax[key] = {
                ...samePersonTypeTax[key],
                unit_amount: 0,
                request_count: 0,
                request_amount: samePersonTypeTax[key].request_amount + row.request_amount,
                episodename: episodeNames,
                comment: comments,
            }
        }
    })
    let samePersonTax = {} as {[key: string]: SettlementType};
    Object.values(samePersonTypeTax).forEach( row => {
        let key = `${row.membername}:${row.tax_rate}`;
        if (!(key in samePersonTax)){
            samePersonTax[key] = row;
        }else {
            let episodeNames = `${samePersonTax[key].episodename}${row.episodename?`,${row.type}(${row.episodename})`:``}`
            let comments = `${samePersonTax[key].comment}${row.comment?`,${row.type}(${row.comment})`:``}`
            samePersonTax[key] = {
                ...samePersonTax[key],
                unit_amount: 0,
                request_count: 0,
                request_amount: samePersonTax[key].request_amount + row.request_amount,
                episodename: episodeNames,
                comment: comments,
            }
        }
    })

    let renderableData = groupByCounterpart?Object.values(samePersonTax):data;

    return (<div className={"Expense"} style={{maxWidth: 1600}}>
        <h1>
            <img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>
            {"작품 정산 작성"}
        </h1>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{marginRight: 10}}>{"조회기간"}</h3>
            <EditableDateField
                type={'date'}
                isEditing={true}
                defaultValue={month}
                monthOnly={true}
                field={"month"}
                update={(newValue)=>{
                    setMonth(newValue.month)
                }}
            />
            <FormControlLabel control={<Checkbox checked={groupByCounterpart} onChange={(event,checked) => setGroupByCounterpart(checked)}/>} label={"담당자별로 묶어 보기"}/>
        </div>
        <EditableTable
            style={{fontSize: '0.8rem', whiteSpace: 'nowrap'}}
            headerButtonHeaders={groupByCounterpart?[]:[
                '', ''
            ]}
            headerButtonsFunction={(row: SettlementType & {settlementid: number}, index)=> groupByCounterpart?[]:[
                {name: '삭제', color: 'secondary', onClick: ()=>{
                    (async()=>{
                        await deleteSettlement(row)
                        setEditId(-1)
                        setNeedRefresh(needRefresh+1)
                    })();
                }, props: {}},
                {name: editId === row.settlementid?'완료':'수정', color: 'primary', onClick: ()=>{
                    if (editId === row.settlementid){
                        (async()=>{
                            await updateSettlement({...row})
                            setNeedRefresh(needRefresh+1);
                        })();
                        setEditId(-1);
                        return;
                    }else if (editId !== -1){
                        (async()=>{
                            await updateSettlement(data[editId])
                        })();
                    }
                    setEditId(row.settlementid)
                    console.log(data, row.settlementid)
                }, props: {}}
            ]}
            columnHeaders={['번호', '작성자', '작품명', '청구월', '사용일', '담당자명', '주민번호', '은행', '계좌번호', '비목', '청구액', '원천징수', '실지급액', '에피소드명', '영수증', '코멘트']}
            columnsFunction={(row: SettlementType & {settlementid: number}, index)=> [
                {isEditing: false, type: 'default', defaultValue: row.settlementid, field: 'settlementid', },
                {isEditing: row.settlementid === editId, type: 'select', defaultValue: row.userid || "", field: 'userid', style:{wordBreak: 'keep-all'}, options: profiles.map( (row:any) => ({key: row.name || "", value: row.userid || ""})) },
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: row.artname, field: 'artname', style:{wordBreak: 'keep-all'} },
                {isEditing: editId === row.settlementid, type: 'date', monthOnly: true, defaultValue: row.requested_at || 0, field:'requested_at' },
                {isEditing: editId === row.settlementid, type: 'date', defaultValue: row.occured_at || 0, field:'occured_at' },
                {isEditing: editId === row.settlementid, type: 'select', defaultValue: row.membername, field: 'membername', style:{wordBreak: 'keep-all'}, options: counterparts.map(counterpart => ({key: `${counterpart.name}(${counterpart.counterpartid})`, value: String(counterpart.counterpartid)})) },
                {isEditing: false, type: 'select', defaultValue: row.membername, field: 'member_id', style:{wordBreak: 'keep-all'}, options: counterparts.map(counterpart => ({key: counterpart.id_num, value: String(counterpart.counterpartid)})) },
                {isEditing: false, type: 'select', defaultValue: row.membername, field: 'member_bank_name', style:{wordBreak: 'keep-all'}, options: counterparts.map(counterpart => ({key: counterpart.bank_name, value: String(counterpart.counterpartid)})) },
                {isEditing: false, type: 'select', defaultValue: row.membername, field: 'member_acc_num', style:{wordBreak: 'keep-all'}, options: counterparts.map(counterpart => ({key: counterpart.account_num, value: String(counterpart.counterpartid)})) },
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: row.type, field: 'type', style:{wordBreak: 'keep-all'} },
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: row.request_amount, field: 'request_amount', },
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: Math.floor(row.request_amount * row.tax_rate / 100.0), field: 'tax_amount', },
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: row.request_amount - Math.floor(row.request_amount * row.tax_rate / 100.0), field: 'tax_rate', },
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: row.episodename, field: 'episodename', style:{wordBreak: 'keep-all'} },
                {isEditing: editId === row.settlementid, type: 'file', defaultValue: row.receipt || "", field: 'receipt', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} }, 
                {isEditing: editId === row.settlementid, type: 'default', defaultValue: row.comment, field: 'comment', },
            ]}
            data={renderableData}
            isDownloadable={true}
            name={`${moment(month * 1000).format('YYMM00')}-비용정산`}
            updateGenerator={(row, index)=> async (newValue: any) => {
                let targetIndex = data.findIndex(record => record.settlementid === row.settlementid)
                setData([
                    ...data.slice(0, targetIndex),
                    {...row, ...newValue},
                    ...data.slice(targetIndex+1),
                ])
            }}
        />
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default Settlement;