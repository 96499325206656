import React, { useEffect, useState } from 'react';

import './index.scss';
import SalesSection from './SalesSection';
import ExpenseSection from './ExpenseSection';
import ShareSection from './ShareSection';
import { Settlement, readSettlement } from 'Data/Settlement';
import { CounterpartType, readCounterparts } from 'Data/Counterpart';
import { SalesType, readSales } from 'Data/Sales';
import { SettlementInfo, readSettlementInfo } from 'Data/SettlementInfo';
import { dateToYYYYMM } from 'Functions';
import EditableDateField from 'Component/Table/EditableDateField';
import { readMemberships } from 'Data/Membership';
import OldSummarySection from './OldSummarySection';
import SummarySection from './SummarySection';
import { FormControlLabel, MenuItem, Select, Switch } from '@mui/material';

const makePayday = (targetMonth: number)=> {
    let ret = new Date(targetMonth * 1000);
    ret.setMonth(ret.getMonth() + 1);

    ret.setDate(10);

    if (ret.getDay() === 0){
        ret.setDate(8);
    }
    if (ret.getDay() === 6){
        ret.setDate(9);
    }

    return ret.getTime() / 1000;
};

const Art = ({teamid}: {teamid: string}) => {

    const [settlements, setSettlements] = useState<(Settlement & {requested_at: number})[]>([]);
    const [counterpart, setCounterpart] = useState<CounterpartType[]>([]);
    const [selectedCounterpart, setSelectedCounterpart] = useState<CounterpartType | null>(null);
    const [sales, setSales] = useState<SalesType[]>([]);
    const [settlementInfo, setSettlementInfo] = useState<SettlementInfo[]>([]);

    const [isOldReport, setIsOldReport] = useState(false);

    const [teamName, setTeamName] = useState('');
    useEffect(()=>{
        (async ()=>{
            let teams = await readMemberships();
            setTeamName(teams.filter(team => team.teamid === teamid)[0]?.name);
        })();
    }, [teamid])

    const [targetMonth, setTargetMonth] = useState(new Date(dateToYYYYMM(new Date((new Date()).getTime() - 11 * 24 * 60 * 60 * 1000))+'-01 00:00:00+09:00').getTime() / 1000);
    const [targetPayday, setTargetPayDay] = useState(0);

    useEffect(()=>{
        setTargetPayDay(makePayday(targetMonth));
    }, [targetMonth])

    useEffect(()=>{
        const rangeEnd = ((targetMonth: number)=>{
            let rangeEnd = new Date(targetMonth * 1000);
            rangeEnd.setMonth(rangeEnd.getMonth()+1);
            return rangeEnd;
        })(targetMonth);

        (async()=>{
            setSettlements( (await readSettlement(0, rangeEnd.getTime()/1000 - 1, teamid))
                .filter(row => typeof row.requested_at === 'number' && row.requested_at < rangeEnd.getTime()/1000)
                .map(row => row as (Settlement & {requested_at: number}))
            );
        })();

        (async()=>{
            setCounterpart((await readCounterparts()));
            setSelectedCounterpart(null);
        })();
        
        (async()=>{
            let sales = await readSales(teamid);
            setSales(sales.filter(row => row.requested_at < rangeEnd.getTime()/1000));
        })();
        
        (async()=>{
            setSettlementInfo((await readSettlementInfo()).filter(row => row.teamid === teamid));
        })();
    },[teamid, targetMonth])

    return (<div className={"ArtSettlement"}>
        <h2>
            {`<${teamName}> 작품 개발 지급 명세서`}
            <Select
                className='SettlementTarget'
                size={"small"}
                value={selectedCounterpart?.counterpartid || ''}
                onChange={(event) => setSelectedCounterpart(counterpart.filter(row => row.counterpartid === event.target.value)[0] || null)}
            >
                <MenuItem value={""}>{""}</MenuItem>
                {counterpart.filter(cp => settlementInfo.find(settle => settle.author === String(cp.name) && cp.teamid.includes(settle.teamid) )).map(cp => <MenuItem value={cp.counterpartid} key={cp.counterpartid}>{cp.name}</MenuItem>)}
            </Select>
            <FormControlLabel
                className={"ToggleButton"}
                control={<Switch size='small' checked={isOldReport} onChange={(_,checked) => setIsOldReport(checked)}/>}
                label={"구형 보고서 보기"}
            />
        </h2>
        <section className={"Header"}>
            <div className={'FromTo Cell'}>
                {`서비스 의뢰자: 주식회사 오늘의웹툰`}<br/>
                {`서비스 제공자: ${selectedCounterpart?.name || settlementInfo.map(provider => provider.author).join(', ')}`}
            </div>
            <div className={'When Cell'}>
                <div>{`귀속월: `}<EditableDateField
                    type={"date"} monthOnly defaultValue={targetMonth} isEditing={true} field={'targetMonth'}
                    update={(newValue) => {
                        setTargetMonth(new Date(dateToYYYYMM(new Date(newValue.targetMonth * 1000))+'-01 00:00:00+09:00').getTime() / 1000)
                    }}
                /></div>
                <div>{`지급예정일: `}<EditableDateField type={"date"} defaultValue={targetPayday} isEditing={true} field={'targetPayday'} update={(newValue) => setTargetPayDay(newValue.targetPayday)} /></div>
            </div>
        </section>
        <br/>
        {isOldReport
            ?<OldSummarySection {...{sales, settlements, settlementInfo, counterpart, targetMonth, selectedCounterpart}} />
            :<SummarySection {...{sales, settlements, settlementInfo, counterpart, targetMonth, selectedCounterpart}} />}
        <SalesSection sales={sales} />
        <ExpenseSection settlements={settlements} counterpart={counterpart} />
        <ShareSection settlements={settlements} counterpart={counterpart} selectedCounterpart={selectedCounterpart} />
        
    </div>);
}

export default Art;