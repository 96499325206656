import React from 'react';
import moment from 'moment-timezone';

import 'Page/Report.scss';
import 'Page/Expense.scss';

import EditableDateField from 'Component/Table/EditableDateField';
import { fn } from 'Functions';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { Checkbox, MenuItem, Select } from '@mui/material';
import { readSettlement, Settlement } from 'Data/Settlement';
import { readMemberships } from 'Data/Membership';
import { CounterpartType, readCounterparts } from 'Data/Counterpart';
import { useSession } from 'Recoil/Session/Session';

type T = any;
const unique = (value:T, index:number, array:T[]) => index === array.indexOf(value);

const CommaSeperate = (num: number) => String(num).split('.').map( (val, idx) => idx === 0?val.split('').reverse().map((char,idx,arr) => (idx % 3 === 2 && idx<arr.length - 1)?[char, ',']:[char]).flat().reverse().join(''):val ).join('.')

const SettlementReport = () => {
    const {session} = useSession();
    
    if (session.userid === null || session.memberState !== 'regular'){
        fn.goto('/setting?redirectTo=/settlement/report');
    }

    const [data, setData] = React.useState([] as (Settlement)[]);
    const [isLoading, setIsLoading] = React.useState(false);

    const [month, setMonth] = React.useState(new Date().getTime()/1000 - 10 * 24 * 60 * 60);
    
    const [art, setArt] = React.useState('');
    const [counterpart, setCounterpart] = React.useState<CounterpartType | null>(null);
    const [arts, setArts] = React.useState([] as string[]);
    const [counterparts, setCounterparts] = React.useState([] as CounterpartType[]);

    const [isCounterPartIdOpen, setIsCounterPartIdOpen] = React.useState(false);

    React.useEffect(()=>{
        (async()=>{
            let memberships = await readMemberships();

            setArts(memberships.map(row => row.name).filter(unique));
        })();
    },[])
    React.useEffect(()=>{
        (async()=>{
            let counterparts = await readCounterparts();
            setCounterparts(counterparts);
        })();
    },[])

    React.useEffect(()=>{
        let isFinished = false;
        setIsLoading(true);
        (async()=>{
            let startDayOfThisMonth = moment(month * 1000).startOf('month').unix();
            let endDayOfThisMonth = moment(month * 1000).endOf('month').unix();
            let settlements = await readSettlement(startDayOfThisMonth, endDayOfThisMonth);

            if (!isFinished){
                setData(settlements);
                setIsLoading(false);
            }
        })();
        return ()=>{
            isFinished = true;
        }
    },[month]);

    const filteredData = data.filter(({artname, membername}) => artname === art && membername === String(counterpart?.counterpartid))
    const receiptData = filteredData.filter(row => row.receipt)

    const totalRequest = filteredData.map(row => row.request_amount).reduce( (a,b) => a+b,0 )
    const totalHolding = filteredData.map(row => Math.floor(row.request_amount * row.tax_rate / 100.0)).reduce( (a,b) => a+b,0 )

    return (<div className={"Expense"}>
        <h1>
            <img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>
            {"정산 보고서"}
        </h1>
        
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
            <h3 style={{marginRight: 10}}>{"조회기간"}</h3>
            <EditableDateField
                type={'date'}
                isEditing={true}
                defaultValue={month}
                monthOnly={true}
                field={"month"}
                update={(newValue)=>{
                    setMonth(newValue.month)
                }}
            />
        </div>
        <div style={{width: '100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', wordBreak: 'keep-all'}}>
            <h3 style={{marginRight: 10}}>{"작품"}</h3>
            <Select size={'small'} fullWidth
                value={art} onChange={(event) => {
                    setArt(event.target.value);
                    setCounterpart(null);
                }}
            >
                {arts.sort().filter(art => data.find(row => row.artname === art)).map(art =>
                    <MenuItem key={art} value={art}>{art}</MenuItem>
                )}
            </Select>
            <h3 style={{margin: 10}}>{"담당자"}</h3>
            <Select size={'small'} fullWidth
                value={counterpart?.counterpartid} onChange={(event) => setCounterpart(counterparts.filter(row => row.counterpartid === event.target.value)[0] || null)}
            >
                {counterparts
                .filter(cp => data.find(settle => settle.membername === String(cp.counterpartid) && settle.artname === art))
                .map(counterpart =>
                    <MenuItem key={counterpart.counterpartid} value={counterpart.counterpartid}>{counterpart.name}</MenuItem>
                )}
            </Select>
        </div>
        <div style={{width: "100%", textAlign:'right', marginBottom: 20, fontSize: '0.8rem'}}>
            {`이하 보고서는 조회기간에 대해 승인된 비용이며 정산 시스템에서 대조가 가능합니다.`}<br/>{`발행일시 ${moment().format('YYYY-MM-DD HH:mm:ss')}`}
        </div>

        <div className='Section'>
            <div style={{textAlign:'right', width: '100%', marginBottom: 20}}>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 건수 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(filteredData.map(row => 1).reduce( (a,b) => a+b,0 ))}</div>
                </div>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 청구액 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(totalRequest)}</div>
                </div>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 원천징수액 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(totalHolding)}</div>
                </div>
                <div>
                    <span style={{marginLeft:10, fontWeight: 'bold', fontSize:'1.3rem', marginRight: 10}}>{"총 지급(예정)액 "}</span>
                    <div style={{display: 'inline-block', minWidth: 100}}>{CommaSeperate(totalRequest - totalHolding)}</div>
                </div>
        
            </div>
            <h2>{"요약"}</h2>
            <h3>{"청구"}</h3>
            <div className={"Table"}>
                <div className={"TableRow"}>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'비목'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'단가'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'작업건수'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'청구액'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'세금'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className='Label'>{'청구월'}</label>
                    </td>
                </div>
                {Object.entries(filteredData
                    .map(({type, request_amount, unit_amount, request_count, tax_rate}) => ({[`${type}`]:{ request_amount, unit_amount, request_count, tax_amount: Math.floor(request_amount * tax_rate / 100.0)}}))
                    .reduce( (a,b) => {
                        for(let type of Object.keys(b)){
                            a[type] = {
                                unit_amount:    Math.max((a[type] || {}).unit_amount || 0, b[type].unit_amount || 0),
                                request_amount: ((a[type] || {}).request_amount || 0) + b[type].request_amount,
                                request_count:  ((a[type] || {}).request_count  || 0) + b[type].request_count,
                                tax_amount: ((a[type] || {}).tax_amount || 0) + b[type].tax_amount
                            };
                        }

                        return a;
                    }, {} as {[type: string]: {request_amount: number, unit_amount: number, request_count: number, tax_amount: number}}))
                .map( ([type, row]) =>
                    <div className='TableRow'>
                        <td className={"TableCell DataCell"}>
                            {type}
                        </td>
                        <td className={"TableCell DataCell"}>
                            {CommaSeperate(row.unit_amount)}
                        </td>
                        <td className={"TableCell DataCell"}>
                            <span style={{display: 'inline-block'}}> {CommaSeperate(row.request_count)}</span>
                        </td>
                        <td className={"TableCell DataCell"}>
                            <span style={{display: 'inline-block'}}> {CommaSeperate(row.request_amount)}</span>
                        </td>
                        <td className={"TableCell DataCell"}>
                            <span style={{display: 'inline-block'}}> {CommaSeperate(row.tax_amount)}</span>
                        </td>
                        <td className={"TableCell DataCell"}>
                            {moment((filteredData[0].requested_at || 0)*1000).format('YYYY-MM')}
                        </td>
                    </div>
                )}
            </div>
            <h3>{"대상자 정보"}<Checkbox title='신분증' value={isCounterPartIdOpen} onChange={() => setIsCounterPartIdOpen(!isCounterPartIdOpen)}/></h3>
            <table className='Table'>
                <tr className={"TableRow"}>
                    <td className={"TableCell HeaderCell QuarterCell"}>
                        <label className={"Label"}>{'성명'}</label>
                    </td>
                    <td className={"TableCell HeaderCell QuarterCell"}>
                        <label className={"Label"}>{'주민등록번호'}</label>
                    </td>
                    <td className={"TableCell HeaderCell QuarterCell"}>
                        <label className={"Label"}>{'은행'}</label>
                    </td>
                    <td className={"TableCell HeaderCell QuarterCell"}>
                        <label className={"Label"}>{'계좌'}</label>
                    </td>
                </tr>
                <tr className={"TableRow"}>
                    <td className={"TableCell DataCell QuarterCell"}>
                        <span style={{display: 'inline-block'}}> {counterpart?.name}</span>
                    </td>
                    <td className={"TableCell DataCell QuarterCell"}>
                        <span style={{display: 'inline-block'}}> {counterpart?.id_num}</span>
                    </td>
                    <td className={"TableCell DataCell QuarterCell"}>
                        <span style={{display: 'inline-block'}}> {counterpart?.bank_name}</span>
                    </td>
                    <td className={"TableCell DataCell QuarterCell"}>
                        <span style={{display: 'inline-block'}}> {counterpart?.account_num}</span>
                    </td>
                </tr>
                {isCounterPartIdOpen && counterpart && counterpart.id_img
                ?<tr className={"TableRow"}>
                    <td className={"TableCell DataCell QuarterCell"} colSpan={2}>
                        <img className={"ReceiptCell"} style={{maxWidth: '100%'}} src={counterpart?.id_img} alt={'id'} />
                    </td>
                    <td className={"TableCell DataCell QuarterCell"} colSpan={2}>
                        <img className={"ReceiptCell"} style={{maxWidth: '100%'}} src={counterpart?.account_img} alt={'account'} />
                    </td>
                </tr>:<></>}
            </table>
        </div>
        <div className='Section'>
            <h2>{"청구/공제 내역"}</h2>
            <table className='Table'>
                <tr className={"TableRow"}>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'연번'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'비목'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'단가'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'건수'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'청구액'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'세율'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'공제액'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className={"Label"}>{'지급액'}</label>
                    </td>
                    <td className={"TableCell HeaderCell"}>
                        <label className='Label'>{'작업일'} / {'상세'}</label>
                    </td>
                </tr>
                {filteredData
                .map(row =>
                    <>
                        <tr key={row.settlementid} className={"TableRow"}>
                            <td className={"TableCell DataCell"}>
                                {row.settlementid}
                            </td>
                            <td className={"TableCell DataCell"}>
                                {row.type}{"/"}{row.episodename}
                            </td>
                            <td className={"TableCell DataCell"}>
                                <span style={{display: 'inline-block'}}> {CommaSeperate(row.unit_amount)}</span>
                            </td>
                            <td className={"TableCell DataCell"}>
                                <span style={{display: 'inline-block'}}> {CommaSeperate(row.request_count)}</span>
                            </td>
                            <td className={"TableCell DataCell"}>
                                <span style={{display: 'inline-block'}}> {CommaSeperate(row.request_amount)}</span>
                            </td>
                            <td className={"TableCell DataCell"}>
                                {row.tax_rate}{"%"}
                            </td>
                            <td className={"TableCell DataCell"}>
                                <span style={{display: 'inline-block'}}> {CommaSeperate(Math.floor(row.request_amount * row.tax_rate / 100.0))}</span>
                            </td>
                            <td className={"TableCell DataCell"}>
                                <span style={{display: 'inline-block'}}> {CommaSeperate(Math.floor(row.request_amount * (1-row.tax_rate / 100.0)))}</span>
                            </td>
                            <td className={"TableCell DataCell"}>
                                <span style={{display: 'inline-block'}}> {moment((row.occured_at || 0)*1000).format('YYYY-MM-DD')}: {row.comment?.replace(new RegExp('https://webtoon.today/home/contents/'), '/')}</span>
                            </td>
                        
                        </tr>
                    </>
                )
            }
            </table>
        </div>
        {receiptData.length > 0
        ?<div className='Section'>
            <h2>{"증빙"}</h2>
            <div className={"Receipts"}>
                <table>
                    <tbody>
                        {Array(Math.ceil(receiptData.length / 2)).fill(0).map( (v,i) =>
                                <tr key={receiptData[i*2]?.settlementid}>
                                    <td>
                                        <div>
                                            <div className={"Detail"}>
                                                <label className={"Label"}>{"연번"}</label>{receiptData[i*2]?.settlementid}<label className={"Label"}>{"비목"}</label>{receiptData[i*2]?.type}
                                                <label className={"Label"}>{"결제일"}</label>{moment((receiptData[i*2]?.occured_at || 0) * 1000).format('YYYY-MM-DD')} <br/>
                                                <label className={"Label"}>{"상세"}</label>{receiptData[i*2]?.comment} <br/>
                                                <label className={"Label"}>{"청구액"}</label>{CommaSeperate(receiptData[i*2].request_amount)}
                                            </div>
                                            <img className={"ReceiptCell"} src={receiptData[i*2].receipt?`https://cached-api.webtoon.today/thumb?mw=700&u=${receiptData[i*2].receipt}`:""} alt={'receipt'}/>
                                        </div>
                                    </td>
                                    {receiptData[i*2+1] && <td>
                                        <div>
                                            <div className={"Detail"}>
                                                <label className={"Label"}>{"연번"}</label>{receiptData[i*2+1].settlementid}<label className={"Label"}>{"비목"}</label>{receiptData[i*2+1].type} 
                                                <label className={"Label"}>{"결제일"}</label>{moment((receiptData[i*2].occured_at || 0) * 1000).format('YYYY-MM-DD')} <br/>
                                                <label className={"Label"}>{"상세"}</label>{receiptData[i*2+1].comment} <br/>
                                                <label className={"Label"}>{"청구액"}</label>{CommaSeperate(receiptData[i*2+1].request_amount)}
                                            </div>
                                            <img className={"ReceiptCell"} src={`https://cached-api.webtoon.today/thumb?mw=700&u=${receiptData[i*2+1].receipt}`} alt={'receipt'}/>
                                        </div>
                                    </td>}
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>:<></>}
        <LoadingCircle show={isLoading}/>
    </div>);
}

export default SettlementReport;