
import axios from 'axios'
import ss from './Session';

export const triggerEmail = async ({email, subject, body, cc, bcc, attachments=[]}:{email: string, subject: string, body: string, cc?: string[], bcc?: string[], attachments?: {name: string, body: string}[]}) => {
    //groups 
    const groups = 'dl_financial_report@webtoontoday.com';
    // const groups = 'id-no-reply@webtoontoday.com';

    let res = await axios.post('https://challenge-api.webtoon.today/trigger-email', {email, cc, bcc, subject, body, attachments, groups}, {
    headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    }else {
        return [];
    }
}
