import { CounterpartType } from "Data/Counterpart";
import { SalesType } from "Data/Sales";
import { Settlement } from "Data/Settlement";
import { SettlementInfo } from "Data/SettlementInfo";
import { numberToAccountingNumber, unique } from "Functions";
import React from "react";

const SummarySection = ({
    sales, settlements, settlementInfo: givenSettlementInfo,
    counterpart, targetMonth, selectedCounterpart
}: {
    sales: SalesType[], settlements: (Settlement & {requested_at: number})[], settlementInfo: SettlementInfo[],
    counterpart: CounterpartType[], targetMonth: number, selectedCounterpart: CounterpartType | null
}) => {

    const settlementInfo = givenSettlementInfo.filter(info => !selectedCounterpart || info.author === selectedCounterpart.name);
    
    const totalSales = sales.map(row => row.net).filter(net => net > 0).reduce((a,b)=> a+b, 0);
    const thisMonthSales = sales.filter(row => row.requested_at >= targetMonth).map(row => row.net).reduce((a,b)=> a+b, 0);
    
    const totalExpense = settlements.filter(row => !['MG', 'RS'].includes(row.type)).map(row => row.request_amount).reduce((a,b) => a + b, 0)
    const thisMonthExpense = settlements.filter(row => !['MG', 'RS'].includes(row.type) && row.requested_at >= targetMonth).map(row => row.request_amount).reduce((a,b) => a + b, 0)
    
    const netSales = totalSales - totalExpense;
    const thisMonthNetSales = thisMonthSales - thisMonthExpense;
    
    const companyShare = 1 - (settlementInfo.map(row => row.revenue_share).reduce( (a,b)=> a+b, 0));

    const authorSettlements = Object.fromEntries(settlementInfo
        .map(info => ([info.author, counterpart.filter(row => row.name === info.author)] as [string, CounterpartType[]]))
        .filter(([author, counterparts]) => !selectedCounterpart || counterparts.find(counterpart => selectedCounterpart.name === String(counterpart.name)))
        .map(([author, counterparts]) => [
            author,
            settlements
                .filter(row => counterparts.find(counterpart => row.membername === String(counterpart.counterpartid))
                    && ['MG', 'RS'].includes(row.type)
                )
                .map(row => row.request_amount).reduce((a,b)=>a+b,0)
        ])
    )

    const authorThisMonthMGSettlements = Object.fromEntries(settlementInfo
        .map(info => ([info.author, counterpart.filter(row => row.name === info.author)] as [string, CounterpartType[]]))
        .filter(([author, counterparts]) => !selectedCounterpart || counterparts.find(counterpart => selectedCounterpart.name === String(counterpart.name)))
        .map(([author, counterparts]) => [
            author,
            settlements
                .filter(row => counterparts.find(counterpart => row.membername === String(counterpart.counterpartid))
                    && ['MG'].includes(row.type)
                    && row.requested_at >= targetMonth
                )
                .map(row => row.request_amount).reduce((a,b)=>a+b,0)
        ])
    )
    const authorThisMonthSettlements = Object.fromEntries(settlementInfo
        .map(info => ([info.author, counterpart.filter(row => row.name === info.author)] as [string, CounterpartType[]]))
        .filter(([author, counterparts]) => !selectedCounterpart || counterparts.find(counterpart => selectedCounterpart.name === String(counterpart.name)))
        .map(([author, counterparts]) => [
            author,
            settlements
                .filter(row => counterparts.find(counterpart => row.membername === String(counterpart.counterpartid))
                    && ['MG', 'RS'].includes(row.type)
                    && row.requested_at >= targetMonth
                )
                .map(row => row.request_amount).reduce((a,b)=>a+b,0)
        ])
    )    
    const authorThisMonthTax = Object.fromEntries(Object.entries(authorThisMonthSettlements)
        .map(([author, settlement]) => [ author, Math.floor(settlement * 0.03 / 10) * 10 + Math.floor(settlement * 0.003 / 10) * 10 ])
    )    
    const authorThisMonthInsurence = Object.fromEntries(Object.entries(authorThisMonthSettlements)
        .map(([author, settlement]) => [ author, Math.floor(settlement * 0.75 * 0.008) ])
    )
    const thisMonthSupportTypes = settlements.filter(row => !['마케팅', 'MG', 'RS'].includes(row.type) && row.requested_at >= targetMonth).map(row => row.type).filter(unique);
    
    return <section className={"Summary"}>
        <table className='SummaryTable'>
            <thead>
                <tr>
                    <th colSpan={6}>{"내용"}</th>
                    <th colSpan={1}>{"계"}</th>
                </tr>
            </thead>
            <tbody>
                {<>{/* 총매출 = totalSales */}
                    <tr className="FirstLine">
                        <td colSpan={1} className='Colored Header' rowSpan={2}>{"총매출<A>"}</td>
                        <td colSpan={1} className='Header'>{"전월 누계"}</td>
                        <td colSpan={4}>{""}</td>
                        <td colSpan={1} className='Colored Data'>{numberToAccountingNumber(totalSales - thisMonthSales)}</td>
                    </tr>
                    <tr>
                        
                        <td colSpan={1} className='Header'>{"금월 매출"}</td>
                        <td colSpan={4}>{}</td>
                        <td colSpan={1} className='Data'>{numberToAccountingNumber(thisMonthSales)}</td>
                    </tr>
                </>}
                {<>{/* 비용 =  totalExpense */}
                    <tr className="FirstLine">
                        <td colSpan={1} className='Colored Header' rowSpan={3 + thisMonthSupportTypes.length}>{"총비용<B>"}</td>
                        <td colSpan={1} rowSpan={1} className='Header'>{"누적 작화 지원"}</td>
                        <td colSpan={3} >{"어시스트"}</td>
                        <td colSpan={1} className='Data'></td>
                        <td colSpan={1} className='Colored Data'>
                        {numberToAccountingNumber(settlements.filter(row => !['마케팅', 'MG', 'RS'].includes(row.type) && row.requested_at < targetMonth).map(row => row.request_amount).reduce((a,b) => a+b, 0))}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='Header'>{"누적 마케팅 지원"}</td>
                        <td colSpan={3} >{"마케팅"}</td>
                        <td colSpan={1} className='Data'></td>
                        <td colSpan={1} className='Colored Data'>
                            {numberToAccountingNumber(settlements.filter(row => row.type === '마케팅' && row.requested_at < targetMonth).map(row => row.request_amount).reduce((a,b) => a+b, 0))}
                        </td>
                    </tr>
                    {thisMonthSupportTypes.map( (type, idx) =>
                        <tr>
                            {idx === 0 && <td colSpan={1} rowSpan={thisMonthSupportTypes.length} className='Header'>{"금월 작화 지원"}</td>}
                            <td colSpan={3} >{`어시-${type}`}</td>
                            <td colSpan={1} className=' Data'></td>
                            <td colSpan={1} className=' Data'>
                                {numberToAccountingNumber(settlements.filter(row => row.type === type && row.requested_at >= targetMonth).map(row => row.request_amount).reduce((a,b) => a+b, 0))}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td colSpan={1} className='Header'>{"금월 마케팅 지원"}</td>
                        <td colSpan={3} >{"마케팅"}</td>
                        <td colSpan={1} className=' Data'></td>
                        <td colSpan={1} className=' Data'>
                            {numberToAccountingNumber(settlements.filter(row => row.type === '마케팅' && row.requested_at >= targetMonth).map(row => row.request_amount).reduce((a,b) => a+b, 0))}
                        </td>
                    </tr>
                </>}
                {<>{/* 순매출 = netSales = totalSales - totalExpense */}
                <tr className="FirstLine">
                    <td colSpan={1} className='Colored Header' rowSpan={2}>{"순매출<A-B>"}</td>
                    <td colSpan={1} className=''>{"누적 순매출"}</td>
                    <td colSpan={4} className=''></td>
                    <td colSpan={1} className='Colored Data'>{numberToAccountingNumber(netSales)}</td>
                </tr>
                <tr>
                    <td colSpan={1} className=''>{"금월 순매출"}</td>
                    <td colSpan={4} className=''></td>
                    <td colSpan={1} className=' Data'>{numberToAccountingNumber(thisMonthNetSales)}</td>
                </tr>
                </>}
                {<>{/* 정산 = netSales * revenue_share */}
                    <tr className="FirstLine">
                        <td colSpan={1} className='Colored Header' rowSpan={3}>{"정산액<C>"}</td>
                        <td colSpan={5 - settlementInfo.length}>{""}</td>
                        {settlementInfo.map(info => <td key={info.author} colSpan={1} className='Colored Header'>{`${info.author}(${info.revenue_share*100}%)`}</td>)}
                        <td colSpan={1} className='Colored Header'>{"회사"}</td>
                    </tr>
                    <tr>
                        <td colSpan={5 - settlementInfo.length}>{""}</td>
                        {settlementInfo.map(info => <td key={info.author} colSpan={1} className='Colored Data'>{`${numberToAccountingNumber(Math.ceil(info.revenue_share * netSales))}`}</td>)}
                        <td colSpan={1} className={'Colored Data'}>{numberToAccountingNumber(Math.floor(companyShare * netSales))}</td>
                    </tr>
                    <tr>
                        <td colSpan={5 - settlementInfo.length}>{""}</td>
                        {settlementInfo.map(info => <td key={info.author} colSpan={1} className='Data'>{`${numberToAccountingNumber(Math.ceil(info.revenue_share * thisMonthNetSales))}`}</td>)}
                        <td colSpan={1} className={'Data'}>{numberToAccountingNumber(Math.floor(companyShare * thisMonthNetSales))}</td>
                    </tr>
                </>}
                {<>{/* 지급 = authorSettlement */}
                    <tr className="FirstLine">
                        <td colSpan={1} rowSpan={2} className='Colored Header'>{"실지급<D>"}</td>
                        <td colSpan={1} className='Header'>{"누적 지급"}</td>
                        <td colSpan={4 - settlementInfo.length} >{""}</td>
                        {settlementInfo.map(info =>
                            <td key={info.author} colSpan={1} className='Colored Data'>
                                {`${numberToAccountingNumber(Math.ceil(
                                    authorSettlements[info.author] - authorThisMonthSettlements[info.author]
                                ))}`}
                            </td>
                        )}
                        <td colSpan={1} className='Colored Data'>
                            {numberToAccountingNumber(Math.floor(
                                netSales
                                - thisMonthNetSales
                                - (Object.values(authorSettlements).reduce((a,b) => a+b, 0)
                                    - Object.values(authorThisMonthSettlements).reduce((a,b) => a+b, 0))
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={1} className='Header'>{"금월 지급"}</td>
                        <td colSpan={4 - settlementInfo.length} >{""}</td>
                        {settlementInfo.map(info =>
                            <td key={info.author} colSpan={1} className='Data'>
                                {`${numberToAccountingNumber(Math.ceil(
                                    authorThisMonthSettlements[info.author]
                                ))}`}
                            </td>
                        )}
                        <td colSpan={1} className=' Data'>
                            {numberToAccountingNumber(Math.floor(thisMonthSales - Object.values(authorThisMonthSettlements).reduce((a,b) => a+b, 0)))}
                        </td>
                    </tr>
                </>}
                {<>{/* 잔액 */}
                    <tr className="FirstLine BottomLine">
                        <td colSpan={1} rowSpan={2} className='Colored Header'>{"잔액<C-D>"}</td>
                        <td colSpan={5 - settlementInfo.length} >{""}</td>
                        {settlementInfo.map(info =>
                            <td key={info.author} colSpan={1} className='Data'>
                                {`${numberToAccountingNumber(Math.ceil(
                                    info.revenue_share*netSales - authorSettlements[info.author]
                                ))}`}
                            </td>
                        )}
                        <td colSpan={1} className=' Data'>{0}</td>
                    </tr>
                </>}
            </tbody>
            <thead>
                <tr>
                    <th colSpan={6}>{"지급내역"}</th>
                    <th colSpan={1}>{"계"}</th>
                </tr>
            </thead>
            <tbody>
               <tr className="FirstLine">
                    <td colSpan={1} className='Colored Header' rowSpan={2}>{"지급<D'>"}</td>
                    <td colSpan={1} className='Header'>{"MG"}</td>
                    <td colSpan={4 - settlementInfo.length}>{""}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Data'>{numberToAccountingNumber(authorThisMonthMGSettlements[info.author])}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
               <tr >
                    <td colSpan={1} className='Header'>{"RS"}</td>
                    <td colSpan={4 - settlementInfo.length}>{""}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Data'>{numberToAccountingNumber(authorThisMonthSettlements[info.author] - authorThisMonthMGSettlements[info.author])}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
                <tr className="FirstLine">
                    <td colSpan={6 - settlementInfo.length} className='Colored Header'>{"지급 계"}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Data'>{numberToAccountingNumber(authorThisMonthSettlements[info.author])}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
               <tr className="FirstLine">
                    <td colSpan={1} className='Colored Header' rowSpan={2}>{"공제<D''>"}</td>
                    <td colSpan={1} className='Header'>{"소득세"}</td>
                    <td colSpan={4 - settlementInfo.length}>{"3.3%"}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Minus Data'>{numberToAccountingNumber(info.needWithholding?authorThisMonthTax[info.author]:0)}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
               <tr >
                    <td colSpan={1} className='Header'>{"예술인 고용보험"}</td>
                    <td colSpan={4 - settlementInfo.length}>{""}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Minus Data'>{numberToAccountingNumber(info.needWithholding?authorThisMonthInsurence[info.author]:0)}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
                <tr className="FirstLine">
                    <td colSpan={6 - settlementInfo.length} className='Colored Header'>{"공제 계"}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Minus Data'>{numberToAccountingNumber(info.needWithholding? (authorThisMonthTax[info.author] + authorThisMonthInsurence[info.author]) :0 )}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
                <tr className="FirstLine BottomLine">
                    <td colSpan={6 - settlementInfo.length} className='Header'>{"계"}</td>
                    {settlementInfo.map(info =>
                        <td key={info.author} colSpan={1} className='Colored Data'>{numberToAccountingNumber( Math.ceil(( authorThisMonthSettlements[info.author] - (info.needWithholding? (authorThisMonthTax[info.author] + authorThisMonthInsurence[info.author]) :0) ) / 10 ) * 10 )}</td>
                    )}
                    <td colSpan={1}>{""}</td>
                </tr>
            </tbody>
        </table>
        <div className={"Byline"}>
            {"작가님의 노고에 감사드립니다."}<br/>
            {"주식회사 오늘의웹툰 드림"}
        </div>
    </section>
}

export default SummarySection;