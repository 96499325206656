import React from 'react';
import './App.scss';

import VirtualRouter from './VirtualRouter';

import { Dialog, DialogContent, DialogActions, Button, SwipeableDrawer, List, ListItemIcon, ListItemText, ListItemButton, IconButton, Divider, } from '@mui/material';
import { Article, ArticleOutlined, AttachMoney, Email, Money, OpenInNew, SupervisedUserCircle, } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { flags, fn } from './Functions';
import Toast from './Component/Toast';

import ss from './Data/Session';
import { MembershipType, readMemberships } from './Data/Membership';
import { useSession } from 'Recoil/Session/Session';
import {Toast as ToastLib} from '@webtoontoday/toast';

function App(props: {isClient?: boolean, injectedLocation?: string}) {
    
    /** @type {[sessionType, (newSession:sessionType)=>void]} */
    const {session, sessionRefresh} = useSession();

    const [alertText, setAlertText] = React.useState(null as (null | string));
    const [toastMessage, setToastMessage] =React.useState(undefined as (undefined | string));
    const [toastTimeout, setToastTimeout] =React.useState(0);
    const alert = React.useMemo(()=>(message: string | null)=>setAlertText(message),[setAlertText]);
    React.useEffect(()=>{
        fn.alert = alert;
        fn.toastAlert = (message, timeout = 1000) => {setToastMessage(message); setToastTimeout(timeout+Math.random())};
    },[alert])

    const [isSideBarOpen, setIsSideBarOpen] = React.useState(ss.getCurrentSession().memberState === 'regular');

    React.useEffect(()=>{
        sessionRefresh();
        
        flags.isClient = props.isClient || false;
        
    },[props.isClient, sessionRefresh]);

    
    const [teamData, setTeamData] = React.useState([] as MembershipType[])
    React.useEffect(()=>{
        (async()=>{
            const res = await readMemberships()
            setTeamData(res.filter(membership => membership.userid === ss.getCurrentSession().userid && membership.authority !== 'read'));
        })();
    },[])
    

    const theme = createTheme({
        palette: {
            primary: {
                main: 'rgb(0,176,240)',
            }
        },
    });

    const routingProps = {
        isClient: props.isClient || false, injectedLocation: props.injectedLocation,
        session,
    };
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <VirtualRouter {...routingProps} />
                <Toast timeout={toastTimeout}>
                    {toastMessage}
                </Toast>
                <ToastLib/>
            </div>
            <IconButton
                className={"HoverButton"}
                style={{position: 'fixed', top: 10, left: 10, zIndex:3, boxShadow:'5px 5px 5px lightgray'}}
                onClick={()=>setIsSideBarOpen(!isSideBarOpen)}
            >
                <OpenInNew/>
            </IconButton>
            <SwipeableDrawer
                anchor={"left"}
                style={{paddingRight: 60}}
                open={isSideBarOpen}
                onOpen={(event)=>setIsSideBarOpen(true)}
                onClose={(event) =>setIsSideBarOpen(false)}
            >
                <List>
                    <ListItemButton onClick={()=>fn.goto('/expense')}>
                        <ListItemIcon><Money/></ListItemIcon>
                        <ListItemText>{"비용작업"}</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={()=>fn.goto('/expense/report')}>
                        <ListItemIcon><ArticleOutlined/></ListItemIcon>
                        <ListItemText>{"비용보고서"}</ListItemText>
                    </ListItemButton>
                    {ss.getCurrentSession().authority?.finance
                    ?
                    <ListItemButton onClick={()=>fn.goto('/expense/manager')}>
                        <ListItemIcon><Money/></ListItemIcon>
                        <ListItemText>{"비용집계"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                    <Divider/>
                    <ListItemButton onClick={()=>fn.goto('/settlement')}>
                        <ListItemIcon><Article/></ListItemIcon>
                        <ListItemText>{"정산"}</ListItemText>
                    </ListItemButton>
                    {teamData.length>0
                    ?
                    <ListItemButton onClick={()=>fn.goto('/settlement/report')}>
                        <ListItemIcon><ArticleOutlined/></ListItemIcon>
                        <ListItemText>{"정산보고서"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                    {ss.getCurrentSession().authority?.finance
                    ?
                    <ListItemButton onClick={()=>fn.goto('/settlement/manager')}>
                        <ListItemIcon><Article/></ListItemIcon>
                        <ListItemText>{"정산집계"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                    {ss.getCurrentSession().authority?.finance
                    ?
                    <ListItemButton onClick={()=>fn.goto('/settlement/sales')}>
                        <ListItemIcon><AttachMoney/></ListItemIcon>
                        <ListItemText>{"작품정산"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                    {ss.getCurrentSession().authority?.finance
                    ?
                    <ListItemButton onClick={()=>fn.goto('/settlement/email')}>
                        <ListItemIcon><Email/></ListItemIcon>
                        <ListItemText>{"정산서 발송"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                    <Divider/>
                    {teamData.length>0
                    ?
                    <ListItemButton onClick={()=>fn.goto('/settlement/counterpart')}>
                        <ListItemIcon><SupervisedUserCircle/></ListItemIcon>
                        <ListItemText>{"거래처관리"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                    {ss.getCurrentSession().authority?.finance
                    ?
                    <ListItemButton onClick={()=>fn.goto('/team')}>
                        <ListItemIcon><SupervisedUserCircle/></ListItemIcon>
                        <ListItemText>{"팀"}</ListItemText>
                    </ListItemButton>
                    :<></>}
                </List>
            </SwipeableDrawer>
            <Dialog open={!!alertText}>
                <DialogContent>{alertText}</DialogContent>
                <DialogActions>
                    <Button color={"primary"} onClick={()=>setAlertText(null)}>{"확인"}</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export default App;
