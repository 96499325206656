import EditableDateField from "Component/Table/EditableDateField";
import { dateToYYYYMM, fileReadHandler } from "Functions";
import React, { useEffect, useState } from "react";
import CompetitionSettlement from "./Component/SettlementForCompetition";
import { Input, MenuItem, Select, TextField } from "@mui/material";
import { readSettlement } from "Data/Settlement";
import { readCounterparts } from "Data/Counterpart";
import { readMemberships } from "Data/Membership";
import { readSettlementInfo } from "Data/SettlementInfo";
import ArtSettlement from "./Component/SettlementForArt";
import SupportSettlement from "./Component/SettlementForSupport";
import SampleSettlement from "./Component/SettlementForSample";

const makePayday = (targetMonth: number)=> {
    let ret = new Date(targetMonth * 1000);
    ret.setMonth(ret.getMonth() + 1);

    ret.setDate(10);

    if (ret.getDay() === 0){
        ret.setDate(8);
    }
    if (ret.getDay() === 6){
        ret.setDate(9);
    }

    return ret.getTime() / 1000;
};

const Email = () => {
    const [type, setType] = useState<'competition'|'art'|'support'|'sample'>('competition');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [targetMonth, setTargetMonth] = useState(new Date(dateToYYYYMM(new Date((new Date()).getTime() - 11 * 24 * 60 * 60 * 1000))+'-01 00:00:00+09:00').getTime() / 1000);
    const [targetPayday, setTargetPayDay] = useState(0);
    const [attachments, setAttachments] = useState<{name: string, body: string}[]>([]);

    const [sentList, setSentList] = useState<{type: typeof type, name: string}[]>([]);

    const targetMonthEndDate = ((date:Date)=>{
        let ret = new Date(date);
        ret.setMonth(date.getMonth() + 1);
        return ret.getTime() / 1000;
    })(new Date(targetMonth * 1000));

    useEffect(()=>{
        setTargetPayDay(makePayday(targetMonth));
    }, [targetMonth])

    const [competitionList, setCompetitionList] = useState<{name:string, email: ""}[]>([]);
    const [sampleList, setSampleList] = useState<{name:string, email: ""}[]>([]);
    const [artList, setArtList] = useState<{name?:string, email?: string, teamname?: string, type: 'art'|'support'}[]>([]);
    
    useEffect(()=>{
        (async()=>{
            let settlementInfos = await readSettlementInfo();
            let memberships = await readMemberships();
            
            let artteams = new Set( settlementInfos.map(row => row.teamid).filter((val, idx, arr) => idx === arr.indexOf(val)) );
            let settlements = await readSettlement(targetMonth, targetMonthEndDate,);
            let counterparts = await readCounterparts();

            setCompetitionList(settlements.filter(row => row.artname === '공모전').map(row => ({
                name: counterparts.filter(counterpart => `${counterpart.counterpartid}` === row.membername)[0]?.name,
                email: ""
            })));

            setSampleList(settlements.filter(row => row.artname === '샘플작업').map(row => ({
                name: counterparts.filter(counterpart => `${counterpart.counterpartid}` === row.membername)[0]?.name,
                email: ""
            })));

            let artList = settlements.filter(row => artteams.has(row.teamid)).map(row => row.membername).filter((val, idx, arr) => idx === arr.indexOf(val)).map(row => {

                let counterpart = counterparts.find(counterpart => `${counterpart.counterpartid}` === row);
                let settlementInfo = settlementInfos.find(settlementInfo => settlementInfo.author === counterpart?.name && counterpart?.teamid.includes(settlementInfo.teamid) );
                let team = memberships.find(membership => counterpart?.teamid.includes(membership.teamid));

                return {
                    name: counterpart?.name,
                    email: settlementInfo?.email,
                    teamname: team?.name,
                    type: (settlementInfo)?"art":"support",
                } as const;
            })
            setArtList(artList);
        })();
    },[targetMonth, targetMonthEndDate]);

    return (<div style={{display:'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'flex-start', margin: '50px auto', gap: 16}}>
        <div className={""} style={{width: '100%', maxWidth: 650, textAlign: 'left'}}>
            <table style={{width: '100%', whiteSpace: "nowrap"}}><tbody>
                <tr>
                    <td>{`귀속월: `}</td>
                    <td><EditableDateField fullWidth
                        type={"date"} monthOnly defaultValue={targetMonth} isEditing={true} field={'targetMonth'}
                        update={(newValue) => {
                            setTargetMonth(new Date(dateToYYYYMM(new Date(newValue.targetMonth * 1000))+'-01 00:00:00+09:00').getTime() / 1000)
                        }}
                    /></td>
                    <td>{`지급예정일: `}</td>
                    <td><EditableDateField fullWidth type={"date"} defaultValue={targetPayday} isEditing={true} field={'targetPayday'} update={(newValue) => setTargetPayDay(newValue.targetPayday)} /></td>
                </tr>
                <tr>
                    <td>{"공모전, 샘플"}</td>
                    <td>
                        <Select size="small" style={{width: '50%'}} value={name} onChange={(e) => {setName(e.target.value); setType('competition'); setEmail("")}}>
                            {competitionList.map(row => <MenuItem value={row.name} disabled={sentList.filter(sent => sent.type === 'competition' && sent.name === row.name).length > 0}>{row.name}</MenuItem>)}
                        </Select>
                        <Select size="small" style={{width: '50%'}} value={name} onChange={(e) => {setName(e.target.value); setType('sample'); setEmail("")}}>
                            {sampleList.map(row => <MenuItem value={row.name} disabled={sentList.filter(sent => sent.name === row.name).length > 0}>{row.name}</MenuItem>)}
                        </Select>
                    </td>
                    <td>{"작품 정산"}</td>
                    <td>
                        <Select size="small" fullWidth style={{maxWidth: 250, overflow:'hidden', textOverflow: 'ellipsis'}} value={name} onChange={(e) => {setName(e.target.value);}}>
                            {artList.filter(row => row.name !== '마케팅').map((row,idx) =>
                                <MenuItem
                                    value={row.name} disabled={sentList.filter(sent => (sent.type === 'art' || sent.type === 'support') && sent.name === row.name).length > 0}
                                    onClick={() => {setType(row.type); setEmail(row.email || "")}}
                                >
                                    {row.name}{`(${row.teamname}${row.type === 'support'?', 어시':''})`}
                                </MenuItem>
                            )}
                        </Select>
                    </td>
                </tr>
                <tr ><td style={{padding: 10}} colSpan={4}></td></tr>
                <tr>
                    <td>{"수신자"}</td><td><TextField fullWidth size={"small"} value={name} onChange={(e) => setName(e.target.value)}/></td>
                    <td>{"이메일"}</td><td><TextField fullWidth size={"small"} value={email} onChange={(e) => setEmail(e.target.value)}/></td>
                </tr>
                <tr>
                    <td>{"첨부파일"}</td>
                    <td>
                        <Input fullWidth type='file' onChange={async (e)=>{
                            console.log((e.target as HTMLInputElement).files);

                            let files = (e?.target as HTMLInputElement)?.files;
                            if (!files || files.length === 0){
                                return;
                            }

                            let blobs = [];
                            for (let i = 0; i < files.length; i++){
                                let file = files[i];
                                let blob = await fileReadHandler(file, 'dataurl') as string;
                                if (!blob){
                                    continue;
                                }
                                let firstComma = blob.indexOf(',');

                                blobs.push ({name: file.name, body: blob.substring(firstComma+1) });
                            }

                            setAttachments(blobs);

                        }}/>
                    </td>
                </tr>
            </tbody></table>
            {type === 'competition'
            ?<CompetitionSettlement
                email={email} author={name} attachments={attachments}
                month={`${new Date(targetMonth * 1000).getMonth()+1}`}
                payday={new Date(targetPayday * 1000).toLocaleDateString()}
                sendCallback={()=>{
                    setSentList(sentList => [...sentList, {type:'competition', name}])
                }}/>
            :type === 'art'
            ?<ArtSettlement
                email={email} author={name} attachments={attachments}
                month={`${new Date(targetMonth * 1000).getMonth()+1}`}
                payday={new Date(targetPayday * 1000).toLocaleDateString()}
                sendCallback={()=>{
                    setSentList(sentList => [...sentList, {type:'art', name}])
                }}/>
            :type === 'sample'
            ?<SampleSettlement
                email={email} author={name} attachments={attachments}
                month={`${new Date(targetMonth * 1000).getMonth()+1}`}
                payday={new Date(targetPayday * 1000).toLocaleDateString()}
                sendCallback={()=>{
                    setSentList(sentList => [...sentList, {type:'sample', name}])
                }}/>
            :/*type === 'support */
            <SupportSettlement
                email={email} author={name} attachments={attachments}
                month={`${new Date(targetMonth * 1000).getMonth()+1}`}
                payday={new Date(targetPayday * 1000).toLocaleDateString()}
                sendCallback={()=>{
                    setSentList(sentList => [...sentList, {type:'support', name}])
                }}/>}
        </div>
    </div>);
}

export default Email;