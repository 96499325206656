
import { Avatar, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle,  IconButton,  ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Select, TextField } from '@mui/material';
import { AddOutlined, CancelOutlined, Create, Delete, Help, Visibility, VpnKey } from '@mui/icons-material';
import React, { Fragment } from 'react';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { readMemberships, createTeam, createMembership, updateMembership, deleteMembership, renameTeam, MembershipType } from 'Data/Membership'
import { validateEmailForm } from '../Functions';

import {SettlementInfo, TeamType, createOrUpdateSettlementInfo, deleteSettlementInfo, readSettlementInfo} from 'Data/SettlementInfo';

import ss from 'Data/Session';
import './Team.scss';
import { readProfile } from 'Data/Profile';
import EditableTable from 'Component/Table/EditableTable';

const nullMembership:MembershipType = {teamid: "", authority: 'write', userid: "", name: "", email: "", teamemail: "", closed: false};

const Team = () => {

    const [teamData, setTeamData] = React.useState([] as MembershipType[])
    const [isLoading, setIsLoading] = React.useState(false)
    const [userName, setUserName] = React.useState({} as {[userid: string]: string})

    const [settlementInfo, setSettlementInfo] = React.useState([] as (SettlementInfo & TeamType)[])

    React.useEffect(()=>{
        document.title = `팀 편집`;
        
        (async()=>{

            setIsLoading(true)

            const res = await readMemberships()

            const users:any = {}
            for (const team of res) {
                if (team.userid && !users[team.userid] && team) {
                    const newProfile = await readProfile(`${team.userid}`)
                    users[`${team.userid}`] = newProfile.name
                }
            }

            setTeamData(res)
            setUserName(users)

            setIsLoading(false)

        })()

    },[])

    React.useEffect(()=>{
        (async()=>{
            const res = await readSettlementInfo();

            setSettlementInfo(res);
        })();
    },[])

    const [isAskingAddMember, setIsAskingAddMember] = React.useState(false);
    const [isAskingCreateTeam, setIsAskingCreateTeam] = React.useState(false);
    const [isAskingEditTeamName, setIsAskingEditTeamName] = React.useState(false);

    const [pendingMembership, setPendingMembership] = React.useState({...nullMembership});
    const [pendingSettlementInfo, setPendingSettlementInfo] = React.useState<SettlementInfo[]>([]);

    const [editAuthorityAnchor, setEditAuthorityAnchor] = React.useState(null as (HTMLButtonElement | null));
    const [editingMember, setEditingMember] = React.useState({...nullMembership});

    const teams:{[key:string]: MembershipType[]} = {}
    for (const membership of teamData) {
        if (!teams[`${membership.teamid}:${membership.name}:${membership.teamemail}`]) {
            teams[`${membership.teamid}:${membership.name}:${membership.teamemail}`] = [membership]
        } else {
            teams[`${membership.teamid}:${membership.name}:${membership.teamemail}`].push(membership)
        }
    }
    
    const addNewMembership = () => {

        (async ()=>{
            setIsLoading(true);
            
            const newMembership = await createMembership(pendingMembership)
            
            const newTeamData = [...teamData]
            const newUsers = {...userName}

            if(newMembership){
                let [teamid, userid] = newMembership.split(':');
                let existingTeam = teamData.filter(team => team.teamid === teamid).concat([{...nullMembership}])[0];

                newTeamData.push({
                    ...pendingMembership,
                    teamid,
                    name: existingTeam.name || '',
                    email: "",
                    teamemail: existingTeam.teamemail || '',
                    closed: false,
                    userid,
                })
                
                if (!userName[`${newMembership.split(':')[1]}`]) {
                    const newProfile = await readProfile(newMembership.split(':')[1])
                    newUsers[`${newMembership.split(':')[1]}`] = newProfile.name
                }

            } else {
                alert('팀원 추가에 실패했습니다.')
            }

            setUserName(newUsers)
            setTeamData(newTeamData)
            setIsLoading(false);
        })();

        setPendingMembership({...nullMembership})
        setIsAskingAddMember(false);

    }

    const changeClosingStatus = ({teamid, name, teamemail, closed}:{teamid: string, name: string, teamemail: string, closed: boolean}) => {
        
        (async ()=>{
            setIsLoading(true)

            await renameTeam({ teamid, name, teamemail, closed });

            const res = await readMemberships()

            const users:any = {}
            for (const team of res) {
                if (team.userid && !users[team.userid] && team) {
                    const newProfile = await readProfile(`${team.userid}`)
                    users[`${team.userid}`] = newProfile.name
                }
            }

            setTeamData(res)
            setUserName(users)

            setIsLoading(false)

        })();

    }

    const generateNewTeam = () => {
        
        (async ()=>{
            setIsLoading(true);

            const newTeamId = await createTeam(pendingMembership.name, pendingMembership.teamemail)

            const newTeamData = [...teamData]
            const newUsers = {...userName}

            if (newTeamId){
                const [teamid, userid] = newTeamId.split(":");
                newTeamData.push({
                    ...pendingMembership,
                    teamid, userid, authority: 'owner'
                })
                
                if (!userName[`${ss.getCurrentSession().userid}`]) {
                    const loginUser = await readProfile(ss.getCurrentSession().userid)
                    newUsers[`${ss.getCurrentSession().userid}`] = loginUser.name
                }

            } else {
                alert('팀 생성에 실패했습니다.')
            }

            setUserName(newUsers)
            setTeamData(newTeamData)
            setIsLoading(false);
        })();

        setPendingMembership({...nullMembership})
        setIsAskingCreateTeam(false);
    }

    const changeTeamInfo = () => {

        (async () => {
            setIsLoading(true);

            const newTeamName = await renameTeam({ teamid: pendingMembership.teamid, name: pendingMembership.name, teamemail: pendingMembership.teamemail, closed: pendingMembership.closed });

            const newTeamData = [...teamData];

            if (newTeamName){
                for (const newMembership of newTeamData){
                    if(newMembership.teamid === pendingMembership.teamid){
                        newMembership.name = pendingMembership.name
                    }
                }
            }

            setTeamData(newTeamData)

            for (let row of pendingSettlementInfo){
                if (!row.author){
                    continue;
                }
                await createOrUpdateSettlementInfo(row);
            }

            for (let row of settlementInfo.filter(row => row.teamid === pendingMembership.teamid)){
                if (pendingSettlementInfo.filter(pending => pending.author === row.author).length === 0){
                    await deleteSettlementInfo(row);
                }
            }

            const res = await readSettlementInfo();

            setSettlementInfo(res);
            
            setPendingSettlementInfo([]);
            setIsLoading(false)
        })();

        setPendingMembership({...nullMembership})
        setIsAskingEditTeamName(false);
    }

    const changeAuthority = async(authority: 'owner' | 'write' | 'read')=> {
        const updatedMembership = await updateMembership({
            ...editingMember,
            authority
        })

        const newTeamData = [...teamData]
        
        for (const membership of newTeamData) {
            if (`${membership.teamid}:${membership.userid}` === updatedMembership) {
                membership.authority = authority
            }
        }

        setEditAuthorityAnchor(null)
        setTeamData(newTeamData)
        setEditingMember({...nullMembership})
    }

    const teamsArray = Object.entries(teams).map( ([key, value]) => ({
        teamid: key.split(':')[0],
        name: key.split(':')[1],
        teamemail: key.split(':')[2],
        closed: value[0]?.closed || false,
        members: value
    }))

    return(
        <div>
            <div className={'TeamAuthority'}>
                <div className={'Title'}>
                    {"팀 관리"}
                </div>
                <EditableTable
                    className={"Slim"}
                    style={{wordBreak: 'keep-all', whiteSpace: 'nowrap', fontSize: '0.8rem', textAlign: 'left'}}
                    columnHeaders={['구분', '항목명', '그룹메일', '담당자', '작업종료']}
                    headerButtonHeaders={[
                        <Button
                            fullWidth
                            color={'primary'} variant='contained' size={"small"} style={{color:'white'}}
                            onClick={()=>{ setIsAskingCreateTeam(true) }}
                        >{"새로 생성"}</Button>
                    ]}
                    headerButtonsFunction={(row, index)=>[
                        {color: 'primary', onClick: ()=>{
                            const newMembership = {...pendingMembership,
                                ...row,
                            }
                            setPendingMembership(newMembership)
                            if (row.teamemail?.startsWith('dl_art')){
                                setPendingSettlementInfo( settlementInfo.filter(info => info.teamemail === row.teamemail))
                            }
                            setIsAskingEditTeamName(true)
                        }, name: '수정'},
                    ]}
                    columnsFunction={(row, index)=>[
                        {isEditing: false, type: 'default', defaultValue: (row.teamemail?.startsWith('dl_art')?'작품':'정산'), field: 'category'},
                        {isEditing: false, type: 'default', defaultValue: row.name, field: 'name'},
                        {isEditing: false, type: 'default', defaultValue: row.teamemail, field: 'teamemail'},
                        {isEditing: false, type: 'default', defaultValue: <div style={{display: 'flex', justifyContent:'flex-start', alignItems: 'center'}}>{row.members.map( (membership:MembershipType) => 
                            <Chip key={`Chip${membership.teamid}:${membership.userid}`} className={"Chip"} 
                                avatar={<Avatar>
                                    {membership.authority === 'owner'
                                    ?<Button
                                    disabled={membership.userid === ss.getCurrentSession().userid && teamData.filter(({teamid})=>teamid === membership.teamid).length > 1}
                                        onClick={(event)=>{
                                            setEditingMember(membership)
                                            setEditAuthorityAnchor(event.currentTarget)
                                        }}>     
                                        <VpnKey className={"Avatar"} /> 
                                    </Button>
                                    :membership.authority === 'write'
                                    ?<Button
                                    disabled={teamData.filter(({teamid, userid, authority})=>teamid === membership.teamid && userid === ss.getCurrentSession().userid && authority !== 'owner').length === 1}
                                        onClick={(event)=>{
                                            setEditingMember(membership)
                                            setEditAuthorityAnchor(event.currentTarget)
                                        }}>     
                                        <Create className={"Avatar"}/> 
                                    </Button>
                                    :membership.authority === 'read'
                                    ?<Button
                                    disabled={teamData.filter(({teamid, userid, authority})=> userid === ss.getCurrentSession().userid && membership.teamid === teamid && authority !== 'owner').length === 1}
                                        onClick={(event)=>{
                                            setEditingMember(membership)
                                            setEditAuthorityAnchor(event.currentTarget)
                                        }}>     
                                        <Visibility className={"Avatar"}/> 
                                    </Button>
                                    :<Help className={"Avatar"}/>}
                                </Avatar>} 
                                label={
                                    <div className={"Label"}>
                                        <div className={"Text"}>{(userName[membership.userid || ""] || "")}</div>
                                    </div>
                                } color={
                                    membership.authority === 'owner'?'primary':
                                    membership.authority === 'write'?'secondary':
                                    membership.authority === 'read'?'default':'default'
                                }
                            />
                        )}
                        
                        <IconButton size={"small"} onClick={()=>{
                            setIsAskingAddMember(true);
                            setPendingMembership({
                                teamid: row.teamid, userid: undefined, name: '', email: '', teamemail: row.teamemail, authority: 'read', closed: false,
                            })
                        }}><AddOutlined/></IconButton>
                        </div>, field: 'member' },
                        {isEditing: false, type: 'default', defaultValue: <Checkbox checked={row.closed} onChange={()=>{
                            changeClosingStatus({
                                teamid: row.teamid,
                                name: row.name,
                                teamemail: row.teamemail,
                                closed: !row.closed
                            })
                        }}/>, field:'closed'}
                    ]}
                    data={teamsArray.sort(({closed: closedA, name: nameA}, {closed:closedB, name: nameB}) => {
                        if ((closedA && closedB) || (!closedA && !closedB)){
                            if (nameA > nameB){
                                return 1;
                            }else if (nameA < nameB) {
                                return -1;
                            }else {
                                return 0;
                            }
                        }else {
                            if (closedA && !closedB){
                                return -1;
                            }else {
                                return 1;
                            }
                        }
                    } )}
                    updateGenerator={(row, index) => async () => {

                    }}
                />
            </div>
            <div>
                <Menu
                    anchorEl={editAuthorityAnchor}
                    keepMounted
                    elevation={1}
                    open={Boolean(editAuthorityAnchor)}
                    onClose={()=>setEditAuthorityAnchor(null)}
                >
                    <MenuList>
                        <MenuItem
                            dense={true}
                            disabled={editingMember?editingMember['authority'] === 'owner':false}
                            onClick={()=>changeAuthority('owner')}
                        > 
                            <ListItemIcon>
                                <VpnKey fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="팀장" />
                        </MenuItem>
                        <MenuItem 
                            dense={true}
                            disabled={editingMember
                            ?editingMember['authority'] === 'write' || (
                                (editingMember.teamid === ss.getCurrentSession().userid && editingMember.authority === 'owner')
                                && teamData.filter(({teamid, userid, authority})=>(teamid === editingMember.teamid)).length === 1
                            )
                            :false}
                            onClick={()=>changeAuthority('write')}
                        >
                            <ListItemIcon>
                                <Create fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="관리자" />
                        </MenuItem>
                        <MenuItem 
                            dense={true}
                            disabled={editingMember
                            ?editingMember['authority'] === 'read' || (
                                (editingMember.teamid === ss.getCurrentSession().userid && editingMember.authority === 'owner')
                                && teamData.filter(({teamid, userid, authority})=>(teamid === editingMember.teamid)).length === 1
                            )
                            :false}
                            onClick={()=>changeAuthority('read')}
                        >
                            <ListItemIcon>
                                <Visibility fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="팀원" />
                        </MenuItem>
                        <MenuItem
                            dense={true}
                            onClick={async()=>{
                                const deletedMembership = await deleteMembership({...nullMembership, teamid: editingMember.teamid, userid: editingMember.userid})
                                
                                const newTeamData = []
                                if (deletedMembership){
                                    for (const membership of teamData) {
                                        if(`${membership.teamid}:${membership.userid}` !== deletedMembership){
                                            newTeamData.push(membership)
                                        }
                                    }
                                } else {
                                    alert('팀원 삭제를 실패했습니다.')
                                }

                                setEditAuthorityAnchor(null)
                                setTeamData(newTeamData)
                                setEditingMember({...nullMembership})
                            }}
                        >
                            <ListItemIcon>
                                <Delete fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="삭제" />
                        </MenuItem>
                    </MenuList>
                </Menu>
                <Dialog
                    open={isAskingAddMember}
                    onClose={()=> setIsAskingAddMember(false)} 
                >
                    <DialogTitle>{"팀원 추가하기"}</DialogTitle>
                    <DialogContent style={{display:'flex', flexDirection:'row', alignItems: 'flex-end'}}>
                        <TextField label={"이메일"} value={pendingMembership.email || ''} onChange={(event)=>{
                            const newMembership = {...pendingMembership}
                            newMembership.email = event.target.value
                            setPendingMembership(newMembership)
                        }} autoFocus></TextField>
                        <Select label={"권한"} value={pendingMembership.authority} onChange={(event)=>{
                            const newMembership = {...pendingMembership}
                            newMembership.authority = event.target.value as 'owner' | 'write' | 'read'
                            setPendingMembership(newMembership)
                        }}>
                            <MenuItem value={"owner"}>{"팀장"}</MenuItem>
                            <MenuItem value={"write"}>{"관리자"}</MenuItem>
                            <MenuItem value={"read"}>{"팀원"}</MenuItem>
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"} 
                        disabled={!validateEmailForm(pendingMembership.email)}
                        onClick={addNewMembership}>{"추가"}</Button>
                        <Button color={"secondary"} onClick={()=>{

                            setPendingMembership({...nullMembership})
                            setIsAskingAddMember(false);

                        }}>{"취소"}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={isAskingCreateTeam}>
                    <DialogTitle>{"팀 생성하기"}</DialogTitle>
                    <DialogContent style={{display:'flex', flexDirection:'column', alignItems: 'flex-end'}}>
                        <TextField size="small" style={{margin:10}} label={"팀 이름"}
                            value={pendingMembership.name || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, name: event.target.value})
                        }}></TextField>
                        <TextField size="small" style={{margin:10}} label={"팀 메일링"}
                            value={pendingMembership.teamemail || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, teamemail: event.target.value})
                        }}></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"} onClick={generateNewTeam}>{"생성"}</Button>
                        <Button color={"secondary"} onClick={()=>{

                            setPendingMembership({...nullMembership})
                            setIsAskingCreateTeam(false);

                        }}>{"취소"}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={isAskingEditTeamName} onClose={()=>{
                    setIsAskingEditTeamName(false);
                    setPendingMembership({...nullMembership});
                    setPendingSettlementInfo([]);
                }}>
                    <DialogTitle>{"팀 정보 변경"}</DialogTitle>
                    <DialogContent style={{display:'flex', flexDirection:'column', alignItems: 'flex-end'}}>
                        <TextField size="small" style={{margin:10}} label={"팀 이름"}
                            value={pendingMembership.name || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, name: event.target.value})
                        }}></TextField>
                        <TextField size="small" style={{margin:10}} label={"팀 메일링"}
                            value={pendingMembership.teamemail || ''} onChange={(event)=>{
                            setPendingMembership({...pendingMembership, teamemail: event.target.value})
                        }}></TextField>
                        {pendingSettlementInfo.map( (row,index) =>
                        <Fragment key ={index}>
                            <div style={{width: 243, display: 'flex', justifyContent: 'center'}}>
                                <div>
                                    <div style={{width: 230, display: 'flex'}}>
                                        <TextField size="small" style={{margin:"10px 5px"}} label={"작가명"}
                                            value={row.author || undefined} onChange={(event)=>{
                                            setPendingSettlementInfo([
                                                ...pendingSettlementInfo.slice(0, index),
                                                {...row, author: event.target.value},
                                                ...pendingSettlementInfo.slice(index+1)
                                            ])
                                        }}></TextField>
                                        <TextField size="small" style={{width: 320, margin:"10px 5px"}} label={"정산비율(작가 몫)"} type={"number"} inputProps={{max: 100, min: 0, step: 5}}
                                            value={ Math.round(row.revenue_share * 100) || 0} onChange={(event)=>{
                                                setPendingSettlementInfo([
                                                    ...pendingSettlementInfo.slice(0, index),
                                                    {...row, revenue_share: Number(event.target.value) / 100},
                                                    ...pendingSettlementInfo.slice(index+1)
                                                ])
                                        }}></TextField>
                                    </div>
                                    <div style={{width: 230, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <span><Checkbox checked={row.needWithholding} onChange={() => {
                                                setPendingSettlementInfo([
                                                    ...pendingSettlementInfo.slice(0, index),
                                                    {...row, needWithholding: !row.needWithholding},
                                                    ...pendingSettlementInfo.slice(index+1)
                                                ])
                                        }} ></Checkbox>{"원천세 공제 대상"}</span>
                                        <IconButton size={"small"} onClick={() => {
                                            setPendingSettlementInfo(pendingSettlementInfo => 
                                                pendingSettlementInfo.filter(settleInfo => settleInfo.author !== row.author)
                                            )
                                        }}><CancelOutlined/></IconButton>
                                    </div>
                                </div>
                            </div>
                            <TextField size="small" style={{margin:10}} label={"작가 이메일"}
                                value={row.email || undefined} onChange={(event)=>{
                                    setPendingSettlementInfo([
                                        ...pendingSettlementInfo.slice(0, index),
                                        {...row, email: event.target.value},
                                        ...pendingSettlementInfo.slice(index+1)
                                    ])
                            }}></TextField>
                        </Fragment>)}
                        {pendingMembership.teamemail.startsWith('dl_art') &&
                        <Button size={"small"} variant={'outlined'} onClick={()=>{
                            setPendingSettlementInfo([
                                ...pendingSettlementInfo,
                                {teamid: pendingMembership.teamid, author: '', email: '', revenue_share: 0, needWithholding: true}
                            ])
                        }}>{"작가 추가"}</Button>}
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"contained"} color={"primary"} onClick={()=>{
                            if(!validateEmailForm(pendingMembership.teamemail)){
                                alert("이메일이 잘못되었습니다.");
                            }
                            changeTeamInfo();
                        }}>{"변경"}</Button>
                        <Button color={"secondary"} onClick={()=>{

                            setPendingMembership({...nullMembership})
                            setIsAskingEditTeamName(false);

                        }}>{"취소"}</Button>
                    </DialogActions>
                </Dialog>
            </div>
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default Team;
