import EditableTable, { EditableTablePropType } from "Component/Table/EditableTable";
import { SalesType } from "Data/Sales";
import { dateToYYYYMM, numberToAccountingNumber } from "Functions";
import React from "react";

const SalesSection = ({sales}: {sales: SalesType[]}) => {
    
    return <section>
        <div className={"SectionHead"}>
            <h2>{"매출"}</h2>
            <div style={{textAlign:'left'}}>
                <h3>{"총매출 계 "}{numberToAccountingNumber(sales.map(row => row.net).filter(net => net > 0).reduce((a,b)=>(a+b),0))}</h3>
            </div>
        </div>
        <EditableTable
            {...{
                className: 'Slim',
                data: sales,
                columnHeaders: [
                    "정산월", "플랫폼",
                    <>{"상세"}<br/>{"(매출기준일 등)"}</>,
                    <>{"결제액"}<br/>{"(A)"}</>,
                    <>{"결제 수수료"}<br/>{"(B)"}</>,
                    <>{"순결제액"}<br/>{"(C = A- B)"}</>,
                    <>{"플랫폼 수수료"}<br/>{"(D)"}</>,
                    <>{"계"}<br/>{"(C - D)"}</>
                ],
                columnsFunction: (row, index) => [
                    {
                        isEditing: false, type:'default', field: '정산월', style:{whiteSpace:'nowrap'}, monthOnly: true,
                        defaultValue: dateToYYYYMM(new Date(row.requested_at * 1000)),
                    }, {
                        isEditing: false, type: 'default', field: "플랫폼", style:{whiteSpace:'nowrap'},
                        defaultValue: row.platform,
                    }, {
                        isEditing: false, type: 'default', field: "상세(매출기준일, 업체명 등)", style: {textAlign: 'left', wordBreak: 'break-word'},
                        defaultValue: row.note,
                    }, {
                        isEditing: false, type: 'default', field: "결제액(A)", style:{whiteSpace:'nowrap'},
                        defaultValue: numberToAccountingNumber(row.gross),
                    }, {
                        isEditing: false, type: 'default', field: "결제 수수료(B)",
                        defaultValue: numberToAccountingNumber(row.payment_commission), style:{whiteSpace:'nowrap'},
                    }, {
                        isEditing: false, type: 'default', field: "순결제액(C = A- B)",
                        defaultValue: numberToAccountingNumber(row.gross - row.payment_commission), style:{whiteSpace:'nowrap'},
                    }, {
                        isEditing: false, type: 'default', field: "플랫폼 수수료(D)", style:{whiteSpace:'nowrap'},
                        defaultValue: numberToAccountingNumber(row.platform_commission),
                    }, {
                        isEditing: false, type: 'default', field: "계(C - D)", style:{whiteSpace:'nowrap'},
                        defaultValue: numberToAccountingNumber(row.net),
                    },
                    
                ],
                updateGenerator: () => async () => {},
                headerButtonsFunction: (row, index) => [],
                headerButtonHeaders: [],
            } as EditableTablePropType<SalesType>}
        />
    </section>
}

export default SalesSection;