import axios from 'axios';
import ss from './Session';

export const expenseTypeMap = {
    '식대': ['점심 식사', '저녁 식사', '회식, 간식, 음료'],
    '교통비': ['외근', '야근'],
    '소모품': ['사무용품', '기타용품'],
    '업무기기': ['컴퓨터', '주변기기'],
    '교육': ['수업관련비', '도서구입비'],
    '업무관계자 접대': ['식사', '경조사 및 선물'],
    '업무관련': ['구매비', '서비스 이용료'],
    '회의비': ['진수글 주최', '서영호 주최'],
    '기타': ['승인 대상']
} as const;

export type Expense = {
    state: 'edit'|'confirmed'|'rejected',
    userid?: string,

    type: string,
    cardid: string,
    related: string[],

    amount: number,
    request_amount: number,
    
    receipt: string | null,
    comment: string | null,
    
    occured_at?: number,
    requested_at?: number,
}

export const createExpense = async (expense: Expense,) => {
    let res = await axios.put('https://admin-api.webtoon.today/expense', {
        ...expense,
        amount: Number(expense.amount),
        request_amount: Number(expense.request_amount),
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

export const updateExpense = async (expense: Expense,) => {

    let res = await axios.patch('https://admin-api.webtoon.today/expense', {
        ...expense,
        amount: Number(expense.amount),
        request_amount: Number(expense.request_amount),
    }, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.data;
    } else {
        return new Error( res.data.message );
    };
};

export const deleteExpense = async (expense: (Expense & {expenseid: number}),) => {

    let res = await axios.delete(`https://admin-api.webtoon.today/expense?expenseid=${expense.expenseid}`, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });

    if (res.data && res.data.code === 200){
        return res.data.key;
    } else {
        return new Error( res.data.message );
    };
};

export const readExpense = async (userid?: string|null, start_from?: number, end_to?:number) => {
    let res = await axios.post(`https://admin-api.webtoon.today/expense`, {userid, start_from, end_to}, {
        headers: {
            Authorization: `Bearer ${ss.getCurrentSession().jwt}`
        },
        withCredentials: true
    });
    
    if (res.data.data && res.data.code === 200) {

        return res.data.data as (Expense & {expenseid: number})[];

    } else {
        return []
    };
};
