import EditableTable, { EditableTablePropType } from "Component/Table/EditableTable";
import { Settlement } from "Data/Settlement";
import { dateToYYYYMM, numberToAccountingNumber } from "Functions";
import React from "react";

import { CounterpartType } from "Data/Counterpart";

const ShareSection = ({
    settlements: given, counterpart, selectedCounterpart
}: {
    settlements: Settlement[], counterpart: CounterpartType[], selectedCounterpart: CounterpartType | null
}) => {
    
    const settlements = given.filter(row => ['MG', 'RS'].includes(row.type) && (!selectedCounterpart || `${selectedCounterpart.counterpartid}` === row.membername));

    return <section>
        <div className={"SectionHead"}>
            <h2>{"정산"}</h2>
            <h3>{"계 "}{numberToAccountingNumber(Math.ceil(settlements.map(row => row.request_amount).reduce((a,b)=>(a+b),0)))}</h3>
        </div>
        <EditableTable
            {...{
                className: 'Slim',
                data: settlements,
                style:{overflow:'hidden'},
                columnHeaders: [
                    "정산월", "구분",
                    "지급대상", "수령일", "내용",
                    "단가", "개수", "계"
                ],
                columnsFunction: (row, index) => [
                    {
                        isEditing: false, type:'default', field: '정산월', monthOnly: true, style:{whiteSpace:'nowrap'},
                        defaultValue: dateToYYYYMM(new Date(row.requested_at * 1000)),
                    }, {
                        isEditing: false, type: 'default', field: "구분", style:{whiteSpace:'nowrap'},
                        defaultValue: row.type,
                    }, {
                        isEditing: false, type: 'select', field: "수신자", style:{whiteSpace:'nowrap'},
                        defaultValue: row.membername, options: counterpart.map(row => ({key: row.name, value: `${row.counterpartid}`}))
                    }, {
                        isEditing: false, type: 'default', field: "수령일", style:{whiteSpace:'nowrap'},
                        defaultValue: row.occured_at?new Date(row.occured_at * 1000).toISOString().substring(0,10):'',
                    }, {
                        isEditing: false, type: 'default', field: "내용", style:{wordBreak: 'break-word', maxWidth: 400},
                        defaultValue: `${row.episodename}: ${row.comment?.replace(new RegExp('https://webtoon.today/home/contents/'), '/')}`,
                    }, {
                        isEditing: false, type: 'default', field: "단가", style:{whiteSpace:'nowrap'},
                        defaultValue: numberToAccountingNumber(row.unit_amount),
                    }, {
                        isEditing: false, type: 'default', field: "개수", style:{whiteSpace:'nowrap'},
                        defaultValue: numberToAccountingNumber(row.request_count),
                    }, {
                        isEditing: false, type: 'default', field: "계", style:{whiteSpace:'nowrap'},
                        defaultValue: numberToAccountingNumber(Math.floor(row.request_amount)),
                    },
                    
                ],
                updateGenerator: () => async () => {},
                headerButtonsFunction: (row, index) => [],
                headerButtonHeaders: [],
            } as EditableTablePropType<(Settlement & {requested_at: number})>}
        />
    </section>
}

export default ShareSection;