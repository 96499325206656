import React from 'react';
import { TextField } from '@mui/material';
import { CellData } from './DataType';

const EditableField = (props: CellData & {update: (newValue:any) => void}) => {

    const [value, setValue] = React.useState(props.defaultValue);

    React.useEffect(()=>{
        setValue(props.defaultValue || "");
    },[props]);

    return (props.isEditing
    ?(<TextField
        style={props.style || {}}
        fullWidth={false}
        value={value}
        inputProps={{style: {padding: 5, textAlign: 'center'}}}
        onChange={(event)=>setValue(event.target.value)}
        onKeyPress={(event)=> {
            let newData = {} as any; newData[props.field] = (event.target as HTMLInputElement).value;
            if (event.key === "Enter") {(event.target as HTMLInputElement)?.blur()}
        }}
        onBlur={(event)=> {
            let newData = {} as any; newData[props.field] = (event.target as HTMLInputElement).value;
            props.update(newData);
        }}
    />)
    :<div style={props.style}>{props.defaultValue}</div>);
}

export default EditableField;