import React, { useEffect } from 'react';
import {  Button } from '@mui/material';
import { triggerEmail } from '../../../../Data/TriggerEmail';

import './SettlementEmail.scss';

const ArtSettlement = ({author, month, payday, email, attachments, sendCallback}: {
        author: string;
        month: string;
        payday: string;
        email: string;
        attachments: {name: string, body: string}[];
        sendCallback: (success: boolean) => void;
    }) => {

    const body = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
        <body">
            <div style="display: block; width: 600px; background-color: white; padding: 30px 20px; font-size: 14px; box-sizing: border-box; color: rgb(68,68,68); line-height: 170%; margin: auto; border: 1px solid rgb(236,240,241); border-bottom: 0px">
                <h3 style="font-size: 18px;"><strong>${month}월 작품 정산 내역을 알려드립니다.</strong></h3>
                안녕하세요 ${author} 작가님, 오늘의웹툰입니다.<br/>
                <br/>
                작품 정산 자료를 보내드립니다. 지급 예정일은 ${payday} 일입니다.<br/>
                살펴보시고 궁금하신 부분 말씀 부탁드립니다.<br/>
                <br/>
                이번달도 잘 부탁드립니다. :)<br/>
                오늘의웹툰 팀 드림.<br/><br/>
            </div>
            <div style="background-color: rgb(236,240,241); width: 600px; box-sizing: border-box; font-size: 12px; color: rgb(177,178,181); line-height: 170%; padding: 10px 0 20px 0; text-align: center; margin: auto; border: 1px solid rgb(236,240,241);">
                주식회사 오늘의웹툰 | 웹툰 데이터 분석 · 에이전시 · 스튜디오<br/>
                서울특별시 강남구 테헤란로 2길 27, 1111호 (역삼동, 패스트파이브 빌딩)<br/>
                작품문의: <a href="mailto:artist@webtoon.today">artist@webtoon.today</a><br/>
                기타문의: <a href="mailto:help@webtoon.today">help@webtoon.today</a><br/>
                <br/><div>© ${new Date().getFullYear()} All Rights Reserved</div>
            <div>
            <div style="width: 600px; display: flex;">
            </div>
        </body>
    </html>
    `
    const mailTitle = `[오늘의웹툰] ${month}월 작품 정산서`

    const [isSending, setIsSending] = React.useState(false);

    useEffect(()=>{
        setIsSending(false);
    },[author, email])

    return (
        <div style={{display: 'flex', justifyContent: 'center', paddingTop: '20px'}}>
            <div className={'SendEmailInner'}>
                <div className={`EmailInfo`} style={{marginBottom:10, fontSize:'0.8rem', borderBottom: '1px black solid'}}>
                    <span style={{fontWeight: 'bold'}}>{"발송 정보"}</span>
                    <table>
                        <tbody>
                            <tr>
                                <td>{"수신인"}</td><td>{email}</td>
                            </tr>
                            <tr>
                                <td>{"제목"}</td><td>{mailTitle}</td>
                            </tr>
                            <tr>
                                <td>{"정산 월/지급일"}</td><td>{month}{"/"}{payday}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={`EmailPreview`}>
                    <div dangerouslySetInnerHTML={{__html: body}} />
                </div>
                {attachments.map(attachment =>
                    <div style={{margin: 5, width: 100, height: 100, border: "1px lightgray solid"}}>
                        <div style={{width: 100, height: 60, backgroundColor: "white"}}></div>
                        <div style={{width: 100, height: 40, overflow: 'hidden', textOverflow: 'ellipsis', fontSize: 12, backgroundColor: "rgb(177,178,181)", padding: 6, boxSizing: 'border-box', color: 'white'}}>
                            {attachment.name}
                        </div>
                    </div>
                )}
                <div className={'SendButton'}>
                    <Button 
                        disabled={!author || !month || !payday || !email || isSending}
                        fullWidth
                        variant={'contained'} color={'primary'} 
                        onClick={ async () => {
                            setIsSending(true);
                            const result = await triggerEmail({
                                email,
                                subject: mailTitle,
                                body,
                                attachments,
                            })
                            if (!result) {
                                alert('이메일 전송에 실패했습니다.')
                            }
                            sendCallback(!!result);
                        }}
                    >
                        {'이메일 보내기'}
                    </Button>
                </div>
            </div>
        </div>
        
    )
}

export default ArtSettlement;