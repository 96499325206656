import React from "react";

import {readSales, createOrUpdateSales, SalesType, deleteSales} from 'Data/Sales';
import { readMemberships } from "Data/Membership";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, MenuItem, Select } from "@mui/material";
import { unique, fileReadHandler, readCSVAsNamedRecordArray, numberToAccountingNumber, dateToYYYYMM, fn } from "Functions";
import EditableTable, { EditableTablePropType } from "Component/Table/EditableTable";

import './Sales.scss';

const Sales = () => {

    const [needToRefresh, setNeedToRefresh] = React.useState(0);

    const [memberships, setMembership] = React.useState<{teamid: string, name: string}[]>([]);
    const [savedSales, setSavedSales] = React.useState<SalesType[] | null>(null);
    const [teamid, setTeamid] = React.useState<string|null>(null);
    const [fileData, setFileData] = React.useState<{[key: string]: string}[] | null>(null);
    const [keyMap, setKeyMap] = React.useState({
        requested_at: '청구월',
        teamid: '팀',
        platform: '플랫폼',
        note: '상세',
    
        gross: '결제액',
        payment_commission: '결제 수수료',
        platform_commission: '플랫폼 수수료',
    
        net: '순매출',
    });
    
    React.useEffect(()=>{
        if (!teamid){
            return;
        }
        (async()=>{
            let sales = await readSales(teamid);
            setSavedSales(sales);
        })();
    },[teamid, needToRefresh])

    React.useEffect(()=>{
       (async()=>{
            let memberships = await readMemberships();
            setMembership(
                memberships
                .filter((row) => row.teamemail?.startsWith('dl_art'))
                .map(({name, teamid}) => `${name}:${teamid}`)
                .filter(unique)
                .map(nameteamid => ({
                    name: nameteamid.split(':')[0],
                    teamid: nameteamid.split(':')[1],
                })));
        })();
    },[needToRefresh]);

    const [sales, setSales] = React.useState<SalesType[] | null>(null);
    React.useEffect(()=>{
        const sales:SalesType[] | null = fileData === null ? null : fileData.map(row => 
            ({
                requested_at: new Date(row[keyMap.requested_at]).getTime() / 1000,
                platform: row[keyMap.platform] || '네이버',
                note: row[keyMap.note] || '',
            
                gross: Number(row[keyMap.gross]?.replace(/,/g,'')) || 0,
                payment_commission: Number(row[keyMap.payment_commission]?.replace(/,/g,'')) || 0,
                platform_commission: Number(row[keyMap.platform_commission]?.replace(/,/g,'')) || 0,
            
                net: Number(row[keyMap.net]?.replace(/,/g,'')) || 0,
            })
        ).map(row => ({...row, teamid: teamid || ''} as SalesType));
        setSales(sales);
    },[fileData, keyMap, teamid, needToRefresh])

    return (<div className={"Sales"}>
        <Select size={"small"} value={teamid || ''} onChange={(event) => setTeamid(event.target.value)}>
            {memberships.sort(({name: nameA}, {name: nameB}) => nameA.localeCompare(nameB)).map(membership => <MenuItem key={membership.teamid} value={membership.teamid}>{membership.name}</MenuItem>)}
        </Select>
        <Button
            href={"https://static.webtoon.today/admin/000000-%EB%A7%A4%EC%B6%9C%20%EC%9E%85%EB%A0%A5%20%ED%85%9C%ED%94%8C%EB%A6%BF.csv"}
        >{"매출 템플릿"}</Button>
        <Button disabled={!teamid} onClick={()=>{
            document.getElementById('fileinput')?.click();
        }}>{"매출 파일 업로드"}</Button>
        <Button disabled={!teamid}
            href={`/settlement/art/${teamid}`}
            onClick={fn.gotoByAnchor}
        >{"작품 정산 보고서"}</Button>
        <Input id={'fileinput'} type={'file'} style={{display: 'none'}} onChange={(event)=> {
            (async ()=>{
                let target = (event.target as HTMLInputElement);
                if (target.files === null || target.files.length === 0){
                    return;
                }
                let data = await fileReadHandler(target.files[0]);
                if (typeof data === "string"){
                    setFileData(readCSVAsNamedRecordArray(data));
                }
                event.target.value= "";
            })();
        }}/>
        {savedSales && savedSales.length > 0 && 
        <section>
            <div className={"SectionHead"}>
                <h2>{"매출"}</h2>
                <h3>{"계 "}{numberToAccountingNumber(savedSales.map(row => row.net).reduce((a,b)=>(a+b),0))}</h3>
            </div>
            <EditableTable
                {...{
                    className: 'Slim',
                    data: savedSales,
                    columnHeaders: [
                        "정산월", "플랫폼",
                        <>{"상세"}<br/>{"(매출기준일 등)"}</>,
                        <>{"결제액"}<br/>{"(A)"}</>,
                        <>{"결제 수수료"}<br/>{"(B)"}</>,
                        <>{"순결제액"}<br/>{"(C = A- B)"}</>,
                        <>{"플랫폼 수수료"}<br/>{"(D)"}</>,
                        <>{"순매출"}<br/>{"(C - D)"}</>
                    ],
                    columnsFunction: (row, index) => [
                        {
                            isEditing: false, type:'default', field: '정산월', monthOnly: true,
                            defaultValue: dateToYYYYMM(new Date(row.requested_at * 1000)),
                        }, {
                            isEditing: false, type: 'default', field: "플랫폼",
                            defaultValue: row.platform,
                        },
                        {
                            isEditing: false, type: 'default', field: "상세(매출기준일, 업체명 등)", style: {textAlign: 'left'},
                            defaultValue: row.note,
                        }, {
                            isEditing: false, type: 'default', field: "결제액(A)",
                            defaultValue: numberToAccountingNumber(row.gross),
                        }, {
                            isEditing: false, type: 'default', field: "결제 수수료(B)",
                            defaultValue: numberToAccountingNumber(row.payment_commission),
                        }, {
                            isEditing: false, type: 'default', field: "순결제액(C = A- B)",
                            defaultValue: numberToAccountingNumber(row.gross - row.payment_commission),
                        }, {
                            isEditing: false, type: 'default', field: "플랫폼 수수료(D)",
                            defaultValue: numberToAccountingNumber(row.platform_commission),
                        }, {
                            isEditing: false, type: 'default', field: "순매출(C - D)",
                            defaultValue: numberToAccountingNumber(row.net),
                        },
                        
                    ],
                    updateGenerator: () => async () => {},
                    headerButtonsFunction: (row, index) => [
                        {color: 'secondary', name: '삭제', onClick:()=>{
                            (async()=>{
                                await deleteSales(row);
                                setNeedToRefresh(needToRefresh => needToRefresh + 1);
                            })();
                        }}
                    ],
                    headerButtonHeaders: [''],
                } as EditableTablePropType<SalesType>}
            />
        </section>
        }
        <Dialog open={!!fileData} onClose={()=>setFileData(null)}>
            <DialogTitle></DialogTitle>
            <DialogActions>
                <Button color={"secondary"} onClick={()=>{setFileData(null)}}>{"취소"}</Button>
                <Button
                    disabled={!sales} color={"primary"} variant="contained" style={{color:'white'}}
                    onClick={()=>{
                        (async ()=>{
                            if (!sales){
                                return;
                            }
                            await createOrUpdateSales(sales);
                            setNeedToRefresh(needToRefresh => needToRefresh+1);
                            setFileData(null);
                        })();
                    }
                }>{"저장하기"}</Button>
            </DialogActions>
            <DialogContent>
                {sales && sales.length > 0 && <EditableTable
                    style={{textAlign: 'right'}} className={"Slim"}
                    headerButtonHeaders={[]}
                    headerButtonsFunction={()=>[]}
                    columnsFunction={(row, index) => [
                        {isEditing: false, type: 'date', monthOnly: true, defaultValue: row.requested_at, field: 'requested_at'},
                        {isEditing: false, type: 'default', defaultValue: row.platform, field: 'platform'},
                        {isEditing: false, type: 'default', defaultValue: row.note, field: 'note'},
                        {isEditing: false, type: 'default', defaultValue: numberToAccountingNumber(row.gross), field: 'gross'},
                        {isEditing: false, type: 'default', defaultValue: numberToAccountingNumber(row.payment_commission), field: 'payment_commission'},
                        {isEditing: false, type: 'default', defaultValue: numberToAccountingNumber(row.platform_commission), field: 'platform_commission'},
                        {isEditing: false, type: 'default', defaultValue: numberToAccountingNumber(row.net), field: 'net'},
                    ]}
                    columnHeaders={!!fileData?[
                        <Select size={"small"} label={"청구월"} fullWidth
                            value={keyMap.requested_at} onChange={event => setKeyMap({...keyMap, requested_at: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                        <Select size={"small"} label={"플랫폼"} fullWidth
                            value={keyMap.platform} onChange={event => setKeyMap({...keyMap, platform: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                        <Select size={"small"} label={"상세"} fullWidth
                            value={keyMap.note} onChange={event => setKeyMap({...keyMap, note: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                        <Select size={"small"} label={"결제액"} fullWidth
                            value={keyMap.gross} onChange={event => setKeyMap({...keyMap, gross: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                        <Select size={"small"} label={"결제 수수료"} fullWidth
                            value={keyMap.payment_commission} onChange={event => setKeyMap({...keyMap, payment_commission: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                        <Select size={"small"} label={"플랫폼 수수료"} fullWidth
                            value={keyMap.platform_commission} onChange={event => setKeyMap({...keyMap, platform_commission: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                        <Select size={"small"} label={"순매출"} fullWidth
                            value={keyMap.net} onChange={event => setKeyMap({...keyMap, net: event.target.value})}
                        >
                            {Object.keys(fileData[0] || {})?.map(row => <MenuItem key={row} value={row}>{row}</MenuItem>)}
                        </Select>,
                    ]:[]}
                    data={sales}
                    updateGenerator={(row, index) => async (newValue) => { }}
                />}
            </DialogContent>
        </Dialog>
    </div>);
}

export default Sales;