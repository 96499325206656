import React from 'react';
import LoadingCircle from 'Component/LoadingCircleAnimated';
import { readMemberships, MembershipType } from 'Data/Membership'
import { readCounterparts, CounterpartType } from 'Data/Counterpart';

import '../Expense.scss';
import EditableTable from 'Component/Table/EditableTable';

import CounterPartCell from './Component/CounterpartCell';
import { Switch } from '@mui/material';
import { useSession } from 'Recoil/Session/Session';

const Team = () => {
    const {session} = useSession();

    const [refreshTimer, setRefreshTimer] = React.useState(null as null | NodeJS.Timeout)
    const [needRefresh, setNeedRefresh] = React.useState(0);
    const [teamData, setTeamData] = React.useState([] as MembershipType[])
    const [counterpartData, setCounterpartData] = React.useState([] as CounterpartType[]);
    const [isLoading, setIsLoading] = React.useState(false)

    const [newCounterpart, setNewCounterpart] = React.useState({} as CounterpartType);

    const [showActiveOnly, setShowActiveOnly] = React.useState(true);

    React.useEffect(()=>{
        document.title = `팀 편집`;
        
        (async()=>{

            setIsLoading(true)

            const res = await readMemberships()
            setTeamData(res.filter(row => row.userid === session.userid));

            const counterparts = await readCounterparts();
            setCounterpartData(counterparts)

            setIsLoading(false)

        })()

    },[session.userid])
    
    React.useEffect(()=>{
        let isFinished = false;

        if (refreshTimer){
            clearTimeout(refreshTimer);
        }

        let timer = setTimeout(()=>{
            (async()=>{
                setIsLoading(true);

                if (!isFinished){

                    const res = await readMemberships()
                    setTeamData(res.filter(membership => membership.userid === session.userid));

                    const counterparts = await readCounterparts();
                    setCounterpartData(counterparts)

                    setIsLoading(false);
                }
            })();
        }, 2000);

        setRefreshTimer(timer);

        return ()=>{
            isFinished = true;
            if (refreshTimer){
                clearTimeout(refreshTimer);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[needRefresh]);
    
    return(
        <div className={"Expense"}>
            <h1><img src={"https://static.webtoon.today/ddah/logo-04.png"} style={{width: 30, marginBottom: 5, marginRight: 10, verticalAlign:'middle'}} alt={"webtoon today"}/>{"거래처"}</h1>
            <CounterPartCell
                newCounterpart={newCounterpart} teamData={teamData} refreshCallback={()=>{setNeedRefresh(needRefresh+1)}}
            />
            <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                <Switch title='거래중인 목록만 보기' checked={showActiveOnly} onChange={(event, checked)=>setShowActiveOnly(checked)}/>
                <label >{'거래중인 목록만 보기'}</label>
            </div>
            <EditableTable
                style={{fontSize: '0.8rem', whiteSpace: 'nowrap'}}
                headerButtonHeaders={[ '' ]}
                headerButtonsFunction={(row, index)=>[
                    {name: '수정', color: 'primary', onClick:()=>{setNewCounterpart({...row})}}
                ]}
                columnHeaders={[ '연번', '관련작품', '거래처명', '은행', '계좌번호', '통장사본', '주민번호', '신분증', '업무개요', '기준단가']}
                columnsFunction={(row, index)=> [
                    {isEditing: false, type: 'default', defaultValue: row.counterpartid, field: 'counterpartid', },
                    {isEditing: false, type: 'default', defaultValue: row.teamid.map((teamid:string) => teamData.find(team => team.teamid === teamid )?.name).join(','), field: 'teamid' },
                    {isEditing: false, type: 'default', defaultValue: row.name, field: 'name', },
                    {isEditing: false, type: 'default', defaultValue: row.bank_name, field: 'bank_name', },
                    {isEditing: false, type: 'default', defaultValue: row.account_num, field: 'account_num', },
                    {isEditing: false, type: 'file', defaultValue: row.account_img, field: 'account_img', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} },
                    {isEditing: false, type: 'default', defaultValue: row.id_num, field: 'id_num', },
                    {isEditing: false, type: 'file', defaultValue: row.id_img, field: 'id_img', onLoad: (param)=>{}, buttonStyle: {}, accept: '', expandPreview: true, style:{maxHeight: 30} },
                    {isEditing: false, type: 'default', defaultValue: row.comment, field: 'comment', },
                    {isEditing: false, type: 'default', defaultValue: row.unit_amount, field: 'unit_amount', },
                    
                ]}
                data={counterpartData.filter(row => !row.finished || !showActiveOnly)}
                updateGenerator={(row, index)=> async ()=>{}}
            />
            <LoadingCircle show={isLoading} />
        </div>
    )
}

export default Team;
